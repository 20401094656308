import { v4 as uuid } from 'uuid';
import { GeolocationQuery } from './geolocation-query';
import { MissionId } from './mission';
import { Msisdn } from './telno';
interface TargetInput {
  missionId: MissionId;
  color: string;
  msisdn: string;
  imsi: string;
  queries: GeolocationQuery[];
}
export type TargetId = string;
export class Target {
  private constructor(
    public readonly missionId: MissionId,
    public msisdn: Msisdn,
    public imsi: string,
    public readonly color: string,
    public queries: GeolocationQuery[],
    public readonly id = uuid(),
    public readonly visible = true
  ) {}
  static create({
    missionId,
    color,
    msisdn,
    imsi,
    queries,
  }: TargetInput): Target {
    return new Target(missionId, msisdn, imsi, color, queries);
  }
  get latestLocatedDate(): string | void {
    const latestLocIndex = this.queries.findIndex(
      (q: GeolocationQuery) => !!q.location
    );
    if (latestLocIndex > -1) {
      return this.queries[latestLocIndex].created_at;
    }
  }
  setQueries(queries: GeolocationQuery[]): Target {
    return new Target(
      this.missionId,
      this.msisdn,
      this.imsi,
      this.color,
      queries,
      this.id
    );
  }
  replaceQuery(query: GeolocationQuery): Target {
    const msisdn = query.provider.telno || this.msisdn;
    const imsi = query.provider.imsi || this.imsi;
    return new Target(
      this.missionId,
      msisdn,
      imsi,
      this.color,
      this.queries.map((q) => (q.id === query.id ? query : q)),
      this.id
    );
  }
  appendQuery(query: GeolocationQuery): Target {
    const msisdn = query.provider.telno || this.msisdn;
    const imsi = query.provider.imsi || this.imsi;
    return new Target(
      this.missionId,
      msisdn,
      imsi,
      this.color,
      [query, ...this.queries],
      this.id
    );
  }
  setVisibility(isVisible: boolean): Target {
    return new Target(
      this.missionId,
      this.msisdn,
      this.imsi,
      this.color,
      this.queries,
      this.id,
      isVisible
    );
  }
  getScheduledQuery(): GeolocationQuery | null {
    const lastScheduledQuery = this.queries.find((q) => !!q.schedule);
    if (lastScheduledQuery && !lastScheduledQuery.schedule.canceled_at) {
      return lastScheduledQuery;
    }
    return null;
  }
  getScheduledQueries(): GeolocationQuery[] {
    return this.queries.filter((q) => !!q.schedule);
  }
}
