import { Pipe, PipeTransform } from '@angular/core';
import {
  GeolocationQuery,
  QueryFormatters,
} from '@fe-platform/geolocation/data';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from './empty';

@Pipe({ name: 'formatLocationType' })
export class QueryLocationTypePipe implements PipeTransform {
  constructor(private translationService: TranslateService) {}

  transform(query: GeolocationQuery): string {
    return query
      ? this.translationService.instant(QueryFormatters.locationType(query))
      : EMPTY;
  }
}
