import { Component } from '@angular/core';
import { UserRoles } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { LeftSidebarService } from '../left-sidebar.service';

@Component({
  selector: 'keystone-left-sidebar-web',
  templateUrl: './left-sidebar-web.component.html',
  styleUrls: ['./left-sidebar-web.component.css'],
  providers: [LeftSidebarService],
})
export class LeftSidebarWebComponent {
  public useRole$ = this.authFacade.useRole$;
  userRoles = UserRoles;

  constructor(
    private authFacade: AuthFacade,
    private sidebarService: LeftSidebarService
  ) {}

  navigateToMission(event?: Event): void {
    this.sidebarService.navigateToMission(event);
  }
}
