import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbPaginationModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LetModule, PushModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { BottomSheetsComponent } from './bottom-sheets/bottom-sheets.component';
import { BottomSheetsService } from './bottom-sheets';
import { DialogComponent } from './dialog/dialog.component';
import { BlurOnClickDirective } from './directives/blur.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FeatherIconDirective } from './directives/feather-icon.directive';
import { FocusDirective } from './directives/focus.directive';
import { MsisdnImsiInputDirective } from './directives/msisdn-imsi-input-filter.directive';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { LoaderComponent } from './loader/loader.component';
import { LogoComponent } from './logo/logo.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { PaginatorComponent } from './paginator/paginator.component';
import {
  FormatEmailErrorPipe,
  FormatPhoneErrorPipe,
  FormatTableDataPipe,
  FormatUsernameErrorPipe,
  ToDatePipe,
} from './pipes';
import { DynamicRadioButtonComponent } from './radio-group-with-editable-values/radio-button-with-editable-value/radio-button-with-editable-value.component';
import { RadioGroupWithEditableValuesComponent } from './radio-group-with-editable-values/radio-group-with-editable-values.component';
import { SearchComponent } from './search/search.component';
import { ShowPasswordButtonComponent } from './show-password-button/show-password-button.component';
import { MobileToastComponent } from './toaster/mobile-toast/mobile-toast.component';
import { ToasterComponent } from './toaster/toaster.component';
import { WebToastComponent } from './toaster/web-toast/web-toast.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbToastModule,
    NgbPaginationModule,
    LetModule,
    PushModule,
    NgbTooltipModule,
    TranslateModule,
  ],
  declarations: [
    LoaderComponent,
    RadioGroupWithEditableValuesComponent,
    DynamicRadioButtonComponent,
    LanguageSelectionComponent,
    FeatherIconDirective,
    ClickOutsideDirective,
    FocusDirective,
    BlurOnClickDirective,
    ToasterComponent,
    DialogComponent,
    LogoComponent,
    ToDatePipe,
    FormatTableDataPipe,
    FormatUsernameErrorPipe,
    FormatEmailErrorPipe,
    FormatPhoneErrorPipe,
    MsisdnImsiInputDirective,
    MobileToastComponent,
    WebToastComponent,
    BottomSheetsComponent,
    PaginatorComponent,
    SearchComponent,
    ShowPasswordButtonComponent,
    PageTitleComponent,
  ],
  exports: [
    LoaderComponent,
    RadioGroupWithEditableValuesComponent,
    LanguageSelectionComponent,
    FeatherIconDirective,
    ClickOutsideDirective,
    FocusDirective,
    BlurOnClickDirective,
    ToasterComponent,
    LogoComponent,
    ToDatePipe,
    FormatTableDataPipe,
    FormatUsernameErrorPipe,
    FormatEmailErrorPipe,
    FormatPhoneErrorPipe,
    MsisdnImsiInputDirective,
    TranslateModule,
    BottomSheetsComponent,
    PaginatorComponent,
    SearchComponent,
    ShowPasswordButtonComponent,
    PageTitleComponent,
  ],
  providers: [BottomSheetsService],
})
export class TempModule {}
