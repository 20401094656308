import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthStatus, User } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { BillingFacade } from '@fe-platform/billing/state';
import { ServerConfigFacade } from '@fe-platform/server-config/state';
import { LoaderService } from '@fe-platform/shared-ui/keystone';
import {
  Observable,
  catchError,
  filter,
  forkJoin,
  map,
  of,
  skipWhile,
  switchMap,
  withLatestFrom,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingGuard implements CanActivate {
  constructor(
    private billingService: BillingFacade,
    private serverConfigService: ServerConfigFacade,
    private authService: AuthFacade,
    private loader: LoaderService
  ) {}
  canActivate(): Observable<boolean> {
    return this.authService.status$.pipe(
      skipWhile((status) => status !== AuthStatus.AUTHENTICATED),
      switchMap(() => this.authService.currentUser$),
      filter<User | null>(Boolean),
      withLatestFrom(this.billingService.isReady$),
      switchMap(([user, isReady]) =>
        // to avoid fetching multiple times, we set and check the isReady$ property of billing service after
        // just fetching and setting once.
        isReady
          ? of(true)
          : forkJoin([
              this.billingService.fetchLedgerData(user.username),
              this.billingService.fetchBillingPlan(),
              this.billingService.fetchTenantBillingDetails(),
              this.serverConfigService.fetchEnvironmentConfig(),
            ]).pipe(
              map(
                ([ledger, billingPlan, tenantBillingDetails, featureFlags]) => {
                  this.billingService.setBillingPlan(billingPlan);
                  this.billingService.setCredits(ledger.current_balance);
                  this.billingService.setTenantBillingDetails(
                    tenantBillingDetails
                  );
                  this.serverConfigService.setEnvironmentConfig(featureFlags);
                  this.billingService.setBillingReady(true);
                  return true;
                }
              )
            )
      ),
      catchError((e) => {
        this.loader.toggleLoading(false);
        throw e;
      })
    );
  }
}
