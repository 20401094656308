import { Component, HostBinding } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Toast } from './toast';
import { ToasterService } from './toaster.service';

@UntilDestroy()
@Component({
  selector: 'keystone-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css'],
})
export class ToasterComponent {
  toasts$ = this.toasterService.toasts$;
  isMobile$ = this.layoutFacade.isMobile$;
  @HostBinding('class') classList = '';

  constructor(
    private toasterService: ToasterService,
    private layoutFacade: LayoutFacade
  ) {
    this.layoutFacade.isMobile$
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => {
        this.classList = isMobile
          ? 'position-fixed mt-5 mobile'
          : 'position-fixed top-90 end-55';
      });
  }

  removeToast(toast: Toast): void {
    this.toasterService.removeToast(toast);
  }
}
