import { Injectable } from '@angular/core';
import { GeolocationQueryDataService } from '@fe-platform/geolocation/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fs from 'file-saver';
import {
  catchError,
  map,
  mergeMap,
  repeat,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { MissionActions } from '../missions/actions';
import { GeolocationQueryAction, selectFilters } from './reducer';

@Injectable()
export class GeolocationQueryEffects {
  constructor(
    private geolocationQueriesDataService: GeolocationQueryDataService,
    private actions$: Actions,
    private store: Store
  ) {}

  filtersChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationQueryAction.setFilters),
      withLatestFrom(this.store.select(selectFilters)),
      tap(() =>
        this.store.dispatch(
          GeolocationQueryAction.setLoading({ loading: true })
        )
      ),
      switchMap(([, filters]) =>
        this.geolocationQueriesDataService.fetchGeolocationQueries(filters)
      ),
      switchMap((response) => {
        const queries = response.result;
        return [
          GeolocationQueryAction.setPagination({ pagination: response.meta }),
          GeolocationQueryAction.setQueries({ queries }),
          GeolocationQueryAction.setLoading({ loading: false }),
        ];
      }),
      catchError((e) => {
        throw e;
      })
    )
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationQueryAction.refresh),
      withLatestFrom(this.store.select(selectFilters)),
      switchMap(([, filters]) =>
        this.geolocationQueriesDataService.fetchGeolocationQueries(filters)
      ),
      switchMap((response) => {
        const queries = response.result;
        return [
          GeolocationQueryAction.setPagination({ pagination: response.meta }),
          GeolocationQueryAction.setQueries({ queries }),
        ];
      }),
      catchError((e) => {
        throw e;
      })
    )
  );

  retrieveCallInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionActions.retrieveCallInfo),
      mergeMap((payload) => {
        return this.geolocationQueriesDataService
          .retrieveCallInfo(payload.queryId)
          .pipe(
            map((response) =>
              MissionActions.updateQueryInTarget({ query: response.result })
            )
          );
      }),
      catchError((e) => {
        throw e;
      }),
      repeat()
    )
  );

  downloadPDFReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationQueryAction.downloadPdfReport),
      mergeMap((payload) =>
        this.geolocationQueriesDataService.generatePDFReport(payload).pipe(
          tap((reportBlob) => {
            const blob = new Blob([reportBlob], { type: `application/PDF` });
            fs.saveAs(
              blob,
              `ΚΕΥ_LOC_${payload.targetMsisdn || payload.equipment.imsi}_${
                payload.createdDate
              }.pdf`
            );
          })
        )
      ),
      catchError((e) => {
        throw e;
      }),
      repeat()
    )
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationQueryAction.downloadReport),
      switchMap(({ params, reportType }) =>
        this.geolocationQueriesDataService
          .generateQueryReport({ ...params, reportType })
          .pipe(
            tap((reportBlob) => {
              const formattedDate = new Date();
              switch (reportType) {
                case 'CSV': {
                  const blob = new Blob([reportBlob], { type: `text/CSV` });
                  fs.saveAs(blob, `Queries_history_${formattedDate}.csv`);
                  break;
                }
                case 'PDF': {
                  const blob = new Blob([reportBlob], {
                    type: `application/PDF`,
                  });
                  fs.saveAs(blob, `Queries_history_${formattedDate}.pdf`);
                  break;
                }
              }
            })
          )
      ),
      catchError((e) => {
        throw e;
      })
    )
  );
}
