import { GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatCellId: ColumnDataFormatter<GeolocationQuery, string> = (
  query: GeolocationQuery
) => {
  const cell_id = query.cell?.cell_id;
  if (cell_id) {
    return String(cell_id);
  }
  return '-';
};
