import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatus } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'keystone-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.css'],
})
export class OtpInputComponent implements OnInit {
  otpCode = '';
  constructor(
    private auth: AuthFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.redirectIfAuthenticated$().subscribe();
  }
  handleOtpCode(otpCode: string): void {
    this.otpCode = otpCode;
  }
  // Otp code can be submitted for either validating the user, or validating the otp config that was just posted.
  // After validating the otp config, the user is considered authenticated.
  submitOtpCode(): void {
    // # 149 Temporary implementation.
    const username = sessionStorage.getItem('username') as string;
    const password = sessionStorage.getItem('password') as string;
    this.auth.status$
      .pipe(
        untilDestroyed(this),
        tap((status) => {
          if (status === AuthStatus.WAITING_FOR_OTP_INPUT) {
            this.auth.login({
              username,
              password,
              otp_code: this.otpCode,
            });
          } else if (status === AuthStatus.VALIDATING_OTP_CONFIGURATION) {
            this.auth.validateOtpConfiguration(this.otpCode);
          }
        })
      )
      .subscribe();
  }
  private redirectIfAuthenticated$(): Observable<AuthStatus> {
    return this.auth.status$.pipe(
      untilDestroyed(this),
      tap((status) => {
        if (status === AuthStatus.AUTHENTICATED) {
          this.clearSessionStorage();
          this.router.navigate(['/']);
        } else if (status === AuthStatus.CHANGING_PASSWORD) {
          this.router.navigate(['../reset-password'], {
            relativeTo: this.route,
          });
        } else {
          console.warn('unexpected status after otp input sent.');
        }
      })
    );
  }
  private clearSessionStorage(): void {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('password');
  }
}
