import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStatus, User } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import {
  LayoutFacade,
  NetworkStatus,
  NetworkStatusFacade,
} from '@fe-platform/core/state';
import { StreamManager } from '@fe-platform/stream-manager';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PasswordChangeFormComponent } from '@fe-platform/auth/feature-password-form';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { notificationList, userAvatar } from './top-bar-mock-data';
import { LayoutService } from '../layout.service';

@UntilDestroy()
@Component({
  selector: 'keystone-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],
  providers: [LayoutService],
})
export class TopBarComponent implements OnInit {
  @Output() mobileMenuButtonClicked = new EventEmitter<void>();

  public loggedInUserAvatar = userAvatar;
  public notificationList = notificationList;
  isOffline$ = combineLatest([
    this.networkService.networkStatus$.pipe(
      map((status) => status === NetworkStatus.OFFLINE)
    ),
    this.streamManager.connected$,
  ]).pipe(map(([offline, wsConnected]) => offline || !wsConnected));
  public currentUser$ = this.authFacade.currentUser$.pipe(
    untilDestroyed(this),
    filter<User | null>(Boolean)
  );
  public isMobile$: Observable<boolean> = this.layoutFacade.isMobile$;

  constructor(
    private router: Router,
    public authFacade: AuthFacade,
    private networkService: NetworkStatusFacade,
    private streamManager: StreamManager,
    private layoutFacade: LayoutFacade,
    private modalService: NgbModal,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.authFacade.status$.pipe(untilDestroyed(this)).subscribe((status) => {
      if (status === AuthStatus.UNAUTHENTICATED) {
        this.router.navigate(['/login']);
      }
    });
  }

  onToggleMobileMenu(event: Event): void {
    event.stopPropagation();
    this.mobileMenuButtonClicked.emit();
  }

  onShowChangePassword(): void {
    this.modalService.open(PasswordChangeFormComponent);
  }

  onSignOut(): void {
    this.layoutService.signOut();
  }
}
