import { GeolocationQuery, Mission } from '../models';

export function queryInMission(
  query: GeolocationQuery,
  mission: Mission
): boolean {
  return mission.targets.some(
    (t) => t.msisdn === query.provider.telno || t.imsi === query.provider.imsi
  );
}

export function queryInMissions(
  query: GeolocationQuery,
  missions: Mission[]
): boolean {
  return missions.some((mission) => queryInMission(query, mission));
}
