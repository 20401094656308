import { Pipe, PipeTransform } from '@angular/core';
import {
  GeolocationQuery,
  QueryFormatters,
} from '@fe-platform/geolocation/data';
import { EMPTY } from './empty';

@Pipe({ name: 'formatCellNetwork' })
export class QueryCellNetworkPipe implements PipeTransform {
  transform(query: GeolocationQuery | undefined): string {
    return query ? QueryFormatters.cellNetwork(query) : EMPTY;
  }
}
