import { MarkerStatus } from '@fe-platform/geolocation/data';

export const FOCUSED_MARKER_STYLES: Partial<CSSStyleDeclaration> = {
  width: '26px',
  height: '26px',
};
export const DEFAULT_MARKER_STYLES: Partial<CSSStyleDeclaration> = {
  width: '26px',
  height: '26px',
  cursor: 'pointer',
};
export const DEFOCUSED_MARKER_STYLES: Partial<CSSStyleDeclaration> = {
  fillOpacity: '0.1',
  width: '26px',
  height: '26px',
};
export const MarkerStyles = {
  [MarkerStatus.DEFAULT]: DEFAULT_MARKER_STYLES,
  [MarkerStatus.FOCUSED]: FOCUSED_MARKER_STYLES,
  [MarkerStatus.DEFOCUSED]: DEFOCUSED_MARKER_STYLES,
} as const;
export const mobileMapPadding = { top: 190, bottom: 270, right: 50, left: 50 };
export const desktopMapPadding = {
  top: 100,
  bottom: 260,
  right: 50,
  left: 650,
};
