import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LatestQueriesService } from '../../latest-queries.service';
import { GeoquerySearchBoxService } from '../../geoquery-search-box.service';

@Component({
  selector: 'keystone-add-new-target-modal',
  templateUrl: './add-new-target-modal.component.html',
  providers: [LatestQueriesService],
})
export class AddNewTargetModalComponent {
  public vm$ = this.geoquerySearchBoxLatestQueriesService.vm$;
  constructor(
    public activeModal: NgbActiveModal,
    private geoquerySearchBoxService: GeoquerySearchBoxService,
    private geoquerySearchBoxLatestQueriesService: LatestQueriesService
  ) {}

  onSubmit(querySearchValue: string): void {
    if (querySearchValue) {
      this.activeModal.close();
      this.geoquerySearchBoxService.submit$.next(querySearchValue);
    }
  }
}
