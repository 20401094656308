<div class="mobile-backdrop"></div>
<div
  class="left-side-menu mobile pb-1"
  keystoneClickOutside
  (clickOutside)="onCloseSidebar()"
>
  <div
    id="sidebar-menu"
    class="d-flex flex-column h-100"
    keystoneFeatherIcon
    *ngrxLet="currentUser$ as user"
  >
    <div id="user-info" class="d-flex justify-content-between">
      <div>
        <p>
          <b>{{ user.username }}</b>
        </p>
      </div>
      <button
        type="button"
        class="btn-close"
        (click)="onCloseSidebar()"
      ></button>
    </div>
    <ul class="mb-0 flex-grow-1">
      <li
        data-qa="sidebar-missions"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['missions']"
      >
        <a (click)="onCloseSidebar(); navigateToMission($event)">
          <i data-feather="map"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.MISSIONS' | translate
          }}</span>
        </a>
      </li>
      <li
        data-qa="sidebar-history"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['report-analysis']"
        (click)="onCloseSidebar()"
      >
        <a>
          <i data-feather="clock"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.HISTORY' | translate
          }}</span>
        </a>
      </li>
      <li
        data-qa="sidebar-robots"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['robots']"
        (click)="onCloseSidebar()"
      >
        <a>
          <i class="uil uil-robot uil-menu-icon"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.ROBOTS' | translate
          }}</span>
        </a>
      </li>
    </ul>

    <div class="divider"></div>

    <ul class="mb-0">
      <li class="side-nav-item">
        <b>{{ 'LEFT_SIDEBAR.USER' | translate }}</b>
      </li>
      <li data-qa="sidebar-change-password" class="cursor-pointer">
        <a (click)="onCloseSidebar(); onShowChangePassword()">
          <i class="uil uil-padlock uil-menu-icon"></i>
          <span>{{ 'PASSWORD.CHANGE_PASSWORD' | translate }}</span>
        </a>
      </li>
      <li
        data-qa="sidebar-pricing"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['pricing']"
        (click)="onCloseSidebar()"
      >
        <a>
          <i class="uil uil-usd-circle uil-menu-icon"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.PRICING' | translate
          }}</span>
        </a>
      </li>
      <li class="side-nav-item">
        <keystone-language-selection></keystone-language-selection>
      </li>
    </ul>

    <div class="divider"></div>

    <ng-container *ngrxLet="useRole$ as role">
      <ng-container
        *ngIf="role === userRoles.ADMIN || role === userRoles.POWERUSER"
      >
        <ul class="mb-0">
          <li class="side-nav-item">
            <b>{{ 'LEFT_SIDEBAR.ADMIN' | translate }}</b>
          </li>
          <li
            data-qa="sidebar-pricing"
            class="cursor-pointer"
            routerLinkActive="menuitem-active"
            [routerLink]="['admin', 'users']"
            (click)="onCloseSidebar()"
          >
            <a>
              <i data-feather="users"></i>
              <span class="cursor-pointer">{{
                'LEFT_SIDEBAR.USER_MANAGEMENT' | translate
              }}</span>
            </a>
          </li>
        </ul>

        <div class="divider"></div>
      </ng-container>
    </ng-container>

    <ul class="mb-0">
      <li class="cursor-pointer">
        <a (click)="onSignOut()">{{ 'SIGN_OUT' | translate }}</a>
      </li>
    </ul>

    <div class="divider"></div>
  </div>
</div>
