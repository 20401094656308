import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthStatus } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { map, Observable } from 'rxjs';

const passwordConfigGuardAllowedStatuses = [
  AuthStatus.CHANGING_PASSWORD,
  AuthStatus.CHANGING_PASSWORD_WITH_MANDATORY_OTP,
  AuthStatus.VALIDATING_OTP_CONFIGURATION,
];
@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
  constructor(private authService: AuthFacade, private router: Router) {}
  canActivate(): Observable<boolean> {
    return this.authService.status$.pipe(
      map((authStatus) => {
        if (passwordConfigGuardAllowedStatuses.includes(authStatus)) {
          return true;
        }
        this.router.navigate(['/login']);
        return false;
      })
    );
  }
}
