import { Injectable } from '@angular/core';
import { AuthDataService, AuthStatus, TOKEN } from '@fe-platform/auth/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  filter,
  map,
  of,
  repeat,
  switchMap,
  tap,
} from 'rxjs';
import { AuthActions } from '../actions';
import {
  handleAuthResponseError$,
  setStatusAfterRefreshSession$,
  setStoreAuthStatusFromLoginResponse$,
  setTokenOnLocalStorage$,
} from '../helpers';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private authDataService: AuthDataService,
    private store: Store
  ) {}
  resetErrorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setStatus),
      filter(({ status }) => status === AuthStatus.AUTHENTICATED),
      map(() => AuthActions.setErrorMessage({ errorMessage: '' }))
    )
  );
  clearTokenToLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetCurrentUser),
      tap(() => {
        localStorage.removeItem(TOKEN);
      }),
      map(() => AuthActions.logoutSuccess()),
      catchError(() => EMPTY)
    )
  );
  fetchCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.fetchCurrentUser),
      switchMap(() => this.authDataService.getCurrentUser()),
      switchMap(({ result }) => [AuthActions.setCurrentUser({ user: result })]),
      catchError(() => of(AuthActions.resetCurrentUser()))
    )
  );
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) => this.authDataService.login(action)),
      setTokenOnLocalStorage$,
      setStoreAuthStatusFromLoginResponse$,
      catchError(handleAuthResponseError$(this.store)),
      repeat()
    )
  );
  refreshSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshSession),
      switchMap(() => this.authDataService.refreshSessionData()),
      setTokenOnLocalStorage$,
      setStatusAfterRefreshSession$,
      catchError(handleAuthResponseError$(this.store)),
      repeat()
    )
  );
}
