import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { take, tap } from 'rxjs';

@Component({
  selector: 'keystone-mission-target-history-bottom-sheet',
  templateUrl: './mission-target-history-bottom-sheet.component.html',
  styleUrls: ['./mission-target-history-bottom-sheet.component.css'],
})
export class MissionTargetHistoryBottomSheetComponent {
  activeTarget$ = this.geospatialService.missions.activeTarget$;

  constructor(
    private router: Router,
    private geospatialService: GeolocationFacade
  ) {}

  public navigateBack(): void {
    this.activeTarget$
      .pipe(
        take(1),
        tap((activeTarget) => {
          this.router.navigate([
            'missions',
            activeTarget?.missionId,
            activeTarget?.id,
          ]);
        })
      )
      .subscribe();
  }
}
