import { AuthResponse, TOKEN } from '@fe-platform/auth/data';
import { Observable, tap } from 'rxjs';
import { AuthResponseIsSuccess } from './authResponse.validators';

export const setTokenOnLocalStorage$ = (
  source$: Observable<AuthResponse>
): Observable<AuthResponse> =>
  source$.pipe(
    tap((response) => {
      if (AuthResponseIsSuccess(response) && response.result.token) {
        localStorage.setItem(TOKEN, response.result.token);
      }
    })
  );
