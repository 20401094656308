import {
  extractTargetFromMission,
  Mission,
  Msisdn,
} from '@fe-platform/geolocation/data';
import { ToastContent } from '@fe-platform/shared-ui/keystone';
import { Observable, tap } from 'rxjs';

export const ensureNumberIsNotInMission$ =
  (msisdnOrImsi: Msisdn) =>
  (mission$: Observable<Mission>): Observable<Mission | never> =>
    mission$.pipe(
      tap((mission) => {
        const target = extractTargetFromMission(msisdnOrImsi, mission);
        if (target) {
          const err =
            msisdnOrImsi === target.imsi
              ? ToastContent.IMSI_ALREADY_INCLUDED_IN_MISSION
              : ToastContent.GEOLOCATION_QUERY_TARGET_ALREADY_INCLUDED;
          throw new Error(err);
        }
      })
    );
