import { BehaviorSubject } from 'rxjs';
import { BottomSheet, BottomSheetConfig, ComponentType } from './BottomSheet';

export class BottomSheetsService {
  private _sheets = new BehaviorSubject<ReadonlyArray<BottomSheet>>([]);
  public sheets$ = this._sheets.asObservable();

  open(
    component: ComponentType<unknown> | null,
    config: BottomSheetConfig,
    onClose?: () => void
  ): BottomSheet {
    const sheet: BottomSheet = {
      component: component,
      config: config,
      onClose: onClose ? onClose : undefined,
    };
    this._sheets.next([...this._sheets.getValue(), sheet]);
    return sheet;
  }

  close(sheet: BottomSheet): void {
    this._sheets.next(this._sheets.getValue().filter((s) => s !== sheet));
    if (sheet.onClose) sheet.onClose();
  }

  closeAll(): void {
    this._sheets.getValue().forEach((sheet) => {
      if (sheet.onClose) sheet.onClose();
    });
    this._sheets.next([]);
  }

  destroy(sheet: BottomSheet): void {
    this._sheets.next(this._sheets.getValue().filter((s) => s !== sheet));
  }

  destroyAll(): void {
    this._sheets.next([]);
  }
}
