import {
  addQueryToMission,
  Mission,
  MissionId,
  queryInMission,
} from '@fe-platform/geolocation/data';
import { createReducer, on } from '@ngrx/store';
import { findActiveMission } from '../helpers';
import { MissionActions } from './actions';
import { createInitialState } from './state';

export const missionReducer = createReducer(
  createInitialState(),
  on(MissionActions.createEmptyMission, (state) => {
    const mission = Mission.createEmpty();
    return {
      ...state,
      missions: [...state.missions, mission],
      activeMissionId: mission.id,
      activeTargetId: null,
      activeQueryId: null,
    };
  }),
  on(MissionActions.deleteMission, (state, action) => {
    const missionToDelete = state.missions.find(
      (m) => m.id === action.missionId
    );
    const newActiveMissionId =
      missionToDelete?.id === state.activeMissionId
        ? state.missions.filter((m) => m.id !== missionToDelete.id)[0].id
        : state.activeMissionId;
    return {
      ...state,
      missions: state.missions.filter((m) => m.id !== action.missionId),
      activeMissionId: newActiveMissionId,
    };
  }),
  on(MissionActions.clearMission, (state) => {
    const activeMission = findActiveMission(
      state.missions,
      state.activeMissionId
    );
    return {
      ...state,
      missions: [
        ...state.missions.map((m) =>
          m.id === activeMission.id ? m.setTargets([]) : m
        ),
      ],
    };
  }),
  on(MissionActions.setActiveMission, (state, action) => {
    return {
      ...state,
      activeMissionId: action.missionId,
      activeQueryId: null,
      activeTargetId: null,
      cellTowers: null,
    };
  }),
  on(MissionActions.changeMapStyle, (state, action) => ({
    ...state,
    missions: state.missions.map((m) =>
      m.id === state.activeMissionId ? m.changeStyle(action.mapStyle) : m
    ),
  })),
  on(MissionActions.setTimebarRange, (state, action) => ({
    ...state,
    missions: state.missions.map((m: Mission) =>
      m.id === state.activeMissionId ? m.updateRange(action.range) : m
    ),
  })),
  on(MissionActions.setActiveTarget, (state, action) => {
    return { ...state, activeTargetId: action.targetId };
  }),
  on(MissionActions.clearActiveTarget, (state) => ({
    ...state,
    activeTarget: null,
  })),
  on(MissionActions.addTargetToActiveMission, (state, action) => {
    return {
      ...state,
      missions: state.missions.map((m) =>
        m.id === state.activeMissionId ? m.addTarget(action.target) : m
      ),
    };
  }),
  on(MissionActions.deleteTargetFromActiveMission, (state, action) => {
    return {
      ...state,
      missions: state.missions.map((m) =>
        m.id === state.activeMissionId ? m.removeTarget(action.target) : m
      ),
    };
  }),
  on(MissionActions.toggleTargetVisibility, (state, action) => {
    const { targetId, visible } = action;

    return {
      ...state,
      activeTargetId:
        targetId === state.activeTargetId ? null : state.activeTargetId,
      activeQueryId:
        targetId === state.activeTargetId ? null : state.activeQueryId,
      missions: state.missions.map((mission) =>
        mission.setTargetVisibility(targetId, visible)
      ),
      expandedTargets: state.expandedTargets.includes(action.targetId)
        ? state.expandedTargets.filter((id) => id !== action.targetId)
        : [...state.expandedTargets],
    };
  }),
  on(MissionActions.addQueriesToTargets, (state, action) => {
    const queries = [...action.queries];
    const missions: Map<MissionId, Mission> = new Map();
    state.missions.forEach((mission) => missions.set(mission.id, mission));
    queries.forEach((query) => {
      missions.forEach((mission) => {
        if (queryInMission(query, mission)) {
          missions.set(mission.id, addQueryToMission(query, mission));
        }
      });
    });
    return { ...state, missions: Array.from(missions.values()) };
  }),
  on(MissionActions.updateQueryInTarget, (state, action) => {
    const missions: Map<MissionId, Mission> = new Map();
    state.missions.forEach((mission) => missions.set(mission.id, mission));

    missions.forEach((mission) => {
      if (queryInMission(action.query, mission)) {
        missions.set(mission.id, addQueryToMission(action.query, mission));
      }
    });

    return { ...state, missions: Array.from(missions.values()) };
  }),
  on(MissionActions.setActiveQuery, (state, action) => {
    return { ...state, activeQueryId: action.queryId, cellTowers: null };
  }),
  on(MissionActions.reset, () => ({
    ...createInitialState(),
  })),
  on(MissionActions.toggleTargetHistory, (state, action) => ({
    ...state,
    expandedTargets: state.expandedTargets.includes(action.targetId)
      ? state.expandedTargets.filter((id) => id !== action.targetId)
      : [...state.expandedTargets, action.targetId],
  })),
  on(MissionActions.updateCellTowers, (state, action) => {
    return { ...state, cellTowers: action.towers };
  })
);
