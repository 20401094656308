import { Component } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'keystone-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styles: [],
})
export class LeftSidebarComponent {
  public isMobile$: Observable<boolean> = this.layoutFacade.isMobile$;

  constructor(private layoutFacade: LayoutFacade) {}
}
