<form [formGroup]="resetPasswordForm">
  <div class="mb-3">
    <label class="form-label">{{ 'LOGIN.NEW_PASSWORD' | translate }}</label>
    <div class="input-group">
      <span class="input-group-text">
        <i class="icon-dual" data-feather="lock" keystoneFeatherIcon></i>
      </span>
      <input
        type="{{ showPassword['current'] ? 'text' : 'password' }}"
        class="form-control"
        formControlName="password"
        [ngClass]="{
          'is-invalid':
            resetPasswordForm.get('password')?.dirty &&
            resetPasswordForm.get('password')?.invalid
        }"
      />
      <keystone-show-password-button
        (changeShowPassword)="
          showPasswordChanged($event, showPasswordLabels.current)
        "
      ></keystone-show-password-button>
    </div>
    <div *ngIf="passwordErrors$ | ngrxPush; let passwordErrors">
      <div class="invalid-feedback">
        {{ passwordErrors | formatPasswordError | translate }}
      </div>
    </div>
  </div>

  <div class="mb-3">
    <label class="form-label">{{
      'LOGIN.CONFIRM_NEW_PASSWORD' | translate
    }}</label>
    <div class="input-group">
      <span class="input-group-text">
        <i class="icon-dual" data-feather="lock" keystoneFeatherIcon></i>
      </span>
      <input
        type="{{ showPassword['confirmCurrent'] ? 'text' : 'password' }}"
        class="form-control"
        [ngClass]="{
          'is-invalid':
            resetPasswordForm.get('confirmPassword')?.dirty &&
            resetPasswordForm.get('confirmPassword')?.invalid
        }"
        formControlName="confirmPassword"
      />
      <keystone-show-password-button
        (changeShowPassword)="
          showPasswordChanged($event, showPasswordLabels.confirmCurrent)
        "
      ></keystone-show-password-button>
    </div>
    <div
      class="invalid-feedback"
      *ngIf="
        resetPasswordForm.get('confirmPassword')?.dirty &&
        resetPasswordForm.get('confirmPassword')?.invalid
      "
    >
      {{ 'PASSWORD.PASSWORDS_ARE_NOT_THE_SAME' | translate }}
    </div>
  </div>
</form>
