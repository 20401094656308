<mgl-marker
  [lngLat]="marker.longLat"
  class="cursor-pointer"
  [className]="
    marker.status === markerStatus.FOCUSED
      ? 'marker-highlighted'
      : marker.status === markerStatus.DEFAULT
      ? 'marker-default'
      : marker.status === markerStatus.DEFOCUSED
      ? 'marker-defocused'
      : ''
  "
>
  <i
    *ngIf="marker.hasExtraInfo"
    [ngbTooltip]="
      'TOOLTIP_CONTENT.ADDITIONAL_INFORMATION_AVAILABLE' | translate
    "
    class="bi bi-info bi-sm"
    [ngClass]="
      marker.status === markerStatus.FOCUSED
        ? 'info-icon-focused'
        : 'info-icon-defocused'
    "
  ></i>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    [ngStyle]="markerStyles[marker.status]"
    (click)="onClickMarker()"
  >
    <g>
      <circle
        cx="13"
        cy="13"
        r="12"
        [ngStyle]="{
          fill: marker.status === markerStatus.FOCUSED ? 'white' : 'none'
        }"
      />
      <circle
        cx="13"
        cy="13"
        r="10"
        [ngStyle]="{
          fill: marker.status === markerStatus.FOCUSED ? '#1A2637' : 'none'
        }"
      />
      <circle
        cx="13"
        cy="13"
        r="8"
        [ngStyle]="{
          fill: marker.color,
          stroke: marker.status === markerStatus.FOCUSED ? 'none' : marker.color
        }"
      />
    </g>
  </svg>
</mgl-marker>
