<ng-container *ngrxLet="isMobile$ as isMobile">
  <!-- web -->
  <ng-container *ngIf="!isMobile; else mobileView">
    <keystone-left-sidebar-web></keystone-left-sidebar-web>
  </ng-container>
  <!-- mobile -->
  <ng-template #mobileView>
    <keystone-left-sidebar-mobile></keystone-left-sidebar-mobile>
  </ng-template>
</ng-container>
