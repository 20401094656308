import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LetModule, PushModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { QueryFormatPipes } from './pipes';
import { QueryLocationStatusBadgeComponent } from './query-location-status-badge/query-location-status-badge.component';
import { QueryNetworkStatusBadgeComponent } from './query-network-status-badge/query-network-status-badge.component';
import { QuerySubscriberStatusBadgeComponent } from './query-subscriber-status-badge/query-subscriber-status-badge.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LetModule,
    PushModule,
    TranslateModule,
  ],
  declarations: [
    QueryLocationStatusBadgeComponent,
    ...QueryFormatPipes,
    QuerySubscriberStatusBadgeComponent,
    QueryNetworkStatusBadgeComponent,
  ],
  exports: [
    QueryLocationStatusBadgeComponent,
    ...QueryFormatPipes,
    QuerySubscriberStatusBadgeComponent,
    QueryNetworkStatusBadgeComponent,
  ],
})
export class GeolocationSharedUiModule {}
