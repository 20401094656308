import { CallInfo } from './callInfo';
import { Cell } from './cell';
import { QueryStatus } from './geolocation-query-status';
import { Location } from './location';
import { MobileDevice } from './mobile-device';
import { NMR } from './nmr';
import { Robot } from './robot';
import { TelcoProvider } from './teclo-provider';

export enum GeolocationQueryType {
  LOGON = 'LOGON',
  SIMPLE = 'SIMPLE',
  FENCED_AREA = 'FENCED_AREA',
  FENCED_QUARANTINE = 'FENCED_QUARANTINE',
  FENCED_COUNTRY = 'FENCED_COUNTRY',
  SCHEDULED = 'SCHEDULED',
  NEAR_POI = 'NEAR_POI',
  PROXIMITY = 'PROXIMITY',
}
export type GeolocationQueryInputType = 'telno' | 'imsi';

export interface GeolocationQueryRequestPayload {
  query_type: string;
  queries: Record<GeolocationQueryInputType, string>[];
  start_at?: number;
  end_at?: number;
  interval_minutes?: number;
}

export type QueryId = string;

export interface GeolocationQuery {
  id: QueryId;
  created_at: string;
  created_by: string;
  nmr: NMR;
  status: QueryStatus;
  updated_at: Date;
  accuracy_meters: number;
  address: string;
  age_of_location_in_minutes: number;
  history_location_count: number;
  azimuth: unknown;
  gps: Record<string, unknown>;
  group: Record<string, unknown>;
  instant_message_profiles: Record<string, unknown>[];
  interception: unknown;
  last_known_location: boolean;
  location_received_at: Date;
  parent_id: string;
  provider: TelcoProvider;
  query_args: Partial<GeolocationQueryArgs>;
  request_id: string;
  roaming: boolean;
  schedule: Robot;
  support_active_query: boolean;
  tag: string;
  type: string;
  call_info?: CallInfo;
  cell?: Cell;
  device?: MobileDevice;
  location?: Location;
}

export interface GeolocationQueryArgs {
  telno: string;
  imsi: string;
}
