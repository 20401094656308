import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicRadioGroupConfig } from './model';

@Component({
  selector: 'keystone-radio-group-with-editable-values',
  templateUrl: './radio-group-with-editable-values.component.html',
  styleUrls: ['./radio-group-with-editable-values.component.css'],
})
export class RadioGroupWithEditableValuesComponent {
  @Input() config!: DynamicRadioGroupConfig;
  @Output() radioSelected = new EventEmitter<string>();
  onRadioSelected(radioSelected: string): void {
    this.radioSelected.emit(radioSelected);
  }
}
