import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  GeolocationQuery,
  QueryStatusContents,
  Target,
} from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { take, tap } from 'rxjs';

@Component({
  selector: 'keystone-mission-target-history-list',
  templateUrl: './mission-target-history-list.component.html',
  styleUrls: ['./mission-target-history-list.component.css'],
})
export class MissionTargetHistoryListComponent {
  @Input() target!: Target;
  status = QueryStatusContents;

  constructor(
    private geospatialService: GeolocationFacade,
    private router: Router
  ) {}

  onNavigateToQuery(query: GeolocationQuery): void {
    if (!this.target.visible) return;
    this.geospatialService.missions.activeMission$
      .pipe(
        take(1),
        tap((activeMission) => {
          this.router.navigate([
            'missions',
            activeMission.id,
            this.target.id,
            'history',
            query.id,
          ]);
        })
      )
      .subscribe();
  }
}
