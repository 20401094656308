import { TimeDiff } from './models/timeDiff';

/**
 * Get the difference between two time values.
 *
 * Returns the difference between two time values in
 * days, hours, minutes and seconds.
 *
 * @param t1 Minuend in milliseconds
 * @param t2 Subtrahend in milliseconds
 * @returns e.g. {days: 1, hours: 2, minutes: 3, seconds: 4}
 *
 * @author Andreas C. Andreou
 */
export const timeDiff = (t1: number, t2: number): TimeDiff => {
  const diff = t1 - t2;

  const millisecPerSec = 1000;
  const millisecPerMin = millisecPerSec * 60;
  const millisecPerHour = millisecPerMin * 60;
  const millisecPerDay = millisecPerHour * 24;

  const days = Math.floor(diff / millisecPerDay);
  const hours = Math.floor((diff % millisecPerDay) / millisecPerHour);
  const minutes = Math.floor((diff % millisecPerHour) / millisecPerMin);
  const seconds = Math.floor((diff % millisecPerMin) / millisecPerSec);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};
