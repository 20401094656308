import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  emailPattern,
  OTPChannels,
  OtpConfiguration,
  User,
} from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { DynamicRadioGroupConfig } from '@fe-platform/shared-ui/keystone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs';
import {
  createRadioConfig,
  extractValueFromOtpForm,
  otpFormValidator,
  otpSelectionIsValid,
  patchOtpConfigForm,
} from './helpers';
import { isValidPhoneFormValidator } from './helpers/phone-number';
@UntilDestroy()
@Component({
  selector: 'keystone-otp-config-form',
  templateUrl: './otp-config-form.component.html',
  styleUrls: ['./otp-config-form.component.css'],
})
export class OtpConfigFormComponent implements OnInit {
  @Output() otpConfigChanged$: EventEmitter<OtpConfiguration> =
    new EventEmitter();
  @Output() formIsValid$: EventEmitter<boolean> = new EventEmitter();
  constructor(private auth: AuthFacade) {}
  public otpConfigForm = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', [
        Validators.pattern(emailPattern),
        Validators.required,
      ]),
      phone: new UntypedFormControl('', isValidPhoneFormValidator()),
      otpChannel: new UntypedFormControl(OTPChannels.None, Validators.required),
    },
    otpFormValidator
  );
  public otpChannelControl = this.otpConfigForm.get(
    'otpChannel'
  ) as UntypedFormControl;
  public radioGroupConfig: DynamicRadioGroupConfig | undefined;

  public currentUser$ = this.auth.currentUser$.pipe(
    untilDestroyed(this),
    filter<User | null>(Boolean),
    tap(patchOtpConfigForm(this.otpConfigForm)),
    tap((user) => {
      this.radioGroupConfig = createRadioConfig(this.otpConfigForm)(user);
    })
  );
  applicationRadioValue = OTPChannels.App;
  public selectedOtpChannel = OTPChannels.None;

  private formStatusSubscription(): void {
    this.otpConfigForm.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(false),
        map(() => !this.otpConfigForm.errors), // the form validity completely relies on the optConfigForm.validator.ts and not on child FormControls
        distinctUntilChanged(),
        tap((valid) => {
          this.formIsValid$.emit(valid);
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.formStatusSubscription();
    this.otpConfigForm.valueChanges.subscribe((v) => console.log(v));
  }
  public handleRadioSelection(otpSelection: string): void {
    if (otpSelectionIsValid(otpSelection)) {
      this.selectedOtpChannel = otpSelection;
      this.otpConfigChanged$.emit(extractValueFromOtpForm(this.otpConfigForm));
    }
  }
}
