import { Component, Input } from '@angular/core';
import {
  GeolocationQuery,
  QueryStatusContents,
} from '@fe-platform/geolocation/data';

@Component({
  selector: 'keystone-query-location-status-badge',
  templateUrl: './query-location-status-badge.component.html',
  styleUrls: ['./query-location-status-badge.component.css'],
})
export class QueryLocationStatusBadgeComponent {
  @Input() query!: GeolocationQuery;
  StatusContents = QueryStatusContents;
}
