<div class="latest-searches mt-4 mb-2">
  <div class="my-1">
    <span data-qa="latest-searches-text">{{
      'LATEST_SEARCHES' | translate
    }}</span>
  </div>
  <hr class="my-2" />
  <ng-container *ngFor="let query of latestQueries">
    <div class="search-single-item">
      <div class="d-flex justify-content-between">
        <div
          class="search-container d-inline cursor-pointer"
          (click)="completeTarget(query)"
        >
          <i class="bi bi-search"></i>
          <span class="d-inline ms-2" data-qa="latest-searches-msisdn">
            {{ query.query_args.telno || query.query_args.imsi }}
          </span>
        </div>
        <span class="d-inline" data-qa="latest-searches-date">{{
          query.created_at | toDate: 'short'
        }}</span>
      </div>
      <hr class="my-2" />
    </div>
  </ng-container>
</div>
