import { Injectable } from '@angular/core';
import { Subscription, fromEvent, merge } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IdleTimerService {
  private timeoutInSec = 1800;
  private interval!: ReturnType<typeof setInterval>;
  private timeoutTracker!: ReturnType<typeof setTimeout>;
  private eventsAll$!: Subscription;
  private onTimeout = () => console.log('Idle timer finished');

  constructor() {
    this.updateExpiredTime = this.updateExpiredTime.bind(this);
  }

  private startTracker(): void {
    this.eventsAll$ = merge(
      fromEvent(window, 'click'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'wheel'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'touchstart')
    ).subscribe({
      next: () => this.updateExpiredTime(),
    });
  }

  private startInterval(): void {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      if (this.getExpiredTime() <= Date.now()) {
        this.stop();
      }
    }, 1000);
  }

  private getExpiredTime(): number {
    return parseInt(localStorage.getItem('_expiredTime') || '0', 10);
  }

  private updateExpiredTime(): void {
    if (this.timeoutTracker) clearTimeout(this.timeoutTracker);

    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(
        '_expiredTime',
        (Date.now() + this.timeoutInSec * 1000).toString()
      );
    }, 300);
  }

  private cleanUp(): void {
    if (this.interval) clearInterval(this.interval);
    localStorage.removeItem('_expiredTime');
    if (this.eventsAll$) this.eventsAll$.unsubscribe();
  }

  /**
   * Starts an idle timer that runs the given onTimeout callback
   * after the app is idle for the given timeout period accross tabs.
   *
   * @param timeout Idle period in seconds.
   * @param onTimeout Callback function to run after the idle timeout.
   */
  public start(timeout?: number, onTimeout?: () => void): void {
    if (timeout) this.timeoutInSec = timeout;
    if (onTimeout) this.onTimeout = onTimeout;

    const expiredTime = this.getExpiredTime();
    if (expiredTime > 0 && expiredTime < Date.now()) {
      this.cleanUp();
    }

    this.startTracker();
    this.startInterval();
  }

  /**
   * Stops the idle timer, runs the onTimeout callback
   * and removes any idle listeners.
   */
  public stop(): void {
    this.onTimeout();
    this.cleanUp();
  }

  /**
   * Stops the idle timer without running the onTimeout callback
   * and removes any idle listeners.
   */
  public destroy(): void {
    this.cleanUp();
  }
}
