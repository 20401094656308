import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { environment } from '@fe-platform/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../toaster';

const listOfHttpRequests = [
  '/notifications',
  '/users',
  '/user',
  '/queries',
  '/hunter-geo-query',
  '/billing-plan',
  '/ledger-item',
  '/tenant-details',
];
@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private toaster: ToasterService,
    private translationService: TranslateService,
    private zone: NgZone
  ) {}

  handleError(error: Error | string): void {
    this.zone.run(() => {
      console.error(error);
      if (environment.production) {
        if (error)
          this.toaster.showError({
            content: this.translationService.instant(
              'ERRORS.GENERIC_ERROR_OCCURRED'
            ),
          });
      } else {
        if (error instanceof HttpErrorResponse) {
          this.handleHttpErrorResponse(error);
        } else if (error instanceof Error) {
          this.handleErrorResponse(error);
        } else {
          this.handleStringErrorResponse(error);
        }
      }
    });
  }

  handleHttpErrorResponse(error: HttpErrorResponse): void {
    if (listOfHttpRequests.some((httpReq) => error.url?.includes(httpReq))) {
      this.toaster.showError({
        content: error.message,
      });
    }
  }

  handleErrorResponse(error: Error): void {
    this.toaster.showError({
      content: error.message,
    });
  }

  handleStringErrorResponse(error: string): void {
    this.toaster.showError({
      content: this.translationService.instant(error),
    });
  }
}
