<div class="d-flex flex-column radio-button-input gap-1">
  <div class="d-flex gap-1 align-items-center">
    <input
      type="radio"
      #radio
      [id]="name"
      [name]="radioGroupName"
      class="form-check-input"
      [value]="value"
      (ngModelChange)="onRadioSelected($event)"
      [formControl]="radioControl"
    />
    <label [for]="name">{{ label | translate }}</label>
  </div>
  <input
    type="text"
    [formControl]="textControl"
    [placeholder]="placeholder | translate"
    [ngClass]="{
      'is-invalid': textControl.dirty && textControl.invalid
    }"
    class="form-control"
  />
</div>
