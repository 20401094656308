import { Injectable } from '@angular/core';
import { NotificationsDataService } from '@fe-platform/notifications/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { NotificationsActions } from './actions';
import { selectAllNotifications } from './selectors';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions: Actions,
    private store: Store,
    private notificationDataSerivce: NotificationsDataService
  ) {}
  markAsRead$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationsActions.markAsRead),
      switchMap((action) =>
        this.notificationDataSerivce.markNotificationsAsRead(action.ids).pipe(
          tap((response) => {
            if (response.result.errors.length) {
              throw new Error('Mark as read operation returned errors.');
            }
          }),
          map(() => NotificationsActions.setAsRead({ ids: action.ids }))
        )
      )
    )
  );
  markAllAsRead$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationsActions.markAllAsRead),
      withLatestFrom(this.store.select(selectAllNotifications)),
      switchMap(([, notifications]) =>
        this.notificationDataSerivce
          .markNotificationsAsRead(notifications.map((n) => n.id))
          .pipe(
            tap((response) => {
              if (response.result.errors.length) {
                throw new Error('Mark as read operation returned errors.');
              }
            }),
            map(() => NotificationsActions.setAllAsRead())
          )
      )
    )
  );
}
