<div class="d-flex">
  <div *ngFor="let mission of missions; let i = index">
    <div class="d-flex mission-select-wrapper">
      <button
        attr.data-qa="{{ 'mission-' + (i + 1) }}"
        type="button"
        class="btn-mission-select btn btn-sm mx-2 position-relative rounded-0"
        [ngClass]="{ active: activeMission.id === mission.id }"
        (click)="onSelectMission(mission.id)"
      >
        {{ 'MISSIONS.MISSION' | translate: { value: i + 1 } }}
        <span
          [ngbTooltip]="'TOOLTIP_CONTENT.CLOSE_MISSION' | translate"
          class="badge position-absolute top-0 start-100 translate-middle rounded-pill bg-secondary cursor-pointer justify-content-center"
          data-qa="delete-mission-btn"
          ><i class="bi-x-lg" (click)="onDeleteMission(mission.id)"></i>
        </span>
      </button>
      <div class="vertical-divider"></div>
    </div>
  </div>
</div>
