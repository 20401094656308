import { Target } from '@fe-platform/geolocation/data';
import { CssSelectorTargetIdPrefix } from '../active-mission/contants';

export function scrollIntoView(target: Target | null): void {
  const elementToScrollTo = document?.getElementById(
    `${CssSelectorTargetIdPrefix + target?.id}`
  );

  const targetsWrapper = document?.getElementById(`targets-wrapper`);

  if (elementToScrollTo && targetsWrapper) {
    const elementRect = elementToScrollTo.getBoundingClientRect();
    const wrapperRect = targetsWrapper.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + wrapperRect.y;
    const scrollToPoint =
      absoluteElementTop -
      (wrapperRect.bottom - wrapperRect.top) / 2 -
      elementRect.height;
    targetsWrapper.scrollTo(0, scrollToPoint);
  }
}
