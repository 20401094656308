import {
  AuthResponse,
  AuthResponseSuccess,
  AuthStatus,
} from '@fe-platform/auth/data';
import { Action } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { AuthActions } from '../actions';
import {
  AuthResponseHasError,
  AuthResponseHasWarning,
} from './authResponse.validators';
function refreshSessionDataIsValid(
  authResponse: AuthResponse
): authResponse is AuthResponse<AuthResponseSuccess> {
  return !(
    AuthResponseHasWarning(authResponse) || AuthResponseHasError(authResponse)
  );
}
export const setStatusAfterRefreshSession$ = (
  source$: Observable<AuthResponse>
): Observable<Action> =>
  source$.pipe(
    switchMap((response: AuthResponse) => {
      if (refreshSessionDataIsValid(response)) {
        return [
          AuthActions.setToken({ token: response.result.token }),
          AuthActions.fetchCurrentUser(),
          AuthActions.setStatus({ status: AuthStatus.AUTHENTICATED }),
        ];
      } else {
        return [AuthActions.setStatus({ status: AuthStatus.UNAUTHENTICATED })];
      }
    })
  );
