import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsStateName } from './reducer';
import { adapter, NotificationsState } from './state';

const { selectAll } = adapter.getSelectors();

export const selectNotificationsState =
  createFeatureSelector<NotificationsState>(NotificationsStateName);

export const selectAllNotifications = createSelector(
  selectNotificationsState,
  selectAll
);
export const selectUnreadNotificationsCount = createSelector(
  selectAllNotifications,
  (notifications) => notifications.filter((n) => !n.is_read).length
);
