import { v4 as uuid } from 'uuid';
import { targetHasBeenLocated } from '../helpers';
import { Target, TargetId } from './target';
import { TimebarRange } from './timebar-range';
export type MissionId = string;
export enum MapStyle {
  NightView = 'mapbox://styles/mapbox/navigation-night-v1',
  SateliteView = 'mapbox://styles/mapbox/satellite-streets-v11',
  StreetView = 'mapbox://styles/mapbox/streets-v11',
}
export class Mission {
  private constructor(
    public readonly id: MissionId,
    public targets: Target[],
    public mapStyle: MapStyle,
    public timebarRange: TimebarRange | null
  ) {}
  public static createEmpty(): Mission {
    return new Mission(uuid(), [], MapStyle.NightView, null);
  }
  get visibleTargets(): Target[] {
    return this.targets.filter((t) => t.visible);
  }
  hasAtLeastOneLocatedTarget(targetToExclude?: Target): boolean {
    return this.visibleTargets
      .filter((t) => t.id !== targetToExclude?.id)
      .some(targetHasBeenLocated);
  }
  hasNoVisibleTargets(targetToExclude?: Target): boolean {
    // a visible target is one that has it's visible property equal false OR a target that has never been located
    return !this.hasAtLeastOneLocatedTarget(targetToExclude);
  }

  changeStyle(mapStyle: MapStyle): Mission {
    return new Mission(this.id, this.targets, mapStyle, this.timebarRange);
  }
  updateRange(timebarRange: TimebarRange | null): Mission {
    return new Mission(this.id, this.targets, this.mapStyle, timebarRange);
  }
  addTarget(target: Target): Mission {
    const targets = [...this.targets, target];
    return new Mission(this.id, targets, this.mapStyle, this.timebarRange);
  }
  removeTarget(target: Target): Mission {
    const targets = this.targets.filter((t) => t.id !== target.id);
    let timebarRange = this.timebarRange;
    if (!targets.length) {
      timebarRange = null;
    }
    return new Mission(this.id, targets, this.mapStyle, timebarRange);
  }
  setTargets(targets: Target[]): Mission {
    return new Mission(this.id, targets, this.mapStyle, this.timebarRange);
  }
  setTargetVisibility(targetId: TargetId, isVisible: boolean): Mission {
    return new Mission(
      this.id,
      this.targets.map((t) =>
        t.id === targetId ? t.setVisibility(isVisible) : t
      ),
      this.mapStyle,
      this.timebarRange
    );
  }
}
