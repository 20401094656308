import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'keystone-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      {{ content }}
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close(false)"
      >
        {{ buttonCancel }}
      </button>
      <button
        *ngIf="buttonName"
        ngbAutofocus
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close(true)"
      >
        {{ buttonName }}
      </button>
    </div>
  `,
})
export class DialogComponent {
  @Input() title!: string;
  @Input() content!: string;
  @Input() buttonName = '';
  @Input() buttonCancel!: string;
  constructor(public activeModal: NgbActiveModal) {}
}
