import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { ToasterService, ToastType } from '@fe-platform/shared-ui/keystone';
import { TranslateService } from '@ngx-translate/core';

export function eventIsManualQueryInsufficientCredits(
  event: unknown
): event is GeolocationQuery {
  return (
    typeof event === 'object' &&
    !!event &&
    'id' in event &&
    'provider' in event &&
    'device' in event &&
    'query_args' in event &&
    'status' in event &&
    (event as { [key: string]: string })['status'] === 'NOT ENOUGH CREDITS'
  );
}

function showInsufficientCreditsManualQueryMessage(
  toaster: ToasterService,
  content: string
): void {
  toaster.showDefault({
    title: ToastType.ERROR,
    content: content,
  });
}

export function createContentForInsufficientCreditsManualQuery(
  translationService: TranslateService,
  toaster: ToasterService
): void {
  const content = translationService.instant(
    'EVENT_NOTIFICATIONS.INSUFFICIENT_CREDITS'
  );
  showInsufficientCreditsManualQueryMessage(toaster, `${content}`);
}
