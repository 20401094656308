import { FeatureFlags } from '@fe-platform/server-config/data';
import {
  createActionGroup,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
  props,
} from '@ngrx/store';

export interface ServerConfigEnvironmentState {
  featureFlags: FeatureFlags | null;
}

export const initialState: ServerConfigEnvironmentState = {
  featureFlags: null,
};
export const ServerConfigStateName = 'ServerConfig';

export const ServerConfigActions = createActionGroup({
  source: 'ServerConfig',
  events: {
    'set Feature Flags': props<{
      featureFlags: FeatureFlags;
    }>(),
  },
});

export const serverConfigReducer = createReducer(
  initialState,
  on(ServerConfigActions.setFeatureFlags, (state, action) => ({
    ...state,
    featureFlags: action.featureFlags,
  }))
);
export const selectServerConfigState =
  createFeatureSelector<ServerConfigEnvironmentState>(ServerConfigStateName);

export const selectFeatureFlags = createSelector(
  selectServerConfigState,
  (state) => state.featureFlags
);
