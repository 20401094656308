<ng-container *ngrxLet="credits$ as credits">
  <div
    class="credits-wrapper d-flex align-items-center justify-content-center"
    [ngbTooltip]="'TOOLTIP_CONTENT.CREDITS_AVAILABLE' | translate"
    data-qa="credit-gauge-text"
    [ngClass]="{
      'credits-border-warning': credits <= creditLimitWarning
    }"
  >
    {{ credits }}
  </div>
</ng-container>
