import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LayoutFacade } from '@fe-platform/core/state';
import {
  ToastContent,
  ToasterService,
  ToastType,
} from '@fe-platform/shared-ui/keystone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { LatestQueriesService } from '../../latest-queries.service';
import { searchInputValidator } from '../../helpers/search-input.validator';

@UntilDestroy()
@Component({
  selector: 'keystone-search-new-query-form',
  templateUrl: './search-new-query-form.component.html',
  styleUrls: ['./search-new-query-form.component.css'],
})
export class SearchNewQueryFormComponent implements AfterViewInit {
  constructor(
    private toasterService: ToasterService,
    private translationService: TranslateService,
    private layoutFacade: LayoutFacade,
    private geoqueryLatestQueriesService: LatestQueriesService
  ) {}
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  inputFocus = false;
  public searchForm: UntypedFormGroup = new UntypedFormGroup({
    target: new UntypedFormControl('', [
      Validators.required,
      searchInputValidator(),
    ]),
  });

  @Output() searchFormSubmitted$: EventEmitter<string> = new EventEmitter();
  isMobile$ = this.layoutFacade.isMobile$;

  ngAfterViewInit(): void {
    this.focusInput();
    this.geoqueryLatestQueriesService.targetForSearch$
      .pipe(
        untilDestroyed(this),
        tap((value) => {
          if (value) {
            this.searchForm.get('target')?.patchValue(value);
            this.focusInput();
          }
        })
      )
      .subscribe();
  }

  private focusInput(): void {
    this.searchInput?.nativeElement.focus();
  }

  onSubmitGeolocationQuerySearch(): void {
    const searchValue = this.searchForm.value.target;
    if (this.searchForm.valid) {
      this.searchFormSubmitted$.emit(searchValue);
    } else {
      this.toasterService.showDefault({
        title: ToastType.ERROR,
        content: this.translationService.instant(
          `TOAST_CONTENT.${ToastContent.GEOLOCATION_QUERY_INPUT_INVALID}`,
          { input: searchValue }
        ),
      });
    }
  }

  public onClearMobileSearchField(): void {
    this.searchForm.reset();
    this.searchInput?.nativeElement.focus();
  }

  onInputFocus(): void {
    this.inputFocus = true;
  }

  onInputBlur(): void {
    this.inputFocus = false;
  }
}
