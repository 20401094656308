import { filter, Observable } from 'rxjs';
import { Notification, NotificationType } from '../models';

const meaningfullNotificationsTypes = [
  NotificationType.TARGET_SUBSCRIPTION_DEACTIVATED,
  NotificationType.LOGON_ROBOT_FINISHED_WITH_LOCATION,
  NotificationType.ROBOT_EXPIRED,
];
export function isMeaningfullNotification(notification: Notification): boolean {
  return meaningfullNotificationsTypes.includes(notification.notification_type);
}
export function isMeaningfullNotification$(
  notification$: Observable<Notification>
): Observable<Notification> {
  return notification$.pipe(filter(isMeaningfullNotification));
}
export function filterOutMeaninglessNotifications(
  notifications: Notification[]
): Notification[] {
  return notifications.filter(isMeaningfullNotification);
}
