import { OTPChannels, OTPCodeRequest, OtpConfiguration } from './models';

export const createOtpPayload = ({
  channel,
  recipient,
}: OtpConfiguration): Partial<OTPCodeRequest> => {
  const password = sessionStorage.getItem('password') as string;
  return {
    otp_channel: channel,
    password,
    otp_status: true,
    otp_email: channel === OTPChannels.Email ? recipient : undefined,
    otp_phone: channel === OTPChannels.OtpPhone ? recipient : undefined,
  };
};
