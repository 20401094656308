import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { GeolocationFacade } from '@fe-platform/geolocation/state';

@Injectable()
export class ActiveQueryGuard implements CanActivate {
  constructor(private geospatialService: GeolocationFacade) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const queryId = route.paramMap.get('queryId');
    if (queryId) {
      this.geospatialService.missions.setActiveQuery(queryId);
      return true;
    }
    return false;
  }
}
