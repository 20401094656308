import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard, AuthGuard } from '@fe-platform/auth/feature-login';
import { BillingGuard } from '@fe-platform/billing/feature-credits';
import {
  ActiveRobotsResolver,
  PendingQueriesResolver,
} from '@fe-platform/geolocation-feature-missions-screen';
import { LayoutComponent } from './layout/layout.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        // component: DashboardComponent,
        redirectTo: '/missions',
        pathMatch: 'full',
      },
      {
        path: 'report-analysis',
        loadChildren: () =>
          import(
            '@fe-platform/geolocation-feature-geolocation-queries-list'
          ).then((m) => m.FeatureGeolocationQueriesListModule),
      },
      {
        path: 'robots',
        loadChildren: () =>
          import('@fe-platform/geolocation/feature-robot-list').then(
            (m) => m.GeolocationFeatureRobotListModule
          ),
      },
      {
        path: 'pricing',
        loadChildren: () =>
          import('@fe-platform/geolocation/pricing').then(
            (m) => m.PricingModule
          ),
      },
      {
        path: 'missions',
        loadChildren: () =>
          import('@fe-platform/geolocation-feature-missions-screen').then(
            (m) => m.FeatureMissionsModule
          ),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('@fe-platform/user-management/feature-users-list').then(
            (m) => m.UserListModule
          ),
        canLoad: [AdminGuard],
      },
    ],
    resolve: [ActiveRobotsResolver, PendingQueriesResolver],
    canActivate: [AuthGuard, BillingGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@fe-platform/auth/feature-login').then(
        (m) => m.AuthFeatureLoginModule
      ),
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
