import { Pipe, PipeTransform } from '@angular/core';
import { getLocale, stringToDate } from '@fe-platform/core/date';
import { Robot } from '@fe-platform/geolocation/data';
import { formatDistance } from 'date-fns';

@Pipe({ name: 'formatRobotDuration', pure: false })
export class RobotDuration implements PipeTransform {
  transform(robot: Robot): string {
    const start = stringToDate(robot.starts_at);
    const end = stringToDate(robot.ends_at);
    return `${formatDistance(start, end, { locale: getLocale() })}`;
  }
}
