import { Component, Input } from '@angular/core';
import { Notification } from '@fe-platform/notifications/data';

@Component({
  selector: 'keystone-notifications-item',
  templateUrl: './notifications-item.component.html',
  styleUrls: ['./notifications-item.component.scss'],
})
export class NotificationsItemComponent {
  @Input() notification!: Notification;
}
