import { Component, Input, OnInit } from '@angular/core';
import { Toast } from '../toast';
import { ToasterService } from '../toaster.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'keystone-mobile-toast',
  templateUrl: './mobile-toast.component.html',
  styleUrls: ['./mobile-toast.component.css'],
})
export class MobileToastComponent implements OnInit {
  @Input() toast!: Toast;
  countdown$!: Observable<number>;

  constructor(private toasterService: ToasterService) {}

  ngOnInit(): void {
    if (this.toast.showCountdown && this.toast.countdown) {
      this.countdown$ = this.toast.countdown
        .asObservable()
        .pipe(map((milliseconds) => milliseconds / 1000));
    }
  }

  onActionClick(toast: Toast): void {
    if (this.toast.closeOnAction) this.removeToast(toast);
  }

  removeToast(toast: Toast): void {
    this.toasterService.removeToast(toast);
  }
}
