import { Component, Input, OnInit } from '@angular/core';
import { BillingFacade } from '@fe-platform/billing/state';
import { LayoutFacade } from '@fe-platform/core/state';
import { environment } from '@fe-platform/environment';
import {
  GeolocationQueryRequestPayload,
  GeolocationQueryType,
} from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import {
  handleErrorAndShowToaster$,
  ToasterService,
} from '@fe-platform/shared-ui/keystone';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { add, format } from 'date-fns';
import { Observable, switchMap } from 'rxjs';
import { RobotModalDetails, ROBOT_MODAL_MODE } from '../model';
interface RobotTimer {
  minutes: number;
  label: string;
}
@Component({
  selector: 'keystone-robots-modal',
  templateUrl: './robots-modal.component.html',
  styleUrls: ['./robots-modal.component.css'],
})
export class RobotsModalComponent implements OnInit {
  @Input() viewMode!: ROBOT_MODAL_MODE;
  @Input() msisdn = '';
  @Input() imsi = '';
  @Input() details: RobotModalDetails | undefined;

  durationIndex = 0;
  durations: RobotTimer[] = [
    ...this.addTestingDurations(),
    { minutes: 1_440, label: `1 ${this.translationService.instant('DAY')}` }, // 60 * 24
    { minutes: 2_880, label: `2 ${this.translationService.instant('DAYS')}` }, // 60 * 24 * 2
    { minutes: 10_080, label: `1 ${this.translationService.instant('WEEK')}` }, // 60 * 24 * 7
    { minutes: 43_200, label: `1 ${this.translationService.instant('MONTH')}` }, // 12 * 24 * 30
  ];

  frequencyIndex = 0;
  frequencies: RobotTimer[] = [
    ...this.addTestingFrequencies(),
    { minutes: 15, label: `15 ${this.translationService.instant('MINUTES')}` },
    { minutes: 30, label: `30 ${this.translationService.instant('MINUTES')}` },
    { minutes: 60, label: `1 ${this.translationService.instant('HOUR')}` },
  ];

  isToggleChecked = true;
  isLogonType = false;

  now = new Date();

  constructor(
    public activeModal: NgbActiveModal,
    private geospatialService: GeolocationFacade,
    private billingFacade: BillingFacade,
    private translationService: TranslateService,
    private layoutFacade: LayoutFacade,
    private toasterService: ToasterService
  ) {}

  isMobile$: Observable<boolean> = this.layoutFacade.isMobile$;

  ngOnInit(): void {
    this.isToggleChecked = this.viewMode === ROBOT_MODAL_MODE.DETAILS;
  }

  onSelectDuration(index: number): void {
    this.durationIndex = index;
  }

  onSelectFrequency(index: number): void {
    this.frequencyIndex = index;
  }

  onSubmit(): void {
    switch (this.viewMode) {
      case ROBOT_MODAL_MODE.CREATION:
        this.createNewRobot();
        break;
      case ROBOT_MODAL_MODE.DETAILS:
        this.deleteExistingRobot();
        break;
    }
  }

  private deleteExistingRobot(): void {
    if (this.details) {
      this.geospatialService.robots
        .deleteRobot(this.details.scheduleId)
        .subscribe();

      this.activeModal.dismiss();
    }
  }

  private createNewRobot(): void {
    this.billingFacade
      .hasSuffecientCredits$('query_location')
      .pipe(
        switchMap(() => {
          const queryType = this.isLogonType ? 'LOGON' : 'SCHEDULED';
          const endDate = add(new Date(), {
            minutes: this.durations[this.durationIndex].minutes,
          });
          const payload: GeolocationQueryRequestPayload = {
            queries: [{ telno: this.msisdn, imsi: this.imsi }],
            query_type: GeolocationQueryType[queryType].toLowerCase(),
            start_at: Number(format(new Date(), 't')),
            end_at: Number(format(endDate, 't')),
            interval_minutes: this.frequencies[this.frequencyIndex].minutes,
          };
          return this.geospatialService.robots.createRobot(payload);
        }),
        handleErrorAndShowToaster$(this.toasterService, this.translationService)
      )
      .subscribe();
    this.activeModal.dismiss();
  }
  private addTestingDurations(): { minutes: number; label: string }[] {
    return environment.robots.enableTestingChoices
      ? [
          {
            minutes: 1,
            label: `1 ${this.translationService.instant('MINUTE')}`,
          },
          {
            minutes: 3,
            label: `3 ${this.translationService.instant('MINUTES')}`,
          },
          {
            minutes: 5,
            label: `5 ${this.translationService.instant('MINUTES')}`,
          },
          {
            minutes: 10,
            label: `10 ${this.translationService.instant('MINUTES')}`,
          },
        ]
      : [];
  }
  private addTestingFrequencies(): { minutes: number; label: string }[] {
    return environment.robots.enableTestingChoices
      ? [
          {
            minutes: 1,
            label: `1 ${this.translationService.instant('MINUTE')}`,
          },
          {
            minutes: 2,
            label: `2 ${this.translationService.instant('MINUTE')}`,
          },
        ]
      : [];
  }
}
