<ng-container *ngrxLet="vm$ as vm">
  <div
    class="notification-list topbar-dropdown"
    ngbDropdown
    [ngbTooltip]="'TOOLTIP_CONTENT.NOTIFICATIONS' | translate"
  >
    <a
      class="nav-link position-relative"
      data-qa="notif-bell-btn"
      role="button"
      ngbDropdownToggle
    >
      <i class="bi bi-bell"></i>
      <span
        *ngIf="vm.unreadNotificationsCount !== 0"
        class="badge bg-danger rounded-circle notification-badge"
        >{{ vm.unreadNotificationsCount }}</span
      >
    </a>
    <div ngbDropdownMenu>
      <ng-container>
        <div class="dropdown-menu-end dropdown-lg">
          <div
            *ngIf="vm.notifications.length"
            class="d-flex justify-content-between m-2"
          >
            <h5
              data-qa="notifications-mark-all-read"
              class="m-0 cursor-pointer"
              (click)="markAllAsRead()"
            >
              {{ 'NOTIFICATIONS.MARK_AS_READ' | translate }}
            </h5>
            <div class="cursor-pointer" (click)="deleteAllNotifications()">
              <i class="bi bi-trash"></i>
              <span data-qa="notifications-delete-all">{{
                'NOTIFICATIONS.DELETE_ALL' | translate
              }}</span>
            </div>
          </div>

          <div class="noti-scroll" data-qa="notifications-scroll">
            <ng-container *ngFor="let notification of vm.notifications">
              <keystone-notifications-item
                [notification]="notification"
                (click)="markAsRead(notification)"
              ></keystone-notifications-item>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!vm.unreadNotificationsCount">
        <div class="m-2" data-qa="notification-all-caught-up">
          {{ 'NOTIFICATIONS.ALL_CAUGHT_UP' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
