import { GeolocationQueryArgs } from '@fe-platform/geolocation/data';

export function getTargetFromQueryArgs(
  queryArgs: Partial<GeolocationQueryArgs>
): string {
  if (queryArgs.telno) {
    return queryArgs.telno;
  } else if (queryArgs.imsi) {
    return queryArgs.imsi;
  }

  return '-';
}
