import { OTPChannels } from '@fe-platform/auth/data';

export function otpSelectionIsValid(
  otpSelection: unknown
): otpSelection is OTPChannels {
  switch (otpSelection) {
    case OTPChannels.App:
    case OTPChannels.Email:
    case OTPChannels.OtpPhone:
    case OTPChannels.None:
      return true;
    default:
      return false;
  }
}
