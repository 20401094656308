import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthStatus, TOKEN } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { Observable, map, skipWhile, switchMap, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthFacade, private router: Router) {}
  canActivate(): Observable<boolean> {
    return this.authService.status$.pipe(
      skipWhile((status) => status === AuthStatus.UNKNOWN),
      switchMap((status) => {
        if (status === AuthStatus.AUTHENTICATED) {
          this.router.navigate(['/']);
          return of(false);
        } else {
          if (localStorage.getItem(TOKEN)) {
            return this.authService.logout().pipe(map(() => true));
          } else {
            return of(true);
          }
        }
      })
    );
  }
}
