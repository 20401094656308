import { Pipe, PipeTransform } from '@angular/core';
import { Robot } from '@fe-platform/geolocation/data';
import { EMPTY } from './empty';

@Pipe({ name: 'formatRobotType' })
export class RobotType implements PipeTransform {
  transform(robot: Robot | undefined): string {
    const types = {
      scheduled: 'ROBOTS.CONTINUOUS',
      logon: 'ROBOTS.END_WHEN_LOCATION_FOUND',
    } as Record<string, string>;
    if (robot?.schedule_type) {
      return types[robot.schedule_type];
    }
    return EMPTY;
  }
}
