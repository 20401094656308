import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { layerPaint, layerPaintLAC } from '../radiusLayerStyle';
import { PinRadiusStyle } from './../radiusLayerStyle';

export function styleRadius(
  geolocationQuery: GeolocationQuery | null
): PinRadiusStyle {
  return geolocationQuery &&
    geolocationQuery.cell?.lac &&
    !geolocationQuery.cell?.cell_id
    ? layerPaintLAC
    : layerPaint;
}
