import { GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatLocationType: ColumnDataFormatter<
  GeolocationQuery,
  string
> = (query: GeolocationQuery) => {
  if (
    query.nmr &&
    (query.nmr.sector_location || query.nmr.trialteration_location)
  ) {
    return 'GEO_RESOLUTION.2_SECTOR';
  }

  if (query.cell?.lac && !query.cell?.cell_id) {
    return 'GEO_RESOLUTION.4_LAC';
  }

  if (query.cell?.lac && query.cell?.cell_id) {
    return 'GEO_RESOLUTION.3_CELL_ID';
  }

  if (query.location?.coordinates?.length) {
    return 'GEO_RESOLUTION.1_COORDINATES';
  }

  return 'GEO_RESOLUTION.0_NONE';
};
