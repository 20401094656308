import { Injectable } from '@angular/core';
import {
  AuthDataService,
  AuthPayload,
  AuthStatus,
  OtpConfiguration,
  PasswordValidationResult,
  TOKEN,
} from '@fe-platform/auth/data';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import { AuthActions } from './actions';
import { tokenIsValid } from './helpers';
import * as selectors from './selectors';
@Injectable({ providedIn: 'root' })
export class AuthFacade {
  currentUser$ = this.store.select(selectors.selectCurrentUser);
  token$ = this.store.select(selectors.selectToken);
  status$ = this.store.select(selectors.selectStatus);
  useRole$ = this.store.select(selectors.selectUserRole);
  errorMessage$ = this.store.select(selectors.selectErrorMessage);
  otpConfiguration$ = this.store.select(selectors.selectOtpConfiguration);

  constructor(private store: Store, private authDataService: AuthDataService) {
    const tokenProperties = localStorage.getItem(TOKEN) ?? '';
    if (tokenIsValid(tokenProperties)) {
      this.store.dispatch(AuthActions.refreshSession());
    } else {
      localStorage.removeItem(TOKEN);
      this.store.dispatch(
        AuthActions.setStatus({ status: AuthStatus.UNAUTHENTICATED })
      );
    }
  }
  public login(payload: AuthPayload): void {
    this.store.dispatch(AuthActions.login(payload));
  }

  public submitOtpConfiguration(otpConfiguration: OtpConfiguration): void {
    this.store.dispatch(AuthActions.submitOtpConfiguration(otpConfiguration));
  }
  public validateOtpConfiguration(otpCode: string): void {
    this.store.dispatch(AuthActions.validateOtpConfiguration({ otpCode }));
  }

  public resetPassword(
    oldPassword: string,
    newPassword: string,
    otpCode?: string
  ): void {
    this.store.dispatch(
      AuthActions.resetPassword({
        oldPassword,
        newPassword,
        otpCode,
      })
    );
  }

  public checkPasswordPolicy(
    password: string
  ): Observable<PasswordValidationResult> {
    return this.authDataService.checkPasswordPolicy(password);
  }
  public generateOTPAuthenticatorQRCode(): Observable<string> {
    return this.authDataService.generateOTPAuthenticatorQRCode();
  }
  public logout(): Observable<Response> {
    return this.authDataService.logout().pipe(
      tap(() => {
        this.store.dispatch(AuthActions.resetCurrentUser());
      })
    );
  }
  public refreshSession(): void {
    this.store.dispatch(AuthActions.refreshSession());
  }
}
