import { map, Observable } from 'rxjs';
import { Imsi, Mission, Msisdn, Target } from '../models';

export function extractVisibleTargetFromMission(
  msisdnOrImsi: Msisdn,
  mission: Mission
): Target | null {
  const targetIndex = mission.targets
    .filter((t) => t.visible)
    .findIndex((m) => m.msisdn === msisdnOrImsi || m.imsi === msisdnOrImsi);
  return targetIndex > -1 ? mission.targets[targetIndex] : null;
}
export function extractTargetFromMission(
  msisdnOrImsi: Msisdn,
  mission: Mission
): Target | null {
  const targetIndex = mission.targets.findIndex(
    (m) => m.msisdn === msisdnOrImsi || m.imsi === msisdnOrImsi
  );
  return targetIndex > -1 ? mission.targets[targetIndex] : null;
}
export const extractTargetFromMission$ =
  (msisdnOrImsi: Msisdn | Imsi) =>
  (mission$: Observable<Mission>): Observable<Target | null> =>
    mission$.pipe(
      map((mission) => {
        const targetIndex = mission.targets.findIndex(
          (m) => m.msisdn === msisdnOrImsi || m.imsi === msisdnOrImsi
        );
        if (targetIndex < 0)
          throw new Error('failed to get target from mission');
        return mission.targets[targetIndex];
      })
    );
