import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatus, OtpConfiguration } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'keystone-otp-config',
  templateUrl: './otp-config.component.html',
  styleUrls: ['./otp-config.component.css'],
})
export class OtpConfigComponent {
  constructor(
    private auth: AuthFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  formIsValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  otpConfiguration: OtpConfiguration | undefined;
  handleFormIsValid(formIsValid: boolean): void {
    this.formIsValid$.next(formIsValid);
  }
  handleOtpSelection(value: OtpConfiguration): void {
    this.otpConfiguration = value;
  }
  submitOtpConfiguration(): void {
    if (this.otpConfiguration) {
      this.auth.submitOtpConfiguration(this.otpConfiguration);
      this.auth.status$
        .pipe(
          untilDestroyed(this),
          tap((status) => {
            if (status === AuthStatus.VALIDATING_OTP_CONFIGURATION) {
              this.router.navigate(['../otp'], { relativeTo: this.route });
            }
          })
        )
        .subscribe();
    }
  }
}
