import { stringToDate } from '@fe-platform/core/date';
import { differenceInMinutes } from 'date-fns';
import { Robot } from '../../models';

export const formatRobotScheduledSearch = (robot: Robot): number => {
  const start = stringToDate(robot.starts_at);
  const end = stringToDate(robot.ends_at);

  return Math.ceil(
    Math.abs(differenceInMinutes(start, end) / robot.interval_minutes)
  );
};
