import { Injectable } from '@angular/core';
import { AuthFacade } from '@fe-platform/auth/state';
import { BillingFacade } from '@fe-platform/billing/state';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { InstantNotificationFacade } from '@fe-platform/notifications/feature-instant-notifications';
import { LoaderService } from '@fe-platform/shared-ui/keystone';

@Injectable()
export class LayoutService {
  constructor(
    private loader: LoaderService,
    private geospatialService: GeolocationFacade,
    private billingService: BillingFacade,
    private authFacade: AuthFacade,
    private instantNotificationFacade: InstantNotificationFacade
  ) {}

  signOut(): void {
    this.loader.toggleLoading(true);
    this.geospatialService.missions.reset();
    this.billingService.reset();
    this.authFacade.logout().subscribe({
      next: () => this.loader.toggleLoading(false),
      error: () => location.reload(),
    });
    this.instantNotificationFacade.clearAllNotifications();
  }
}
