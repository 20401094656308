import { BehaviorSubject, Subject } from 'rxjs';
import { ToastContent } from './toastContent';

const DEFAULT_TOASTER_DELAY = 10_000;

export interface Toast {
  title: string;
  content: string;
  timestamp: string;
  delay: number;
  autohide: boolean;
  closeOnAction: boolean;
  showCountdown?: boolean;
  countdownInterval?: ReturnType<typeof setInterval>;
  countdown?: BehaviorSubject<number>;
  closed$: Subject<boolean>;
  icon?: string;
  action?: ToastAction;
  action_btn?: ToastAction;
}

export interface ToastAction {
  content: string;
  actionClicked: (...args: unknown[]) => void;
}

export const DEFAULT_TOAST: Toast = {
  title: '',
  content: ToastContent.EMPTY_TOAST,
  timestamp: '',
  delay: DEFAULT_TOASTER_DELAY,
  autohide: true,
  closeOnAction: true,
  closed$: new Subject<boolean>(),
};
