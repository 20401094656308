import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@fe-platform/environment';

const ExternalScripts = {
  recaptcha: `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaKey}`,
  hotjar: `assets/scripts/hotjar.js`,
};

if (environment.production) {
  enableProdMode();
}
if (environment.recaptchaKey) {
  appendExternalScript(ExternalScripts.recaptcha);
}
if (environment.hotjarKey) {
  appendExternalScript(ExternalScripts.hotjar, [
    { name: 'hotjarKey', value: environment.hotjarKey },
  ]);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

function appendExternalScript(
  srcPath: string,
  customAttibutes?: { name: string; value: string }[]
): void {
  const script = document.createElement('script');

  if (customAttibutes?.length) {
    customAttibutes.forEach((attr) =>
      script.setAttribute(attr.name, attr.value)
    );
  }

  script.src = srcPath;
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  document.getElementsByTagName('head')[0].appendChild(script);
}
