import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveMissionComponent } from './active-mission/active-mission.component';
import { ActiveMissionGuard, ActiveQueryGuard } from './guards';
import { ActiveTargetGuard } from './guards/active-target.guard';
import { MissionsContainerComponent } from './missions-container.component';
import { MissionTargetHistoryComponent } from './mission-target-history/mission-target-history.component';

const missionsRoutes: Routes = [
  {
    path: '',
    component: MissionsContainerComponent,
    children: [
      {
        path: ':missionId/:targetId/history',
        component: MissionTargetHistoryComponent,
        canActivate: [ActiveMissionGuard, ActiveTargetGuard],
      },
      {
        path: ':missionId/:targetId/:queryId',
        loadChildren: () =>
          import('./mission-query-card/mission-query-card.module').then(
            (m) => m.MissionQueryCardModule
          ),
        canActivate: [ActiveMissionGuard, ActiveTargetGuard, ActiveQueryGuard],
      },
      {
        path: ':missionId/:targetId/history/:queryId',
        loadChildren: () =>
          import('./mission-query-card/mission-query-card.module').then(
            (m) => m.MissionQueryCardModule
          ),
        canActivate: [ActiveMissionGuard, ActiveTargetGuard, ActiveQueryGuard],
      },
      {
        path: ':missionId/:targetId',
        component: ActiveMissionComponent,
        canActivate: [ActiveMissionGuard, ActiveTargetGuard],
      },
      {
        path: ':missionId',
        component: ActiveMissionComponent,
        canActivate: [ActiveMissionGuard],
      },
      {
        path: '**',
        component: ActiveMissionComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(missionsRoutes)],
  exports: [RouterModule],
})
export class FeatureMissionsRoutingModule {}
