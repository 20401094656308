import { GeolocationQuery } from '../models';

export function extractImsiFromGeoQueries(
  geoqueries: GeolocationQuery[]
): string {
  if (!geoqueries.length) return '';
  return extractImsiFromGeoQuery(geoqueries[0]);
}
export function extractImsiFromGeoQuery(geoquery: GeolocationQuery): string {
  if (!geoquery.provider?.imsi) return '';
  return geoquery.provider.imsi;
}
