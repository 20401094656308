import {
  BalanceType,
  BillingLimitType,
  BillingPolicies,
  CreditPools,
} from '@fe-platform/billing/data';

export enum OTPChannels {
  Email = 'email',
  OtpPhone = 'sms',
  App = 'app',
  None = '',
}

export interface UsersQueryParams {
  limit: number;
  page: number;
  sortKey: string;
  sortOrder: number;
  filterArg: string;
}

export interface User {
  id: string;
  username: string;
  roles: Array<UserRoles>;
  email?: string;
  quota: number;
  max_quota: number;
  identity?: string;
  jti?: string;
  otp_enabled?: boolean;
  otp_phone?: string;
  phone?: string;
  otp_channel?: OTPChannels;
  is_locked?: boolean;
  is_temporary?: boolean;
  temporary_password?: string;
  password_expires_at?: Date;
  first_login_at?: Date;
  used_active_time_ms?: number;
  user_claims?: {
    roles?: Array<string>;
    email?: string;
    uid: string;
  };
  features?: string[];
  accurate_location?: boolean;
  email_notifications?: boolean;

  sms_notifications?: boolean;
  policies?: BillingPolicies[];
  balance_type: BalanceType;
  current_balance?: CreditPools | number;
  initial_balance: CreditPools | number;
  limit_type: BillingLimitType | null;
  defaultPool?: number;
  created_by: string;
  created_at: Date;
  updated_at: Date;
}

export enum UserRoles {
  ADMIN = 'admin',
  POWERUSER = 'poweruser',
  USER = 'user',
  SUPPORT = 'support',
}
