import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TempModule } from '@fe-platform/shared-ui/keystone';
import { RobotsModalService } from './robots-modal.service';
import { RobotsModalComponent } from './robots-modal/robots-modal.component';
import { LetModule, PushModule } from '@ngrx/component';

@NgModule({
  declarations: [RobotsModalComponent],
  imports: [CommonModule, FormsModule, LetModule, PushModule, TempModule],
  providers: [RobotsModalService],
  exports: [RobotsModalComponent],
})
export class GeolocationFeatureRobotsModalModule {}
