import { Inject, Injectable } from '@angular/core';
import { GEO_URL, RECAPTCHA_KEY } from '@fe-platform/core/config';
import { ApiService } from '@fe-platform/core/http';
import { from, map, Observable, switchMap, tap } from 'rxjs';
import {
  AuthPayload,
  AuthResponse,
  OTPCodeRequest,
  OtpResponse,
  PasswordValidationResult,
  User,
} from './models';
import { UserResponse } from './models/auth.model';
@Injectable({ providedIn: 'root' })
export class AuthDataService {
  constructor(
    @Inject(GEO_URL) private geoUrl: string,
    @Inject(RECAPTCHA_KEY) private recaptchaKey: string,
    private apiService: ApiService
  ) {}
  public login(payload: AuthPayload): Observable<AuthResponse> {
    const login$ = (recaptchaToken = ''): Observable<AuthResponse> =>
      this.apiService.post<
        AuthResponse,
        AuthPayload & { recaptchaToken: string }
      >(`${this.geoUrl}/auth`, {
        ...payload,
        recaptchaToken,
      });
    if (this.recaptchaKey) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const grecaptcha = (window as any).grecaptcha;
        if (!grecaptcha) throw new Error('grecaptcha does not exist on window');
        return from(
          grecaptcha.execute(this.recaptchaKey, {
            action: 'login',
          }) as Promise<string>
        ).pipe(
          switchMap((recaptchaResponse: string) => login$(recaptchaResponse))
        );
      } catch (error) {
        console.error(
          'Failed to get recaptcha. The application is not expected to work propertly.'
        );
        console.log(error);
      }
    }
    return login$();
  }

  public getCurrentUser = (): Observable<{ result: User }> =>
    this.apiService.get<{ result: User }>(`${this.geoUrl}/user`);

  public logout = (): Observable<Response> =>
    this.apiService.get(`${this.geoUrl}/logout`);

  public postOtpConfiguration(
    codePayload: Partial<OTPCodeRequest>
  ): Observable<OtpResponse> {
    return this.apiService.post(
      `${this.geoUrl}/security/otp/code`,
      codePayload
    );
  }
  public validateOtpConfiguration(
    codePayload: Partial<OTPCodeRequest>
  ): Observable<OtpResponse> {
    return this.apiService
      .post<OtpResponse, Partial<OTPCodeRequest>>(
        `${this.geoUrl}/security/otp/code`,
        codePayload
      )
      .pipe(
        tap((response) => {
          if (response.result.status !== 'ok') {
            throw new Error(response.result.message);
          }
        })
      );
  }
  public resetPassword(
    oldPassword: string,
    newPassword: string
  ): Observable<UserResponse> {
    return this.apiService.put(`${this.geoUrl}/user/reset-password`, {
      oldPassword,
      newPassword,
    });
  }

  public checkPasswordPolicy(
    password: string
  ): Observable<PasswordValidationResult> {
    return this.apiService.post(
      `${this.geoUrl}/security/password/format/validate`,
      { pwd: password }
    );
  }

  public generateOTPAuthenticatorQRCode(): Observable<string> {
    return this.apiService
      .get<Blob>(`${this.geoUrl}/security/otp/generate/qr?${Date.now()}`)
      .pipe(map((blob) => URL.createObjectURL(blob))) as Observable<string>;
  }
  public refreshSessionData(): Observable<AuthResponse> {
    return this.apiService.get(`${this.geoUrl}/security/session/data`);
  }
}
