import { AuthResponse, AuthStatus } from '@fe-platform/auth/data';
import { Action } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { AuthActions } from '../actions';
import {
  AuthResponseHasError,
  AuthResponseHasWarning,
  AuthResponseIsSuccess,
  AuthResponseIsSupport,
} from './authResponse.validators';

export const setStoreAuthStatusFromLoginResponse$ = (
  source$: Observable<AuthResponse>
): Observable<Action> =>
  source$.pipe(
    switchMap((response: AuthResponse) => {
      const actions: Action[] = [];
      if (AuthResponseHasWarning(response)) {
        const result = response.result;
        actions.push(AuthActions.setToken({ token: response.result.token }));
        if (result.request_password_change && result.force_2fa_enablement) {
          actions.push(
            AuthActions.setStatus({
              status: AuthStatus.CHANGING_PASSWORD_WITH_MANDATORY_OTP,
            }),
            AuthActions.fetchCurrentUser()
          );
        } else if (result.request_password_change) {
          actions.push(
            AuthActions.setStatus({ status: AuthStatus.CHANGING_PASSWORD }),
            AuthActions.fetchCurrentUser()
          );
        } else if (result.force_2fa_enablement) {
          actions.push(
            AuthActions.setStatus({
              status: AuthStatus.CONFIGURING_OTP,
            }),
            AuthActions.fetchCurrentUser()
          );
        }
      } else if (AuthResponseHasError(response)) {
        const result = response.result;
        if (result.otp_code_required) {
          if (result.otp_recipient && result.otp_channel) {
            actions.push(
              AuthActions.setOtpConfiguration({
                channel: result.otp_channel,
                hashedRecipient: result.otp_recipient,
                recipient: '',
              })
            );
          } else {
            throw new Error(
              'OTP code required but otp response payload is considered invalid.'
            );
          }
          actions.push(
            AuthActions.setStatus({ status: AuthStatus.WAITING_FOR_OTP_INPUT })
          );
          if (result.message) {
            actions.push(
              AuthActions.setErrorMessage({ errorMessage: result.message })
            );
          }
        }
      } else if (AuthResponseIsSupport(response)) {
        throw new Error('ACCESS_TO_SUPPORT_NOT_ALLOWED');
      } else if (AuthResponseIsSuccess(response)) {
        const result = response.result;
        actions.push(
          AuthActions.setToken({ token: result.token }),
          AuthActions.setStatus({ status: AuthStatus.AUTHENTICATED }),
          AuthActions.fetchCurrentUser()
        );
      }
      return actions;
    })
  );
