<div class="map-wrapper" *ngrxLet="mapStyle$ as mapStyle">
  <mgl-map
    [style]="mapStyle"
    [zoom]="[3]"
    [center]="[-2, -10]"
    (mapLoad)="mapLoaded($event)"
  >
    <mgl-control mglNavigation [showCompass]="false"></mgl-control>

    <ng-container *ngrxLet="activeQueryGeojson$ as geojson">
      <ng-container *ngIf="geojson">
        <mgl-geojson-source
          id="activeQuery"
          [data]="geojson"
        ></mgl-geojson-source>
        <mgl-layer
          source="activeQuery"
          id="pin-radius"
          type="fill"
          [paint]="pinRadiusStyle"
        >
        </mgl-layer>
      </ng-container>
    </ng-container>

    <ng-container *ngrxLet="activeQuerySectorGeojson$ as sector">
      <ng-container *ngIf="sector">
        <mgl-geojson-source
          id="activeQuerySector"
          [data]="sector"
        ></mgl-geojson-source>
        <mgl-layer
          source="activeQuerySector"
          id="pin-sector"
          type="fill"
          [paint]="pinRadiusStyle"
        >
        </mgl-layer>
      </ng-container>
    </ng-container>

    <ng-container *ngrxLet="markers$ as markers">
      <keystone-map-marker
        *ngFor="let marker of markers; trackByMarker"
        [marker]="marker"
        (markerClicked)="onClickMarker($event)"
      >
      </keystone-map-marker>
    </ng-container>

    <ng-container *ngrxLet="cellTowers$ as cellTowers">
      <ng-container *ngFor="let cellTower of cellTowers">
        <keystone-cell-tower-pin
          [cellTower]="cellTower"
        ></keystone-cell-tower-pin>
      </ng-container>
    </ng-container>
  </mgl-map>

  <div id="map-style-select" ngbDropdown>
    <button
      ngbDropdownToggle
      id="dropdownStyles"
      class="btn btn-layer d-flex align-items-center justify-content-center"
      [ngbTooltip]="'TOOLTIP_CONTENT.CHANGE_MAP_VIEW' | translate"
      type="button"
    >
      <i class="uil uil-layers"></i>
    </button>
    <div
      ngbDropdownMenu
      class="bg-white w-max-content text-center px-1 pt-2"
      aria-labelledby="dropdownStyles"
    >
      <div
        class="d-inline-flex flex-column justify-content-center align-items-center cursor-pointer mx-1 mb-1"
        data-qa="map-default"
        [class.active-layer]="mapStyle === mapStyles.NightView"
        (click)="onSetMapStyle(mapStyles.NightView)"
      >
        <img
          class="map-style-thumb mb-1"
          [src]="'assets/images/night-view.jpg'"
        />
        <span>{{ 'MISSIONS.DEFAULT' | translate }}</span>
      </div>
      <div
        class="d-inline-flex flex-column justify-content-center align-items-center cursor-pointer mx-1 mb-1"
        data-qa="map-satellite"
        [class.active-layer]="mapStyle === mapStyles.SateliteView"
        (click)="onSetMapStyle(mapStyles.SateliteView)"
      >
        <img
          class="map-style-thumb mb-1"
          [src]="'assets/images/satelite-view.jpg'"
        />
        <span>{{ 'MISSIONS.SATELITE' | translate }}</span>
      </div>
      <div
        class="d-inline-flex flex-column justify-content-center align-items-center cursor-pointer mx-1 mb-1"
        data-qa="map-bright"
        [class.active-layer]="mapStyle === mapStyles.StreetView"
        (click)="onSetMapStyle(mapStyles.StreetView)"
      >
        <img
          class="map-style-thumb mb-1"
          [src]="'assets/images/street-view.jpg'"
        />
        <span>{{ 'MISSIONS.BRIGHT' | translate }}</span>
      </div>
    </div>
  </div>
</div>
