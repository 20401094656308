<div class="card col-10 col-md-4 p-4">
  <keystone-otp-config-form
    (formIsValid$)="handleFormIsValid($event)"
    (otpConfigChanged$)="handleOtpSelection($event)"
  >
  </keystone-otp-config-form>
  <div class="mb-3 text-center d-grid">
    <button
      class="btn btn-primary"
      (click)="submitOtpConfiguration()"
      [disabled]="(formIsValid$ | ngrxPush) === false"
    >
      {{ 'CONTINUE' | translate }}
    </button>
  </div>
</div>
