import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge } from 'rxjs';
import { NetworkStatus } from './network-status.enum';

@Injectable()
export class NetworkStatusFacade {
  networkStatus$ = new BehaviorSubject<NetworkStatus>(NetworkStatus.ONLINE);
  isOffline$ = this.networkStatus$.pipe(
    map((status) => status === NetworkStatus.OFFLINE)
  );
  constructor() {
    merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true))
    ).subscribe((isOnline) => {
      isOnline
        ? this.networkStatus$.next(NetworkStatus.ONLINE)
        : this.networkStatus$.next(NetworkStatus.OFFLINE);
    });
  }
}
