import { ElementRef } from '@angular/core';
import { TimebarEvent } from '@fe-platform/geolocation/data';
import { TranslateService } from '@ngx-translate/core';

export const createTooltipPositions = (
  event: TimebarEvent,
  tooltip: ElementRef,
  translateService: TranslateService
): string | null => {
  tooltip.nativeElement.classList.remove('fadeout');
  const tooltipTop =
    event.args.tooltipY - tooltip.nativeElement.offsetHeight - 40;
  const tooltipLeft =
    event.args.tooltipX - tooltip.nativeElement.offsetWidth / 2;
  tooltip.nativeElement.style.left = `${tooltipLeft}px`;
  tooltip.nativeElement.style.top = `${tooltipTop}px`;
  if (event.args.type === 'bar') {
    return (
      event.args.value +
      ' ' +
      translateService.instant(
        event.args.value === 1
          ? `TOOLTIP_CONTENT.LOCATION`
          : `TOOLTIP_CONTENT.LOCATIONS`
      )
    );
  } else if (event.args.type === 'fit') {
    return translateService.instant(`TOOLTIP_CONTENT.RESET_TIMELINE`);
  } else if (event.args.type === 'prev') {
    return translateService.instant(`TOOLTIP_CONTENT.MOVE_BACKWARDS`);
  } else if (event.args.type === 'next') {
    return translateService.instant(`TOOLTIP_CONTENT.MOVE_FORWARD`);
  } else {
    tooltip.nativeElement.classList.add('fadeout');
    return null;
  }
};
