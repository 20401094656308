import { Injectable } from '@angular/core';
import {
  extractUniqueMsisdnsFromAllMissions,
  GeolocationQuery,
  Robot,
  Target,
} from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { StreamManager } from '@fe-platform/stream-manager';
import {
  filter,
  forkJoin,
  map,
  merge,
  Observable,
  retry,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { NetworkStatus, NetworkStatusFacade } from './network-status';
// This service is responsible for making sure that data on the app is always up to date, even after losing connection
// and reconnecting

@Injectable()
export class NetworkLostStateManager {
  constructor(
    private networkService: NetworkStatusFacade,
    private geospatialFacade: GeolocationFacade,
    private streamManager: StreamManager
  ) {}
  private refreshPendingQueries(): Observable<GeolocationQuery[]> {
    return this.geospatialFacade.pendingQueries
      .fetchPendingGeolocationQueries()
      .pipe(
        tap((queries) =>
          this.geospatialFacade.pendingQueries.setPendingQueries(queries)
        )
      );
  }
  private refreshActiveRobots(): Observable<Robot[]> {
    return this.geospatialFacade.robots
      .fetchRobots()
      .pipe(
        tap((robots) => this.geospatialFacade.robots.setActiveRobots(robots))
      );
  }
  private refreshAllTargetsQueryLists(): Observable<
    {
      target: Target;
      queries: GeolocationQuery[];
    }[]
  > {
    return this.geospatialFacade.missions.missions$.pipe(
      take(1),
      map(extractUniqueMsisdnsFromAllMissions),
      switchMap((msisdns) =>
        forkJoin(
          msisdns.map((msisdn) =>
            this.geospatialFacade.missions.fetchGeolocationQueriesByNumber(
              msisdn
            )
          )
        )
      )
    );
  }
  public restoreAppStateOnNetworkRestore$(): Observable<unknown> {
    // this merge ensures that the same workflow will happen
    // - When the network is triggered from Offline to Online, and
    // - When the websocket connection was reestablished, even if the app never went offline.
    return merge(
      this.networkService.networkStatus$.pipe(
        skip(1), // skip initial status for app init
        filter((status) => status === NetworkStatus.ONLINE),
        tap(() => this.streamManager.initializeConnection()),
        switchMap(() => this.streamManager.connected$.pipe(take(1)))
      ),
      this.streamManager.connected$.pipe(skip(2)) // skiping connected statuses on app init (false -> true). We are only interested in reconnecting events.
    ).pipe(
      filter(Boolean),
      switchMap(() => {
        console.log('resync...');
        return forkJoin([
          this.refreshPendingQueries(),
          this.refreshActiveRobots(),
          this.refreshAllTargetsQueryLists(),
        ]).pipe(retry({ count: 5, delay: 2000 }));
      })
    );
  }
}
