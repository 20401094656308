import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationEffects } from './effects';
import { notificationsReducer, NotificationsStateName } from './reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(NotificationsStateName, notificationsReducer),
    EffectsModule.forFeature([NotificationEffects]),
  ],
})
export class NotificationsFeatureStoreModule {}
