import { BillingActionsType } from './billingActions';

export enum BillingPolicies {
  USAGE_LIMITS = 'USAGE_LIMITS',
  MAX_USAGE = 'MAX_USAGE',
}

export enum BillingLimitType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  MAXIMUM = 'MAXIMUM',
  FIXED = 'FIXED',
}

// we only use geolocation for now.
export interface CreditPools {
  geolocation: number | null;
  webint: number | null;
  call_log: number | null;
  somedus: number | null;
  total: number;
}
export enum BalanceType {
  DISTRIBUTED = 'distributed',
  SINGLE = 'single',
}
export type BillingPlan = Record<BillingActionsType, { cost: number }>;

export function createInitialBalance(
  balanceType: BalanceType
): number | CreditPools {
  if (balanceType === BalanceType.DISTRIBUTED) {
    return { geolocation: 0, webint: 0, call_log: 0, somedus: 0, total: 0 };
  }
  if (balanceType === BalanceType.SINGLE) {
    return 0;
  }
  throw new Error('Balance type not supported');
}
