import { Robot } from '@fe-platform/geolocation/data';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import {
  createActionGroup,
  createFeatureSelector,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';

export type RobotsState = EntityState<Robot>;

export const adapter: EntityAdapter<Robot> = createEntityAdapter<Robot>();

export const initialState: RobotsState = adapter.getInitialState();
export const RobotsStateName = 'Robots';
export const RobotActions = createActionGroup({
  source: 'Robots',
  events: {
    'set Active Robot': props<{ robot: Robot }>(),
    'set Active Robots': props<{ robots: Robot[] }>(),
    'remove Active Robot': props<{ robotId: string }>(),
    'clear Active Robots': emptyProps(),
  },
});
export const robotsReducer = createReducer(
  { ...initialState },
  on(RobotActions.setActiveRobots, (state, { robots }) =>
    !robots.length ? adapter.removeAll(state) : adapter.setAll(robots, state)
  ),
  on(RobotActions.setActiveRobot, (state, action) =>
    adapter.setOne(action.robot, state)
  ),
  on(RobotActions.removeActiveRobot, (state, action) =>
    adapter.removeOne(action.robotId, state)
  )
);
export const { selectAll } = adapter.getSelectors();
export const selectRobotsFeature =
  createFeatureSelector<RobotsState>(RobotsStateName);

export const selectActiveRobots = createSelector(
  selectRobotsFeature,
  selectAll
);
