import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
} from '@angular/router';
import { User, UserRoles } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { catchError, filter, map, Observable, of, skipWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(private authService: AuthFacade, private router: Router) {}

  canLoad(): boolean | Observable<boolean> {
    return this.authService.currentUser$.pipe(
      skipWhile((u) => !u),
      filter(Boolean),
      map((user: User) => {
        if (
          user.roles.includes(UserRoles.ADMIN) ||
          user.roles.includes(UserRoles.POWERUSER)
        ) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }),
      catchError((err) => {
        console.log(err);
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }

  canActivate(): boolean | Observable<boolean> {
    return this.canLoad();
  }

  canActivateChild(): boolean | Observable<boolean> {
    return this.canLoad();
  }
}
