import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { BroadcastService } from '../../services/broadcast.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'keystone-idle-timer-modal',
  templateUrl: './idle-timer-modal.component.html',
  styleUrls: ['./idle-timer-modal.component.css'],
})
export class IdleTimerModalComponent implements OnInit, OnDestroy {
  private countdownInSec = 59;
  private countdownInterval!: ReturnType<typeof setInterval>;
  private _countdown$ = new BehaviorSubject<number>(this.countdownInSec);
  public countdown$ = this._countdown$.asObservable();

  constructor(
    public activeModal: NgbActiveModal,
    private broadcastService: BroadcastService
  ) {}

  ngOnInit(): void {
    this.countdownInterval = setInterval(() => {
      if (this.countdownInSec <= 0) {
        this.activeModal.close('exit');
      } else {
        this._countdown$.next((this.countdownInSec -= 1));
      }
    }, 1000);

    // Check if 'continue' was pressed in different tab
    this.broadcastService
      .listen('continue')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.activeModal.close('continue'),
      });
  }

  ngOnDestroy(): void {
    if (this.countdownInterval) clearInterval(this.countdownInterval);
  }

  onContinue() {
    this.broadcastService.publish('continue');
    this.activeModal.close('continue');
  }
}
