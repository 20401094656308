export enum CellType {
  UNKNOWN,
  '2G' = 2,
  '3G',
  '4G',
  '5G',
}
export interface Cell {
  cell_id: number;
  cell_type: CellType;
  enbid?: string;
  lac: number;
  lcid?: string;
  mcc: string;
  mnc: string;
  zone: string;
}
