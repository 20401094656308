import { Component } from '@angular/core';
import { User, UserRoles } from '@fe-platform/auth/data';
import { PasswordChangeFormComponent } from '@fe-platform/auth/feature-password-form';
import { AuthFacade } from '@fe-platform/auth/state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { LeftSidebarService } from '../left-sidebar.service';
import { LayoutService } from '../../layout.service';

@UntilDestroy()
@Component({
  selector: 'keystone-left-sidebar-mobile',
  templateUrl: './left-sidebar-mobile.component.html',
  styleUrls: ['./left-sidebar-mobile.component.css'],
  providers: [LayoutService, LeftSidebarService],
})
export class LeftSidebarMobileComponent {
  public useRole$ = this.authFacade.useRole$;
  userRoles = UserRoles;

  public currentUser$ = this.authFacade.currentUser$.pipe(
    untilDestroyed(this),
    filter<User | null>(Boolean)
  );

  constructor(
    private authFacade: AuthFacade,
    private modalService: NgbModal,
    private sidebarService: LeftSidebarService,
    private layoutService: LayoutService
  ) {}

  onCloseSidebar(): void {
    document.body.classList.remove('sidebar-enable');
  }

  navigateToMission(event?: Event): void {
    this.sidebarService.navigateToMission(event);
  }

  onShowChangePassword(): void {
    this.modalService.open(PasswordChangeFormComponent);
  }

  onSignOut(): void {
    this.layoutService.signOut();
  }
}
