import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthFeatureOtpFormModule } from '@fe-platform/auth/feature-otp-form';
import { AuthFeaturePasswordFormModule } from '@fe-platform/auth/feature-password-form';
import { TempModule } from '@fe-platform/shared-ui/keystone';
import { LetModule, PushModule } from '@ngrx/component';
import { AuthFeatureLoginRoutingModule } from './auth-feature-login-routing.module';
import { LoginComponent } from './components/login/login.component';
import { OtpConfigComponent } from './components/otp-config/otp-config.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { ResetPasswordAfterLoginComponent } from './components/reset-password-after-login/reset-password-after-login.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthFeatureLoginRoutingModule,
    FormsModule,
    TempModule,
    AuthFeatureOtpFormModule,
    AuthFeaturePasswordFormModule,
    LetModule,
    PushModule,
  ],
  declarations: [
    LoginComponent,
    OtpInputComponent,
    OtpConfigComponent,
    ResetPasswordAfterLoginComponent,
  ],
})
export class AuthFeatureLoginModule {}
