import { Component, Input } from '@angular/core';
import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { LatestQueriesService } from '../../latest-queries.service';

@Component({
  selector: 'keystone-latest-queries-list',
  templateUrl: './latest-queries-list.component.html',
})
export class LatestQueriesListComponent {
  @Input() latestQueries!: GeolocationQuery[];

  constructor(
    private geoquerySearchBoxLatestQueriesService: LatestQueriesService
  ) {}

  completeTarget(query: GeolocationQuery): void {
    const target = query.query_args.telno || query.query_args.imsi;
    this.geoquerySearchBoxLatestQueriesService.setLatestTargetForSearch(target);
  }
}
