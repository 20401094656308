import { GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatPhoneModel: ColumnDataFormatter<GeolocationQuery, string> = (
  query
) => {
  const phoneModel = query?.device?.phone_model;
  if (!phoneModel || phoneModel === 'None') return '-';
  return phoneModel;
};
