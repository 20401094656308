import { Mission } from '../models';

const targetAvailableColors = [
  '#2F8DFA',
  '#1FD0BF',
  '#EB648B',
  '#F8C753',
  '#EB7E30',
  '#A93790',
  '#058A7D',
  '#1553B6',
  '#CCE94D',
  '#42B3D5',
] as const;
export function determineTargetColor(mission: Mission): string {
  const existentColors = mission.targets.map((t) => t.color);
  const availableColors = targetAvailableColors.filter(
    (color) => !existentColors.includes(color)
  );
  return availableColors[0];
}

export const timebarColors = [...targetAvailableColors];
