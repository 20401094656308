import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActiveRobotsResolver implements Resolve<void> {
  constructor(private geospatialService: GeolocationFacade) {}
  resolve(): Observable<void> {
    return this.geospatialService.robots
      .fetchRobots()
      .pipe(
        map((schedules) =>
          this.geospatialService.robots.setActiveRobots(schedules)
        )
      );
  }
}
