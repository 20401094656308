import { GeolocationQueryParams } from '../models';

export function generateGeolocationQueryParams(
  params: GeolocationQueryParams
): Record<string, string> {
  return Object.keys(params)
    .filter((paramKey) => params[paramKey as keyof GeolocationQueryParams])
    .reduce(
      (paramsFiltered, paramKey) => ({
        ...paramsFiltered,
        [paramKey]: params[paramKey as keyof GeolocationQueryParams].toString(),
      }),
      {}
    );
}
