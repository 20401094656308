import { Environment } from './environment.model';
const wsUrl = window.location.host;
const wsScheme = window.location.href.startsWith('https') ? 'wss' : 'ws';
export const environment: Environment = {
  production: true,
  hotjarKey: '',
  recaptchaKey: '6Le3IWcaAAAAAJpRtWK2LaCrQvTg4DaWrrIRuPMw',
  reportsUrl: '/reports',
  reportsV2Url: `/reports-v2`,
  geoUrl: '/api/v1',
  wsUrl: `${wsScheme}://${wsUrl}`,
  fastApiUrl: '/fastapi/api/v2',
  proxyAPIUri: `/server-ts`,
  fetchRetries: 3,
  missions: {
    maxMissions: 5,
    maxTargets: 10,
  },
  billing: {
    creditLimitWarning: 10,
  },
  mapBoxToken:
    'pk.eyJ1IjoiaW50ZWxtYXBib3giLCJhIjoiY2w3cmhhajAyMGZkdDNucDI4YmljbGIzdCJ9.Vs-8FmAmlvY8x5iDprTzUg',
  features: {},
  robots: {
    enableTestingChoices: true,
  },
  idleTimeoutInSec: 1800,
};
