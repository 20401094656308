<keystone-geoquery-search-box></keystone-geoquery-search-box>
<ng-container *ngrxLet="activeMission$ as mission">
  <div id="targets-wrapper" [ngClass]="{ empty: !mission.targets.length }">
    <keystone-mission-target-card
      id="{{ CssSelectorTargetIdPrefix + target.id }}"
      *ngFor="let target of mission.targets; trackBy: targetTrackBy"
      [target]="target"
      [missionId]="mission.id"
      class="d-block mb-2"
    ></keystone-mission-target-card>
  </div>
</ng-container>
