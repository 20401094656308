import { Injectable } from '@angular/core';
import { NotificationEvent } from '@fe-platform/geolocation/state';
import {
  isMeaningfullNotification$,
  Notification,
  NotificationId,
  NotificationsDataService,
} from '@fe-platform/notifications/data';
import { StreamManager } from '@fe-platform/stream-manager';
import { Store } from '@ngrx/store';
import { EventChannel } from '@trg-commons/gio-data-models-ts';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationsActions } from './actions';
import {
  selectAllNotifications,
  selectUnreadNotificationsCount,
} from './selectors';
import { NotificationsState } from './state';

@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  notifications$ = this.store.select(selectAllNotifications);
  unreadNotificationsCount$ = this.store.select(selectUnreadNotificationsCount);
  constructor(
    private store: Store<NotificationsState>,
    private notificationsService: NotificationsDataService,
    private streamManager: StreamManager
  ) {
    this.subscribeToNotificationChanges();
  }

  fetchNotifications(): void {
    this.notificationsService
      .getNotifications()
      .pipe(
        tap((notificationsResponse) => {
          const notifications = notificationsResponse.result;
          this.store.dispatch(
            NotificationsActions.setNotifications({
              notifications,
            })
          );
        })
      )
      .subscribe();
  }

  deleteAllNotifications(): void {
    this.notificationsService
      .deleteNotifications()
      .pipe(
        tap(() => {
          this.store.dispatch(NotificationsActions.clearNotifications());
        })
      )
      .subscribe();
  }

  private subscribeToNotificationChanges(): Subscription {
    return this.streamManager
      .onEvent<Notification>(
        EventChannel.GeofrontNotification,
        NotificationEvent
      )
      .pipe(isMeaningfullNotification$)
      .subscribe((notification: Notification) => {
        this.store.dispatch(
          NotificationsActions.pushNotification({ notification })
        );
      });
  }
  markAsRead(notificationId: NotificationId): void {
    this.store.dispatch(
      NotificationsActions.markAsRead({ ids: [notificationId] })
    );
  }
  markAllAsRead(): void {
    this.store.dispatch(NotificationsActions.markAllAsRead());
  }
}
