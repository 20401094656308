import { Component, Input } from '@angular/core';

@Component({
  selector: 'keystone-logo',
  template: `<img
    src="assets/images/keystone-logo-white.png"
    [width]="width"
    [height]="height"
    alt=""
    srcset=""
  />`,
  styleUrls: ['./logo.component.css'],
})
export class LogoComponent {
  // default size below reflects desktop top bar logo size
  @Input() width = 147;
  @Input() height = 30;
}
