import { Pipe, PipeTransform } from '@angular/core';
import {
  getNotificationTarget,
  Notification,
  NotificationType,
} from '@fe-platform/notifications/data';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'transformToNotificationText' })
export class NotificationTextPipe implements PipeTransform {
  constructor(private translationService: TranslateService) {}
  transform(notification: Notification): Observable<string> {
    const target = getNotificationTarget(notification);
    switch (notification.notification_type) {
      case NotificationType.LOGON_ROBOT_FINISHED_WITH_LOCATION:
        return this.translationService.stream('NOTIFICATIONS.LOCATION_FOUND', {
          subject: target,
        });
      case NotificationType.TARGET_SUBSCRIPTION_DEACTIVATED:
        return this.translationService.stream(
          'NOTIFICATIONS.TARGET_SUBSCRIPTION_DEACTIVATED',
          { subject: target }
        );
      case NotificationType.ROBOT_EXPIRED:
        return this.translationService.stream('NOTIFICATIONS.ROBOT_EXPIRED', {
          subject: target,
        });
      default:
        return this.translationService.stream(
          'NOTIFICATIONS.TRANSLATION_ERROR'
        );
    }
  }
}
