import { toMediumDateString } from '@fe-platform/core/date';
import { Robot } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatStartDate: ColumnDataFormatter<Robot, string> = (robot) => {
  if (robot.created_at) {
    return toMediumDateString(robot.created_at);
  }
  return '-';
};
