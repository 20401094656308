<div keystoneClickOutside (clickOutside)="onCloseSearch()">
  <button
    *ngrxLet="activeMission$ as activeMission"
    class="btn btn-primary btn-floating"
    [class.elevated]="activeMission.targets.length !== 0"
    type="button"
    (click)="onToggleSearch()"
  >
    <i
      class="uil"
      [ngClass]="showSearchMobile ? 'uil-multiply' : 'uil-plus'"
    ></i>
  </button>

  <div
    *ngIf="showSearchMobile"
    id="searchMobileWrapper"
    class="fade-in ps-2 pe-2"
  >
    <keystone-search-new-query-form
      (searchFormSubmitted$)="onSubmit($event)"
    ></keystone-search-new-query-form>

    <ng-container *ngrxLet="vm$ as vm">
      <keystone-latest-queries-list
        *ngIf="vm.latestQueries.length && !vm.loading"
        [latestQueries]="vm.latestQueries"
      ></keystone-latest-queries-list>
      <keystone-loader
        *ngIf="vm.loading"
        [fullscreen]="false"
        class="mt-2"
      ></keystone-loader>
    </ng-container>
  </div>
</div>
