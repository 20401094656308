import { Mission } from '../models/mission';
import { Observable, tap } from 'rxjs';

export const validateMaxTargetsInMission$ =
  (maxTargets: number) =>
  (mission$: Observable<Mission>): Observable<Mission | never> =>
    mission$.pipe(
      tap((mission) => {
        if (mission.targets.length >= maxTargets)
          throw new Error('MAX_TARGETS_IN_MISSION');
      })
    );
