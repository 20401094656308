import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { LOCAL_STORAGE_LANGUAGE_KEY } from '@fe-platform/core/constants';
import { fade, LoaderService } from '@fe-platform/shared-ui/keystone';
@Component({
  selector: 'keystone-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade],
})
export class AppComponent {
  constructor(
    private loader: LoaderService,
    private router: Router,
    translate: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loader.toggleLoading(true);
        document.body.classList.remove('sidebar-enable');
      }
      if (event instanceof NavigationEnd) {
        this.loader.toggleLoading(false);
      }
    });

    translate.setDefaultLang('en'); // fallback if a translation isn't found in the current language

    // // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) || 'en');
  }
  loading$ = this.loader.loading$;
}
