<ng-container *ngIf="target">
  <ng-container [ngSwitch]="!!target.latestLocatedDate">
    <ng-container *ngSwitchCase="true">
      <ng-container [ngSwitch]="!!hasPendingQuery">
        <ng-container *ngSwitchCase="true">
          <div class="d-inline-flex align-items-center">
            <i *ngIf="isMobile" class="bi-circle-fill me-1"></i>
            <span>{{ 'TARGET_CARD.LOCATING' | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <div
            class="d-inline-flex align-items-center"
            [ngbTooltip]="'TOOLTIP_CONTENT.DATE_LAST_LOCATED' | translate"
          >
            <i *ngIf="isMobile" class="bi-circle-fill text-success me-1"></i>
            <i *ngIf="!isMobile" class="uil uil-crosshair lh-1 me-1"></i>
            <span
              *ngIf="target.latestLocatedDate"
              data-qa="target-card-badge"
              >{{ target.latestLocatedDate | toDate }}</span
            >
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <ng-container *ngIf="!isMobile">
        <div
          class="badge p-1 badge-soft-secondary"
          data-qa="target-card-badge"
          *ngIf="!target.latestLocatedDate"
        >
          {{ 'TARGET_CARD.NEVER_LOCATED' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="isMobile">
        <div class="d-inline-flex align-items-center">
          <i class="bi-circle-fill icon-danger me-1"></i>
          <span *ngIf="!target.latestLocatedDate" data-qa="target-card-badge">{{
            'TARGET_CARD.NEVER_LOCATED' | translate
          }}</span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
