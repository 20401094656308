import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Marker, MarkerStatus } from '@fe-platform/geolocation/data';
import { MarkerStyles } from '../mission-map/constants';

@Component({
  selector: 'keystone-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.css'],
})
export class MapMarkerComponent {
  markerStatus = MarkerStatus;
  markerStyles = MarkerStyles;
  @Input() marker!: Marker;
  @Output() markerClicked = new EventEmitter<Marker>();
  onClickMarker(): void {
    this.markerClicked.emit(this.marker);
  }
}
