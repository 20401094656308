export enum QueryStatus {
  EXPIRED = 'EXPIRED',
  DONE = 'DONE',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  LOCATION_PENDING = 'LOCATION PENDING',
  NO_LOCATION = 'NO LOCATION',
}
export const QueryPendingStatuses = [
  QueryStatus.PENDING,
  QueryStatus.LOCATION_PENDING,
];
