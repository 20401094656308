import { Router } from '@angular/router';
import {
  extractTargetsFromMissions$,
  GeolocationQuery,
  GeolocationQueryDataService,
  geoqueryInTimerange,
  QueryFormatters,
} from '@fe-platform/geolocation/data';
import {
  filterGeoqueriesByTimerange$,
  GeolocationFacade,
} from '@fe-platform/geolocation/state';
import { getTargetFromQueryArgs } from '@fe-platform/notifications/data';
import { ToasterService } from '@fe-platform/shared-ui/keystone';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap, take, tap, throwError } from 'rxjs';

export function eventIsGeolocationQueryCompletedSuccessfull(
  event: unknown
): event is GeolocationQuery {
  return (
    typeof event === 'object' &&
    !!event &&
    'id' in event &&
    'provider' in event &&
    'device' in event &&
    'query_args' in event &&
    'status' in event &&
    (event as { [key: string]: string })['status'] === 'DONE'
  );
}

export function createSuccessfullGeolocationQueryToaster(
  translationService: TranslateService,
  toaster: ToasterService,
  query: GeolocationQuery,
  geospatialService: GeolocationFacade,
  geolocationQueryDataService: GeolocationQueryDataService,
  router: Router
): void {
  const title = getTargetFromQueryArgs(query.query_args);
  const content = `${translationService.instant(
    'EVENT_NOTIFICATIONS.NEW_LOCATION_FOUND'
  )} (${translationService.instant(QueryFormatters.locationType(query))})`;
  const action = {
    content: `${translationService.instant('EVENT_NOTIFICATIONS.VIEW')}`,
    actionClicked: () => findQueryInMissions(geospatialService, query, router),
  };
  const action_btn = {
    content: `${translationService.instant('TOAST_CONTENT.RETRIEVE_SECTOR')}`,
    actionClicked: () =>
      retrieveSector(
        geolocationQueryDataService,
        geospatialService,
        query,
        router
      ),
  };
  const shouldShowIcon = !!query.schedule;

  if (
    query.nmr &&
    (query.nmr.trialteration_location || query.nmr.sector_location) &&
    query['tag'] !== '' &&
    !query['tag']
  ) {
    toaster.showDefault({
      title: title,
      content: content,
      action: action,
      delay: 60_000,
      closeOnAction: false,
      showCountdown: true,
      action_btn: action_btn,
      icon: shouldShowIcon ? 'uil uil-robot uil-menu-icon' : undefined,
    });
  } else {
    toaster.showDefault({
      title: title,
      content: content,
      action: action,
      icon: shouldShowIcon ? 'uil uil-robot uil-menu-icon' : undefined,
    });
  }
}

function retrieveSector(
  geolocationQueryDataService: GeolocationQueryDataService,
  geospatialService: GeolocationFacade,
  query: GeolocationQuery,
  router: Router
) {
  geolocationQueryDataService.chargeNMRQuery(query.id).subscribe({
    next: (result) => {
      const newQuery = result.result;
      geospatialService.missions.addQueriesToTargets([newQuery]);
      findQueryInMissions(geospatialService, newQuery, router);
    },
    error: (e) => {
      throwError(() => e);
    },
  });
}

function findQueryInMissions(
  geospatialService: GeolocationFacade,
  query: GeolocationQuery,
  router: Router
) {
  geospatialService.missions.missions$
    .pipe(
      take(1),
      extractTargetsFromMissions$(query.provider.telno || query.provider.imsi),
      filter((targets) => !!targets.length),
      map((targets) => targets[targets.length - 1]),
      switchMap((target) => {
        return geospatialService.missions.missions$.pipe(
          take(1),
          map((missions) => missions.find((m) => m.id === target.missionId)),
          filter(Boolean),
          filterGeoqueriesByTimerange$,
          tap((mission) => {
            if (!geoqueryInTimerange(query, mission.timebarRange)) {
              geospatialService.missions.setTimebarRange(null);
            }
          }),
          map(() => target)
        );
      })
    )
    .subscribe((target) => {
      router.navigate(['missions', target.missionId, target.id, query.id]);
    });
}
