<ng-container *ngrxLet="mission$ as mission">
  <div class="position-relative" *ngIf="mission.targets.length">
    <keystone-kl-components (klReady)="klReady($event)">
      <keystone-kl-component
        type="timebar"
        (klEvents)="klTimebarEvents($event)"
        [options]="timebarOptions"
      >
      </keystone-kl-component>
    </keystone-kl-components>

    <div #tooltip class="timebar-tooltip fadeout">
      <ng-container *ngrxLet="tooltipContent$ as tooltipContent">
        <div class="arrow"></div>
        <div class="inner">
          <span>
            {{ tooltipContent }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
