import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@fe-platform/auth/state';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { ToasterService } from '@fe-platform/shared-ui/keystone';
import { StreamManager } from '@fe-platform/stream-manager';
import { TranslateService } from '@ngx-translate/core';
import {
  filter,
  map,
  merge,
  Observable,
  of,
  Subscription,
  withLatestFrom,
} from 'rxjs';
import { InstantNotificationSources, sources } from './models';
import {
  createContentForFailedManualQuery,
  createContentForInsufficientCreditsManualQuery,
  createContentForRobotTerminated,
  createSuccessfullGeolocationQueryToaster,
  eventIsGeolocationQueryCompletedSuccessfull,
  eventIsManualQueryFailed,
  eventIsManualQueryInsufficientCredits,
  eventIsRobotTerminated,
  eventIsTriggeredByCurrentUser,
} from './use-cases';
import { GeolocationQueryDataService } from '@fe-platform/geolocation/data';

@Injectable({
  providedIn: 'root',
})
export class InstantNotificationFacade {
  constructor(
    private streamManager: StreamManager,
    private translationService: TranslateService,
    private toaster: ToasterService,
    private geospatialService: GeolocationFacade,
    private geolocationQueryDataService: GeolocationQueryDataService,
    private router: Router,
    private authFacade: AuthFacade
  ) {
    this.subscribeToEventNotificationChanges(of(sources));
  }

  clearAllNotifications(): void {
    this.toaster.removeAllToasts();
  }

  private subscribeToEventNotificationChanges(
    sources$: Observable<InstantNotificationSources[]>
  ): Subscription {
    return merge(
      sources$.pipe(
        map((sources) => {
          return sources.map((source) =>
            this.streamManager
              .onEvent(source.channel, source.eventName)
              .pipe(
                withLatestFrom(
                  this.authFacade.currentUser$.pipe(map((u) => u?.username))
                ),
                filter(([event, username]) =>
                  eventIsTriggeredByCurrentUser(event, username)
                ),
                map(([event]) => {
                  if (eventIsGeolocationQueryCompletedSuccessfull(event)) {
                    createSuccessfullGeolocationQueryToaster(
                      this.translationService,
                      this.toaster,
                      event,
                      this.geospatialService,
                      this.geolocationQueryDataService,
                      this.router
                    );
                  } else if (eventIsManualQueryFailed(event)) {
                    createContentForFailedManualQuery(
                      this.translationService,
                      this.toaster,
                      event
                    );
                  } else if (eventIsManualQueryInsufficientCredits(event)) {
                    createContentForInsufficientCreditsManualQuery(
                      this.translationService,
                      this.toaster
                    );
                  } else if (eventIsRobotTerminated(event)) {
                    createContentForRobotTerminated(
                      this.translationService,
                      this.toaster,
                      event
                    );
                  }
                })
              )
              .subscribe()
          );
        })
      )
    ).subscribe();
  }
}
