import {
  GeolocationQuery,
  Marker,
  MarkerStatus,
  Target,
} from '@fe-platform/geolocation/data';
import { isBefore } from 'date-fns';
import { map, Observable } from 'rxjs';

export const styleMarkers = (
  source$: Observable<[Marker[], Target | null, GeolocationQuery | null]>
): Observable<Marker[]> =>
  source$.pipe(
    map(([markers, activeTarget, activeQuery]) => {
      if (activeQuery && activeTarget) {
        const styledTargetMarkers = markerStatusByFilter(
          markerStatusByFilter(markers, MarkerStatus.DEFAULT),
          MarkerStatus.DEFOCUSED,
          (marker) => marker.targetId !== activeTarget.id
        );
        return markerStatusByFilter(
          styledTargetMarkers,
          MarkerStatus.FOCUSED,
          (marker) => activeQuery.id === marker.queryId
        );
      } else if (activeTarget) {
        const latestFoundQuery: GeolocationQuery | undefined =
          activeTarget.queries
            .filter((q) => !!q.location?.coordinates)
            .sort((a, b) =>
              isBefore(new Date(a.created_at), new Date(b.created_at)) ? 1 : -1
            )[0];
        const styledTargetMarkers = markerStatusByFilter(
          markerStatusByFilter(markers, MarkerStatus.DEFAULT),
          MarkerStatus.DEFOCUSED,
          (marker) => marker.targetId !== activeTarget.id
        );
        const stylesLatestFoundMarker = markerStatusByFilter(
          styledTargetMarkers,
          MarkerStatus.FOCUSED,
          (marker) => marker.queryId === latestFoundQuery?.id
        );
        return stylesLatestFoundMarker;
      } else {
        return markerStatusByFilter(markers, MarkerStatus.DEFAULT);
      }
    })
  );
function markerStatusByFilter(
  markers: Marker[],
  status: MarkerStatus,
  filter: (marker: Marker) => boolean = (): true => true
): Marker[] {
  return markers.map((marker) => ({
    ...marker,
    status: filter(marker) ? status : marker.status,
  }));
}
