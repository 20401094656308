<div class="toast-header" data-qa="toast">
  <strong class="me-auto" data-qa="toast-title">{{ toast.title }}</strong>
  <small *ngIf="toast.timestamp">{{ toast.timestamp | toDate: 'short' }}</small>
  <button
    type="button"
    aria-label="Close"
    class="btn-close"
    data-qa="toast-close-btn"
    (click)="removeToast(toast)"
  ></button>
</div>
<div class="toast-body">
  <div data-qa="toast-body-text">
    <ng-container *ngIf="toast.icon">
      <i [class]="toast.icon"></i>
    </ng-container>
    {{ toast.content }}
    <p *ngIf="toast.action_btn" class="text-muted mb-0">
      {{ 'TOAST_CONTENT.PRESS_ENTER' | translate }}
    </p>
  </div>
  <button
    data-qa="toast-view-btn"
    *ngIf="toast.action"
    class="btn btn-outline-primary mt-1 w-100"
    type="button"
    (click)="toast.action.actionClicked(); onActionClick(toast)"
  >
    {{ toast.action.content }}
  </button>
  <button
    *ngIf="toast.action_btn"
    class="btn btn-primary btn-toaster-action mt-1 w-100"
    data-qa="toast-locate-btn"
    type="button"
    (click)="toast.action_btn.actionClicked(); removeToast(toast)"
  >
    {{ toast.action_btn.content }}
    <span *ngIf="toast.showCountdown">
      <span class="mx-1">|</span>
      <span class="me-1 spinner-border text-secondary"></span>
      <span *ngrxLet="countdown$ as countdown">
        {{ countdown }} {{ countdown | i18nPlural: ('SECONDS' | translate) }}
      </span>
    </span>
  </button>
</div>
