<div class="modal-header" data-qa="modal-robots">
  <h4 class="modal-title" data-qa="modal-title">
    {{ 'ROBOTS_MODAL.SEARCH_FOR' | translate: { telno: msisdn || imsi } }}
  </h4>
  <button
    type="button"
    class="btn-close"
    data-qa="robot-modal-close-btn"
    (click)="activeModal.dismiss()"
  ></button>
</div>

<div class="modal-body" data-qa="modal-body">
  <div class="form-switch d-flex justify-content-between ps-0">
    <label data-qa="toggle-text">
      {{
        (isToggleChecked
          ? 'ROBOTS_MODAL.TOGGLE_OFF_TO_DISABLE'
          : 'ROBOTS_MODAL.TOGGLE_ON_TO_ENABLE'
        ) | translate
      }}
    </label>
    <input
      type="checkbox"
      class="form-check-input"
      data-qa="toggle"
      [(ngModel)]="isToggleChecked"
    />
  </div>

  <ng-container *ngIf="details && viewMode === 'DETAILS'">
    <dl class="row mb-2 mt-3">
      <dt class="col-sm-3 fw-normal">
        {{ 'ROBOTS_MODAL.DATE_STARTED' | translate }}
      </dt>
      <dd class="col-sm-9 fw-normal">
        {{ details.startDateTime | toDate }}
      </dd>
      <dt class="col-sm-3 fw-normal">
        {{ 'ROBOTS_MODAL.DURATION' | translate }}
      </dt>
      <dd class="col-sm-9 fw-normal">
        {{ details.duration }}
        {{
          'ROBOTS_MODAL.UNTIL_EXPIRATION'
            | translate: { value: details.expirationDate }
        }}
      </dd>

      <dt class="col-sm-3 fw-normal">
        {{ 'ROBOTS_MODAL.FREQUENCY' | translate }}
      </dt>
      <dd class="col-sm-9 fw-normal">
        {{ details.frequency }} {{ 'MINUTES' | translate }}
      </dd>

      <dt class="col-sm-3 fw-normal mt-2">
        {{ 'ROBOTS_MODAL.EXECUTED_SEARCHES' | translate }}
      </dt>
      <dd class="col-sm-9 fw-normal mt-2">{{ details.executedSearches }}</dd>

      <dt class="col-sm-3 fw-normal">
        {{ 'ROBOTS_MODAL.SCHEDULED_SEARCHES' | translate }}
      </dt>
      <dd class="col-sm-9 fw-normal">{{ details.scheduledSearches }}</dd>

      <dt class="col-sm-3 fw-normal">
        {{ 'ROBOTS_MODAL.LOCATIONS_FOUND' | translate }}
      </dt>
      <dd class="col-sm-9 fw-normal">{{ details.timesLocated }}</dd>
    </dl>

    <div class="mt-3 d-flex" *ngIf="details.isLogon">
      <i class="uil uil-exclamation-circle me-2"></i>
      <p>{{ 'ROBOTS_MODAL.WILL_STOP_WHEN_FOUND' | translate }}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="isToggleChecked && viewMode === 'CREATION'">
    <h5 class="header-title mb-1 mt-2" data-qa="starting-date-time-text">
      {{ 'ROBOTS_MODAL.STARTING_DATE_TIME' | translate }}
    </h5>
    <p class="sub-header" data-qa="starting-date-time-value">
      {{ 'ROBOTS_MODAL.IMMEDIATELY' | translate }} [{{ now | toDate }}]
    </p>

    <h4 class="header-title mb-1 mt-2" data-qa="duration-text">
      {{ 'ROBOTS_MODAL.DURATION' | translate }}
    </h4>
    <div class="d-flex flex-wrap">
      <button
        *ngFor="let duration of durations; let i = index"
        type=" button"
        class="btn btn-outline-primary me-2 mb-2"
        data-qa="duration-button-values"
        [ngClass]="{ active: durationIndex === i }"
        (click)="onSelectDuration(i)"
      >
        {{ duration.label }}
      </button>
    </div>

    <h4 class="header-title mb-1 mt-2" data-qa="frequency-text">
      {{ 'ROBOTS_MODAL.FREQUENCY' | translate }}
    </h4>
    <div class="d-flex flex-wrap">
      <button
        *ngFor="let frequency of frequencies; let i = index"
        type=" button"
        class="btn btn-outline-primary me-2 mb-2"
        data-qa="frequency-button-values"
        [ngClass]="{ active: frequencyIndex === i }"
        (click)="onSelectFrequency(i)"
      >
        {{ frequency.label }}
      </button>
    </div>

    <!-- STOP ROBOT WHEN LOCATION IS FOUND -->
    <div class="form-check mt-2 lh-1">
      <input
        id="chboxStop"
        type="checkbox"
        class="form-check-input mt-0"
        data-qa="logon-checkbox"
        [(ngModel)]="isLogonType"
      />
      <label
        for="chboxStop"
        class="form-check-label cursor-pointer"
        data-qa="logon-checkbox-text"
      >
        {{ 'ROBOTS_MODAL.STOP_WHEN_FOUND' | translate }}
      </label>
    </div>
  </ng-container>
</div>

<div
  class="modal-footer"
  *ngIf="viewMode === 'DETAILS' || (viewMode === 'CREATION' && isToggleChecked)"
>
  <button
    data-qa="cancel-btn"
    *ngIf="!(isMobile$ | ngrxPush)"
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    {{ 'CANCEL' | translate }}
  </button>
  <button
    data-qa="apply-btn"
    type="button"
    class="btn btn-primary"
    [class.w-100]="isMobile$ | ngrxPush"
    [disabled]="viewMode === 'DETAILS' && isToggleChecked"
    (click)="onSubmit()"
  >
    {{ 'APPLY' | translate }}
  </button>
</div>
