import {
  GeolocationQuery,
  queryInMissions,
} from '@fe-platform/geolocation/data';
import { StreamManager } from '@fe-platform/stream-manager';
import { Store } from '@ngrx/store';
import { EventChannel } from '@trg-commons/gio-data-models-ts';
import { Subscription, tap, withLatestFrom } from 'rxjs';
import { MissionActions } from '../missions/actions';
import { selectAllMissions } from '../missions/selectors';
import { PendingQueriesActions } from '../pending-queries';
import { UpdateGeolocationEventName } from './constants';

export function subscribeToIncomingGeolocationQueries(
  store: Store,
  streamManager: StreamManager
): Subscription {
  return streamManager
    .onEvent<GeolocationQuery>(
      EventChannel.GeolocationQueries,
      UpdateGeolocationEventName
    )
    .pipe(
      withLatestFrom(store.select(selectAllMissions)),
      tap(([query]) =>
        store.dispatch(PendingQueriesActions.updatePendingQuery({ query }))
      ),
      tap(([query, missions]) => {
        if (queryInMissions(query, missions)) {
          store.dispatch(
            MissionActions.addQueriesToTargets({ queries: [query] })
          );
        }
      })
    )
    .subscribe();
}
