import { countries } from '@fe-platform/core/constants';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatCountry: ColumnDataFormatter<GeolocationQuery, string> = (
  query: GeolocationQuery
) => {
  try {
    const phone = query.provider.telno;
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.parse(phone);
    const countryCode = phoneNumberUtil.getRegionCodeForNumber(
      phoneNumber
    ) as string;
    return countryCode ? countries[countryCode] : '-';
  } catch (e) {
    return '-';
  }
};
