import { Pipe, PipeTransform } from '@angular/core';
import { toMediumDateString } from '@fe-platform/core/date';
import { Robot } from '@fe-platform/geolocation/data';
import { EMPTY } from './empty';

@Pipe({ name: 'formatRobotEndDate' })
export class RobotEndDate implements PipeTransform {
  transform(robot: Robot | undefined): string {
    const date = robot?.canceled_at || robot?.ends_at;
    return date ? toMediumDateString(date) : EMPTY;
  }
}
