<div
  class="card"
  data-qa="mission-target-content"
  (click)="setActiveTarget()"
  [ngClass]="{
    'selected-target': selected$ | ngrxPush,
    hidden: !target.visible
  }"
>
  <div class="d-flex">
    <keystone-mission-target-color-sidebar
      [target]="target"
      [missionId]="missionId"
    ></keystone-mission-target-color-sidebar>

    <div
      *ngrxLet="hasPendingQuery$ as hasPendingQuery"
      class="target-card-body"
      [class.cursor-pointer]="target.visible"
    >
      <ng-container *ngrxLet="hasActiveRobot$ as hasActiveRobot">
        <button
          type="button"
          data-qa="delete-target-btn"
          class="target-close-btn btn-close"
          (click)="deleteTargetFromMission($event)"
          [disabled]="!target.visible"
        ></button>
        <p class="d-flex align-items-center title mb-1" title="MSISDN">
          <i class="bi bi-telephone me-1"></i>
          <span>{{
            target.msisdn || 'TARGET_CARD.NO_MSISDN_FOUND' | translate
          }}</span>
          <i
            data-qa="target-card-robot-btn"
            class="uil uil-robot lh-1 ms-1"
            [ngClass]="
              hasActiveRobot ? 'text-success' : 'text-muted opacity-30'
            "
            title="{{
              hasActiveRobot
                ? ('TARGET_CARD.ROBOTS_ACTIVE' | translate)
                : ('TARGET_CARD.ROBOTS_INACTIVE' | translate)
            }}"
          ></i>
        </p>
        <p
          class="subtitle ellipsis mb-1"
          title="{{ 'TARGET_CARD.LOCATION_STATUS' | translate }}"
        >
          <ng-container
            *ngrxLet="targetWithAllQueries$ as targetWithAllQueries"
          >
            <keystone-latest-located-label
              [target]="targetWithAllQueries"
              [hasPendingQuery]="hasPendingQuery || lockLocate"
              [isMobile]="true"
            ></keystone-latest-located-label>
          </ng-container>
          <span class="mx-1">
            <i class="bi bi-sim me-1"></i>
            <span>{{
              target.imsi || 'TARGET_CARD.NO_IMSI_FOUND' | translate
            }}</span>
          </span>
        </p>

        <div class="d-flex align-items-end">
          <button
            data-qa="locate-btn"
            class="btn btn-action first btn-primary justify-content-center rounded-pill d-inline-flex align-items-center text-black mt-1 me-1"
            type="button"
            (click)="locate()"
            [disabled]="hasPendingQuery || !target.visible || lockLocate"
            title="{{
              (hasPendingQuery || lockLocate
                ? 'TARGET_CARD.LOCATING'
                : 'TARGET_CARD.LOCATE'
              ) | translate
            }}"
          >
            <i class="uil uil-crosshair lh-1 icon-margin"></i>
            {{
              (hasPendingQuery || lockLocate
                ? 'TARGET_CARD.LOCATING'
                : 'TARGET_CARD.LOCATE'
              ) | translate
            }}
          </button>
          <button
            type="button"
            class="btn btn-action btn-secondary justify-content-center rounded-pill d-inline-flex align-items-center mt-1 ms-1 me-1"
            data-qa="target-card-robot-btn"
            [ngClass]="{ 'active-robot': hasActiveRobot }"
            (click)="openRobotsModal($event)"
          >
            <div
              class="d-inline-flex align-items-center"
              [ngbTooltip]="
                hasActiveRobot
                  ? ('TOOLTIP_CONTENT.VIEW_ROBOT_SEARCH' | translate)
                  : ('TOOLTIP_CONTENT.START_ROBOT_SEARCH' | translate)
              "
            >
              <i class="uil uil-robot lh-1 me-1 icon-margin"></i>
              <span data-qa="target-card-robot-text">
                {{ 'TARGET_CARD.ROBOTS' | translate }}</span
              >
            </div>
          </button>
          <button
            class="btn btn-action btn-secondary justify-content-center rounded-pill d-inline-flex align-items-center mt-1 ms-1 me-1"
            type="button"
            data-qa="target-card-history-btn"
            (click)="onNavigateToTargetHistory(target)"
            title="{{ 'TARGET_CARD.HISTORY' | translate }}"
            [disabled]="!target.visible"
          >
            <i class="uil uil-history lh-1 icon-margin"></i>
            {{ target.queries.length }}
          </button>

          <ng-template #targetActions let-modal>
            <div class="modal-header">
              <h4 class="modal-title">
                {{ 'TARGET_CARD_ACTIONS.ACTIONS' | translate }}
              </h4>
              <button
                type="button"
                class="btn-close"
                (click)="modal.dismiss()"
                title="{{ 'TARGET_CARD_ACTIONS.CLOSE' | translate }}"
              ></button>
            </div>
            <div class="modal-body p-0">
              <ul class="list-group list-group-flush">
                <button
                  type="button"
                  class="list-group-item list-group-item-action text-white"
                  (click)="modal.dismiss()"
                >
                  {{ 'TARGET_CARD_ACTIONS.ROBOT_SEARCH' | translate }}
                </button>
              </ul>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
