import { ValueOf } from '@fe-platform/core/types';
import {
  CellTowerInfo,
  GeolocationQuery,
  MapStyle,
  MissionId,
  QueryId,
  Target,
  TargetId,
  TimebarRange,
} from '@fe-platform/geolocation/data';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MissionActions = createActionGroup({
  source: 'Missions',
  events: {
    'create Empty Mission': emptyProps(),
    'delete Mission': props<{ missionId: MissionId }>(),
    'clear Mission': props<{ missionId: MissionId }>(),
    'set Active Mission': props<{ missionId: MissionId }>(),
    'change Map Style': props<{ mapStyle: ValueOf<typeof MapStyle> }>(),
    'set Active Target': props<{ targetId: TargetId }>(),
    'clear Active Target': emptyProps(),
    'add Target To Active Mission': props<{ target: Target }>(),
    'delete Target From Active mission': props<{ target: Target }>(),
    'toggle Target Visibility': props<{
      targetId: TargetId;
      visible: boolean;
    }>(),
    'add Queries To Targets': props<{ queries: GeolocationQuery[] }>(),
    'set Active Query': props<{ queryId: QueryId }>(),
    'clear Active Query': emptyProps(),
    'toggle Target History': props<{ targetId: TargetId }>(),
    'set Timebar Range': props<{ range: TimebarRange | null }>(),
    'retrieve Call Info': props<{ queryId: string }>(),
    'update Query In Target': props<{ query: GeolocationQuery }>(),
    'check For Cell Towers': props<{ query: GeolocationQuery }>(),
    'retrieve Cell Towers': props<{ query: GeolocationQuery }>(),
    'update Cell Towers': props<{ towers: CellTowerInfo[] | null }>(),
    reset: emptyProps(),
  },
});
export type MissionAction = ReturnType<ValueOf<typeof MissionActions>>;
