import { Component, OnInit } from '@angular/core';
import { Notification } from '@fe-platform/notifications/data';
import { NotificationsFacade } from '@fe-platform/notifications/state';
import { combineLatest, map } from 'rxjs';

@Component({
  selector: 'keystone-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['./notification-widget.component.scss'],
})
export class NotificationWidgetComponent implements OnInit {
  constructor(private notificationsFacade: NotificationsFacade) {}
  vm$ = combineLatest([
    this.notificationsFacade.notifications$,
    this.notificationsFacade.unreadNotificationsCount$,
  ]).pipe(
    map(([notifications, unreadNotificationsCount]) => ({
      notifications,
      unreadNotificationsCount,
    }))
  );
  ngOnInit(): void {
    this.notificationsFacade.fetchNotifications();
  }

  markAsRead(notification: Notification): void {
    if (!notification.is_read) {
      this.notificationsFacade.markAsRead(notification.id);
    }
  }

  markAllAsRead(): void {
    this.notificationsFacade.markAllAsRead();
  }

  deleteAllNotifications(): void {
    this.notificationsFacade.deleteAllNotifications();
  }
}
