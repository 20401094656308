<div
  class="notification-item cursor-pointer"
  [ngClass]="{ 'is-active': !notification.is_read }"
  ngbDropdownItem
>
  <p class="notification-text" data-qa="notification-text">
    {{ notification | transformToNotificationText | ngrxPush }}
  </p>
  <p class="text-muted mb-0 user-msg">
    <small data-qa="notification-date">{{
      notification.created_at | toDate: 'long'
    }}</small>
  </p>
</div>
