import { Injectable } from '@angular/core';
import { User } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import {
  BalanceType,
  BillingActionsType,
  BillingDataService,
  BillingLimitType,
  BillingPlan,
  CreditPools,
  LedgerEvent,
  LedgerPlan,
  TenantBillingDetails,
} from '@fe-platform/billing/data';
import { StreamManager } from '@fe-platform/stream-manager';
import { Store } from '@ngrx/store';
import {
  Observable,
  Subscription,
  combineLatest,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { BillingStatusUpdate, UpdateLedgerDetailsEvent } from './helpers';
import {
  BillingActions,
  BillingState,
  selectBillingIsReady,
  selectBillingPlan,
  selectCredits,
  selectTenantBillingDetails,
} from './reducer';
import { EventChannel } from '@trg-commons/gio-data-models-ts';
@Injectable({ providedIn: 'root' })
export class BillingFacade {
  credits$ = this.store.select(selectCredits);
  billingPlan$ = this.store.select(selectBillingPlan);
  tenantBillingDetails$ = this.store.select(selectTenantBillingDetails);
  isReady$ = this.store.select(selectBillingIsReady);
  balanceType$ = this.store
    .select(selectTenantBillingDetails)
    .pipe(map((t) => t?.balance_type));
  maximumAvailableCredits$ = (user: User): Observable<number> =>
    combineLatest([
      this.getUserBalance$(user),
      this.getTenantUnassignedBalance$(),
    ]).pipe(
      map(([userCredits, tenantCredits]) => {
        return user.limit_type === BillingLimitType.MAXIMUM
          ? tenantCredits
          : userCredits + tenantCredits;
      })
    );
  constructor(
    private streamManager: StreamManager,
    private store: Store<BillingState>,
    private billingDataService: BillingDataService,
    private authFacade: AuthFacade
  ) {
    this.subscribeToLedgerChanges();
  }
  private subscribeToLedgerChanges(): Subscription {
    return this.streamManager
      .onEvent<LedgerEvent>(
        EventChannel.BillingStatusUpdate,
        BillingStatusUpdate,
        UpdateLedgerDetailsEvent
      )
      .pipe(
        // we are mapping the ledger event to an http call to get user ledger details
        // because we don't get suffecient information from the websocket message to update the ledger appropriately
        withLatestFrom(
          this.authFacade.currentUser$.pipe(map((u) => u?.username))
        ),
        map(([, username]) => username),
        filter(Boolean),
        switchMap((username) =>
          this.billingDataService.fetchLedgerData(username)
        )
      )
      .subscribe(({ result }) => {
        const ledgerPlan = result;
        this.setCredits(ledgerPlan.current_balance);
      });
  }
  public setBillingReady(ready: boolean): void {
    this.store.dispatch(BillingActions.ready({ ready }));
  }
  public setCredits(credits: number | CreditPools): void {
    if (typeof credits === 'number') {
      credits = Math.trunc(credits);
    } else {
      credits.geolocation = Math.trunc(Number(credits.geolocation));
    }
    this.store.dispatch(BillingActions.setCredits({ credits }));
  }

  public setBillingPlan(billingPlan: BillingPlan): void {
    this.store.dispatch(BillingActions.setBillingPlan({ billingPlan }));
  }

  public setTenantBillingDetails(
    tenantBillingDetails: TenantBillingDetails
  ): void {
    this.store.dispatch(
      BillingActions.setTenantBillingDetails({ tenantBillingDetails })
    );
  }

  public fetchLedgerData(username: string): Observable<LedgerPlan> {
    return this.billingDataService
      .fetchLedgerData(username)
      .pipe(map((ledger) => ledger.result));
  }

  public fetchBillingPlan(): Observable<BillingPlan> {
    return this.billingDataService
      .fetchBillingPlan()
      .pipe(map((billingPlan) => billingPlan));
  }
  public hasSuffecientCredits$(
    action: BillingActionsType
  ): Observable<boolean | never> {
    return combineLatest([this.billingPlan$, this.credits$]).pipe(
      tap(([billingPlan, credits]) => {
        if (billingPlan[action].cost > credits) {
          throw new Error('EVENT_NOTIFICATIONS.INSUFFICIENT_CREDITS');
        }
      }),
      map(() => true),
      take(1)
    );
  }

  public fetchTenantBillingDetails(): Observable<TenantBillingDetails> {
    return this.billingDataService
      .fetchTenantDetails()
      .pipe(
        map(
          (billingDetailsResult: { result: TenantBillingDetails }) =>
            billingDetailsResult.result
        )
      );
  }
  public getUserBalance$(user: User): Observable<number> {
    return this.balanceType$.pipe(
      take(1),
      map((balanceType) => {
        if (balanceType === BalanceType.DISTRIBUTED) {
          return Number((user.current_balance as CreditPools).geolocation);
        }
        if (balanceType === BalanceType.SINGLE) {
          return user.current_balance as number;
        }
        throw new Error('Balance type not supported');
      })
    );
  }
  public getUserBalance(user: User): number {
    if ('current_balance' in user && typeof user.current_balance === 'number') {
      return Number(user.current_balance.toFixed(1));
    } else {
      const balance = Number((user.current_balance as CreditPools).geolocation);
      return Number(balance.toFixed(1));
    }
  }
  public getTenantUnassignedBalance$(): Observable<number> {
    return this.balanceType$.pipe(
      take(1),
      switchMap((balanceType) => {
        if (balanceType === BalanceType.DISTRIBUTED) {
          return this.tenantBillingDetails$.pipe(
            map((t) =>
              Number((t.unassign_user_credits as CreditPools).geolocation)
            )
          );
        }
        if (balanceType === BalanceType.SINGLE) {
          return this.tenantBillingDetails$.pipe(
            map((t) => t.unassign_user_credits as number)
          );
        }
        throw new Error('Balance type not supported');
      })
    );
  }
  public reset(): void {
    this.store.dispatch(BillingActions.reset());
  }
}
