import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TempModule } from '@fe-platform/shared-ui/keystone';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LetModule, PushModule } from '@ngrx/component';
import { SearchBoxMobileComponent } from './search-box-mobile/search-box-mobile.component';
import { AddNewTargetModalComponent } from './search-box-web/add-new-target-modal/add-new-target-modal.component';
import { SearchBoxWebComponent } from './search-box-web/geolocation-query-search-box-web.component';
import { GeoquerySearchBoxComponent } from './geoquery-search-box.component';
import { LatestQueriesListComponent } from './shared-components/latest-queries-list/latest-queries-list.component';
import { SearchNewQueryFormComponent } from './shared-components/search-new-query-form/search-new-query-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    TempModule,
    LetModule,
    PushModule,
  ],
  declarations: [
    GeoquerySearchBoxComponent,
    AddNewTargetModalComponent,
    SearchBoxMobileComponent,
    SearchNewQueryFormComponent,
    SearchBoxWebComponent,
    LatestQueriesListComponent,
  ],
  exports: [GeoquerySearchBoxComponent],
})
export class GeolocationFeatureQueryBoxModule {}
