import { Component, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewTargetModalComponent } from './add-new-target-modal/add-new-target-modal.component';

@Component({
  selector: 'keystone-search-box-web',
  templateUrl: './geolocation-query-search-box-web.component.html',
  styleUrls: ['./geolocation-query-search-box-web.component.css'],
})
export class SearchBoxWebComponent {
  constructor(private modalService: NgbModal, private injector: Injector) {}
  public onOpenModal(): void {
    this.modalService.open(AddNewTargetModalComponent, {
      centered: true,
      injector: this.injector,
    });
  }
}
