import { Component } from '@angular/core';
import { OtpConfiguration } from '@fe-platform/auth/data';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ResetPasswordAfterLoginComponentStore } from './reset-password-after-login.store';

@UntilDestroy()
@Component({
  selector: 'keystone-reset-password-after-login',
  templateUrl: './reset-password-after-login.component.html',
  styleUrls: ['./reset-password-after-login.component.css'],
  providers: [ResetPasswordAfterLoginComponentStore],
})
export class ResetPasswordAfterLoginComponent {
  submitEnabled$ = this.componentStore.submitEnabled$;
  otpToggleVisible$ = this.componentStore.otpToggleVisible$;
  otpConfigFormVisible$ = this.componentStore.otpFormVisible$;
  otpCodeFormVisible$ = this.componentStore.otpCodeFormVisible$;
  errorMessage$ = this.componentStore.errorMessage$;
  constructor(private componentStore: ResetPasswordAfterLoginComponentStore) {}
  handlePasswordFormChanged(password: string): void {
    this.componentStore.updatePassword(password);
  }
  handlePasswordFormValidityChanged(passwordIsValid: boolean): void {
    this.componentStore.updatePasswordValidity(passwordIsValid);
  }
  handleOtpSelection(otpConfiguration: OtpConfiguration): void {
    this.componentStore.updateOtpConfiguration(otpConfiguration);
  }

  handleOtpCode(otpCode: string): void {
    this.componentStore.updateOtpCode(otpCode);
  }

  onOtpConfigToggled(otpToggleEnabled: boolean): void {
    this.componentStore.updateOtpEnabled(otpToggleEnabled);
  }
  handleOtpFormValidity(otpFormValid: boolean): void {
    this.componentStore.updateOtpFormValidity(otpFormValid);
  }
  submit(): void {
    this.componentStore.submit();
  }
}
