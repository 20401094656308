import { Component } from '@angular/core';
import { LOCAL_STORAGE_LANGUAGE_KEY } from '@fe-platform/core/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'keystone-language-selection',
  templateUrl: './language-selection.component.html',
})
export class LanguageSelectionComponent {
  selectedLang = 'en';

  langs = [
    { key: 'en', value: 'English' },
    { key: 'es', value: 'Español' },
  ];

  constructor(private translate: TranslateService) {
    this.selectedLang = this.translate.currentLang;
  }

  onChange(event: Event): void {
    const lang = (event.target as HTMLInputElement).value;
    this.selectedLang = lang;
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, lang);
    this.translate.use(lang);
  }
}
