<mgl-marker
  #cellTowerMarker
  [lngLat]="[cellTower.location.lon, cellTower.location.lat]"
>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_3621_18054)">
      <path
        d="M9.29999 15.7999L10.5 14.5999C9.49999 13.5999 8.99999 12.2999 8.99999 11.0999C8.99999 9.7999 9.49999 8.4999 10.5 7.5999L9.29999 6.3999C7.99999 7.6999 7.29999 9.3999 7.29999 11.0999C7.29999 12.7999 7.99999 14.4999 9.29999 15.7999Z"
        fill="#F3F3F3"
      />
      <path
        d="M21.1 4L19.9 5.2C21.5 6.8 22.3 9 22.3 11.1C22.3 13.2 21.5 15.4 19.9 17L21.1 18.2C23.1 16.2 24 13.7 24 11.1C24 8.5 23 6 21.1 4Z"
        fill="#F3F3F3"
      />
      <path
        d="M8.1 5.2L6.9 4C5 6 4 8.5 4 11.1C4 13.7 5 16.2 6.9 18.2L8.1 17C6.5 15.4 5.7 13.2 5.7 11.1C5.7 9 6.5 6.8 8.1 5.2Z"
        fill="#F3F3F3"
      />
      <path
        d="M18.7 15.7999C20 14.4999 20.7 12.7999 20.7 11.0999C20.6 9.3999 20 7.6999 18.7 6.3999L17.5 7.5999C18.5 8.5999 19 9.8999 19 11.0999C19 12.3999 18.5 13.6999 17.5 14.5999L18.7 15.7999Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.5 11.1001C16.5 9.7201 15.38 8.6001 14 8.6001C12.62 8.6001 11.5 9.7201 11.5 11.1001C11.5 11.8601 11.84 12.5201 12.37 12.9801L9 23.1001H11L11.67 21.1001H16.34L17 23.1001H19L15.63 12.9801C16.16 12.5201 16.5 11.8601 16.5 11.1001ZM12.33 19.1001L14 14.1001L15.67 19.1001H12.33Z"
        fill="#F3F3F3"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3621_18054"
        x="0"
        y="0"
        width="28"
        height="27.1001"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3621_18054"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3621_18054"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</mgl-marker>
<mgl-popup [marker]="cellTowerMarker">
  <keystone-cell-tower-popup
    [cellTower]="cellTower"
  ></keystone-cell-tower-popup>
</mgl-popup>
