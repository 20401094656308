/**
 * Date format docs: https://date-fns.org/v2.29.3/docs/format
 */
import { LOCAL_STORAGE_LANGUAGE_KEY } from '@fe-platform/core/constants';
import { format, isDate } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
const locales: Record<string, Locale> = { enUS, es };
export function stringToDate(dateString: string): Date {
  const date = new Date(dateString);
  if (!isDate(date)) throw new Error('Failed to transform string to date');
  return date;
}
export function toShortDateString(value: string | Date): string {
  const date = value instanceof Date ? value : stringToDate(value);
  return format(date, 'd MMM yyyy', { locale: getLocale() });
}
export function toMediumDateString(value: string | Date): string {
  const date = value instanceof Date ? value : stringToDate(value);
  return format(date, 'd MMM yyyy, h:mm a', { locale: getLocale() });
}
export function toLongDateString(value: string | Date): string {
  const date = value instanceof Date ? value : stringToDate(value);
  return format(date, 'd MMM yyyy, h:mm:ss a', { locale: getLocale() });
}
export function getLocale(): Locale {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) ?? '';
  return locales[lang] ?? enUS;
}
