import {
  AuthPayload,
  AuthStatus,
  OtpConfiguration,
  OtpConfigurationWithHashedRecipient,
  User,
} from '@fe-platform/auth/data';
import { ValueOf } from '@fe-platform/core/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Validate Otp Configuration': props<{ otpCode: string }>(),
    'Submit Otp Configuration': props<OtpConfiguration>(),
    'set Token': props<{ token: string }>(),
    Login: props<AuthPayload>(),
    'fetch Current User': emptyProps(),
    'set Current User': props<{ user: User }>(),
    'reset Current User': emptyProps(),
    'reset Password': props<{
      oldPassword: string;
      newPassword: string;
      otpCode?: string;
    }>(),
    'refresh Session': emptyProps(),
    'set Status': props<{ status: AuthStatus }>(),
    'set Error Message': props<{ errorMessage: string }>(),
    'set Otp Configuration': props<OtpConfigurationWithHashedRecipient>(),
    'logout Success': emptyProps(),
  },
});
export type AuthAction = ReturnType<ValueOf<typeof AuthActions>>;
