<nav
  *ngrxLet="activeMission$ as activeMission"
  id="nav-missions"
  class="bg-blur d-flex align-items-center justify-content-between w-100 px-1"
>
  <ng-container *ngrxLet="missions$ as missions">
    <button
      type="button"
      class="btn btn-mission"
      [disabled]="missions.indexOf(activeMission) === 0"
      (click)="
        onSelectMission(missions[missions.indexOf(activeMission) - 1].id)
      "
      title="Previous Mission"
    >
      <i class="bi bi-chevron-left"></i>
    </button>
    <button
      type="button"
      class="btn d-flex align-items-center text-white py-0"
      (click)="onToggleMissionsList()"
      title="Select Mission"
    >
      <div
        class="d-inline-flex align-items-center lh-1 h4 fw-bold text-white ellipsis"
      >
        <i class="bi-list-task me-1"></i>
        <span>{{
          'MISSIONS.MISSION'
            | translate: { value: missions.indexOf(activeMission) + 1 }
        }}</span>
      </div>
    </button>
    <button
      type="button"
      class="btn btn-mission"
      [disabled]="missions.indexOf(activeMission) === missions.length - 1"
      (click)="
        onSelectMission(missions[missions.indexOf(activeMission) + 1].id)
      "
      title="Next Mission"
    >
      <i class="bi bi-chevron-right"></i>
    </button>
  </ng-container>
</nav>
