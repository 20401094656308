<label class="form-label" for="langSelector">{{
  'LANGUAGE_SELECTION.CHOOSE' | translate
}}</label>
<select
  class="form-select form-select-sm"
  (change)="onChange($event)"
  id="langSelector"
>
  <option
    *ngFor="let lang of langs"
    value="{{ lang.key }}"
    [selected]="lang.key === selectedLang"
  >
    {{ lang.value }}
  </option>
</select>
