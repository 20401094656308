import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthStatus } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OtpGuard implements CanActivate {
  constructor(private authService: AuthFacade, private router: Router) {}
  canActivate(): Observable<boolean> {
    return this.authService.status$.pipe(
      map((authStatus) => {
        if (
          authStatus === AuthStatus.WAITING_FOR_OTP_INPUT ||
          authStatus === AuthStatus.VALIDATING_OTP_CONFIGURATION
        ) {
          return true;
        }
        this.router.navigate(['/login']);
        return false;
      })
    );
  }
}
