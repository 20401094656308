import { Robot } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatRobotType: ColumnDataFormatter<Robot, string> = (robot) => {
  const types = {
    scheduled: 'ROBOTS.CONTINUOUS',
    logon: 'ROBOTS.END_WHEN_LOCATION_FOUND',
  } as Record<string, string>;
  if (robot.schedule_type) {
    return types[robot.schedule_type];
  }
  return '-';
};
