import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'keystone-otp-toggle',
  templateUrl: './otp-toggle.component.html',
  styleUrls: ['./otp-toggle.component.css'],
})
export class OtpToggleComponent {
  @Input() initialValue = false;
  @Input() disabled = false;
  @Output() otpConfigToggled$: EventEmitter<boolean> = new EventEmitter();

  otpConfigChecked(event: Event): void {
    const isChecked = (<HTMLInputElement>event.target).checked;
    this.otpConfigToggled$.emit(isChecked);
  }
}
