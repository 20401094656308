import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({ name: 'formatPasswordError' })
export class PasswordErrorPipe implements PipeTransform {
  transform(error: ValidationErrors): string {
    const translationPrefix = 'PASSWORD.';
    if ('passwordAtLeast8Chars' in error)
      return translationPrefix + 'Password must be at least 8 characters';
    if (error['passwortAtLeastUppercase'])
      return translationPrefix + 'Password does not contain upper case letters';
    if (error['passwordAtLeastDigits'])
      return translationPrefix + 'Password does not contain any digits';
    if (error['passwordAtLeastLowercase'])
      return translationPrefix + 'Password does not contain lower case letters';
    if (error['required']) return translationPrefix + 'Password is required';
    return '';
  }
}
