import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Mission } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';
import { pageTitles } from './page-titles';

@Component({
  selector: 'keystone-page-title',
  templateUrl: './page-title.component.html',
  styles: [
    `
      .vertical-divider {
        width: 2px;
        height: 24px;
      }
    `,
  ],
})
export class PageTitleComponent implements OnInit {
  pageTitle = '';
  missionIndex: number | null = null;
  missions$ = this.geolocationFacade.missions.missions$;

  constructor(
    private geolocationFacade: GeolocationFacade,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.url;
    this.getPageTitle(url);
    this.getMissionIndex(url);

    this.router.events
      .pipe(
        map((router) => {
          return router instanceof NavigationEnd && router.url;
        }),
        distinctUntilChanged(),
        filter(Boolean),
        tap((route) => {
          this.getPageTitle(route);
          this.getMissionIndex(route);
        }),
        switchMap((route) => {
          return this.missions$.pipe(
            tap((missions) => {
              this.getMissionIndex(route, missions);
            })
          );
        })
      )
      .subscribe();
  }

  getPageTitle(url: string): void {
    pageTitles.map((title) => {
      if (url.includes(title.name)) {
        this.pageTitle = title.translation;
      }
    });
  }

  getMissionIndex(url: string, missions?: Mission[]): void {
    if (url.includes('/missions')) {
      const missionId = url.split('/')[2];
      this.missionIndex = 1;

      if (missions?.length) {
        this.missionIndex =
          missions.findIndex((mission) => missionId === mission.id) + 1;
      }
    } else {
      this.missionIndex = null;
    }
  }
}
