<ng-container *ngrxLet="sheets$ as sheets">
  <ng-container *ngFor="let sheet of sheets; let i = index">
    <!-- Backdrop -->
    <div
      *ngIf="sheet.config.backdrop"
      @fadeInOut
      class="bottom-sheet-backdrop"
      [ngStyle]="{
        bottom: sheet.config.bottom ? sheet.config.bottom : 0,
        'z-index': sheet.config.z_index ? sheet.config.z_index : 2
      }"
      (click)="close(sheet)"
    ></div>

    <!-- Bottom Sheet -->
    <div
      @slideInOutBottom
      class="bottom-sheet"
      [class.resizable]="sheet.config.resizable"
      [ngStyle]="{
        bottom: sheet.config.bottom ? sheet.config.bottom : 0,
        'z-index': sheet.config.z_index ? sheet.config.z_index : 2,
        'max-height': sheet.config.max_height ? sheet.config.max_height : '100%'
      }"
    >
      <!-- Handle -->
      <div
        *ngIf="sheet.config.resizable"
        class="bottom-sheet-handle"
        (mousedown)="onDragStart($event, sheet)"
        (touchstart)="onDragStart($event, sheet)"
      >
        <span class="bottom-sheet-handle-icon"></span>
      </div>

      <!-- Content -->
      <div *ngIf="sheet.component" class="bottom-sheet-content">
        <ng-container *ngComponentOutlet="sheet.component"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
