export interface TimebarEvent {
  name: TimebarEventActions;
  args: TimebarArgs;
  preventDefault: boolean;
}

export enum TimebarEventActions {
  ALL = 'all',
  CHANGE = 'change',
  CLICK = 'click',
  DOUBLE_CLICK = 'double-click',
  DRAG_END = 'drag-end',
  DRAG_START = 'drag-start',
  END = 'end',
  HOVER = 'hover',
  KEY_DOWN = 'key-down',
  KEY_UP = 'key-up',
  POINTER_DOWN = 'pointer-down',
  POINTER_UP = 'pointer-up',
  START = 'start',
  WHEEL = 'wheel',
}

export const PropertyChangedTimebarEvents = [
  TimebarEventActions.WHEEL,
  TimebarEventActions.DRAG_END,
  TimebarEventActions.DOUBLE_CLICK,
  TimebarEventActions.CLICK,
];

export interface TimebarArgs {
  type: string;
  tooltipX: number;
  tooltipY: number;
  value: number;
  button: number;
  pointerId: number;
  pointerType: string;
  x: number;
  y: number;
  rangeStart: Date;
  rangeEnd: Date;
}
