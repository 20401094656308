import { UntypedFormGroup } from '@angular/forms';
import { User } from '@fe-platform/auth/data';

export const patchOtpConfigForm =
  (otpConfigForm: UntypedFormGroup) =>
  (user: User): void =>
    otpConfigForm.patchValue({
      email: user?.email,
      phone: user?.otp_phone,
    });
