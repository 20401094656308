import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthFacade } from '@fe-platform/auth/state';
import { mustMatchValidator } from './must-match-password-validator';
import { passwordPolicyValidator } from './password-policy-validator';

export function createChangePasswordForm(auth: AuthFacade): UntypedFormGroup {
  return new UntypedFormGroup(
    {
      passwordCurrent: new UntypedFormControl('', [Validators.required]),
      passwordNew: new UntypedFormControl(
        '',
        [Validators.required],
        [passwordPolicyValidator(auth)]
      ),
      passwordConfirmNew: new UntypedFormControl('', [Validators.required]),
    },
    [mustMatchValidator('passwordNew', 'passwordConfirmNew')]
  );
}
