import { AuthStatus, OTPChannels } from '@fe-platform/auth/data';
import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './actions';
import { generateInitialState } from './state';
export const authReducer = createReducer(
  generateInitialState(),
  on(AuthActions.setCurrentUser, (state, action) => ({
    ...state,
    currentUser: action.user,
  })),
  on(AuthActions.resetCurrentUser, (state) => ({
    ...state,
    currentUser: null,
    token: '',
    status: AuthStatus.UNAUTHENTICATED,
    otpChannel: OTPChannels.None,
    errorMessage: '',
  })),
  on(AuthActions.setStatus, (state, action) => ({
    ...state,
    status: action.status,
    errorMessage: '',
  })),
  on(AuthActions.setErrorMessage, (state, action) => ({
    ...state,
    errorMessage: action.errorMessage,
  })),
  on(AuthActions.setOtpConfiguration, (state, action) => ({
    ...state,
    otpChannel: action.channel,
    otpChannelRecipient: action.recipient,
    otpHashedRecipient: action.hashedRecipient,
  }))
);
