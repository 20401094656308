<form
  [formGroup]="changePasswordForm"
  (ngSubmit)="onSubmit()"
  class="py-1 px-2"
  autocomplete="off"
>
  <div class="modal-header">
    <h4 class="modal-title" data-qa="modal-title">
      {{ 'PASSWORD.CHANGE_PASSWORD' | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      data-qa="change-password-modal-close-btn"
      aria-label="Close"
      (click)="activeModal.dismiss('Close click')"
    ></button>
  </div>
  <div class="modal-body">
    <!-- Current Password -->
    <div class="mb-3">
      <label for="passwordCurrent" class="form-label">
        <span class="me-1 text-danger">*</span>
        <span data-qa="current-password-text">{{
          'PASSWORD.CURRENT_PASSWORD' | translate
        }}</span>
      </label>
      <div class="input-group">
        <span class="input-group-text">
          <i class="icon-dual" data-feather="lock" keystoneFeatherIcon></i>
        </span>
        <input
          ngbAutoFocus
          name="passwordCurrent"
          id="passwordCurrent"
          formControlName="passwordCurrent"
          type="{{ hidePassword['current'] ? 'password' : 'text' }}"
          class="form-control"
          data-qa="current-password-input"
          [class.is-invalid]="
            currentPasswordControl?.dirty && currentPasswordControl?.invalid
          "
          autocomplete="new-password"
          required
        />
        <button
          class="btn btn-outline-secondary"
          data-qa="reveal-current-password-btn"
          type="button"
          (click)="onTogglePasswordVisibility('current')"
        >
          <i *ngIf="hidePassword['current']" class="bi bi-eye"></i>
          <i *ngIf="!hidePassword['current']" class="bi bi-eye-slash"></i>
        </button>
      </div>
      <div *ngIf="passwordCurrentErrors$ | ngrxPush; let passwordErrors">
        <div class="invalid-feedback">
          {{ passwordErrors | formatPasswordError | translate }}
        </div>
      </div>
    </div>

    <!-- New Password -->
    <div class="mb-3">
      <label for="passwordNew" class="form-label">
        <span class="me-1 text-danger">*</span>
        <span data-qa="new-password-text">{{
          'PASSWORD.NEW_PASSWORD' | translate
        }}</span>
      </label>
      <div class="input-group">
        <span class="input-group-text">
          <i class="icon-dual" data-feather="lock" keystoneFeatherIcon></i>
        </span>
        <input
          name="passwordNew"
          id="passwordNew"
          formControlName="passwordNew"
          type="{{ hidePassword['new'] ? 'password' : 'text' }}"
          class="form-control"
          data-qa="new-password-input"
          [class.is-invalid]="
            newPasswordControl?.dirty && newPasswordControl?.invalid
          "
          required
        />
        <button
          class="btn btn-outline-secondary"
          data-qa="reveal-new-password-btn"
          type="button"
          (click)="onTogglePasswordVisibility('new')"
        >
          <i *ngIf="hidePassword['new']" class="bi bi-eye"></i>
          <i *ngIf="!hidePassword['new']" class="bi bi-eye-slash"></i>
        </button>
      </div>
      <div *ngIf="passwordNewErrors$ | ngrxPush; let passwordErrors">
        <div class="invalid-feedback">
          {{ passwordErrors | formatPasswordError | translate }}
        </div>
      </div>
    </div>

    <!-- Confirm New Password -->
    <div>
      <label for="passwordConfirmNew" class="form-label">
        <span class="me-1 text-danger">*</span>
        <span data-qa="confirm-password-text">{{
          'PASSWORD.CONFIRM_NEW_PASSWORD' | translate
        }}</span>
      </label>
      <div class="input-group">
        <span class="input-group-text">
          <i class="icon-dual" data-feather="lock" keystoneFeatherIcon></i>
        </span>
        <input
          name="passwordConfirmNew"
          id="passwordConfirmNew"
          formControlName="passwordConfirmNew"
          type="{{ hidePassword['confirm_new'] ? 'password' : 'text' }}"
          class="form-control"
          data-qa="confirm-password-input"
          [class.is-invalid]="
            confirmNewPasswordControl?.dirty &&
            confirmNewPasswordControl?.invalid
          "
          required
        />
        <button
          class="btn btn-outline-secondary"
          data-qa="reveal-confirm-password-btn"
          type="button"
          (click)="onTogglePasswordVisibility('confirm_new')"
        >
          <i *ngIf="hidePassword['confirm_new']" class="bi bi-eye"></i>
          <i *ngIf="!hidePassword['confirm_new']" class="bi bi-eye-slash"></i>
        </button>
      </div>
      <div *ngIf="passwordConfirmNewErrors$ | ngrxPush; let passwordErrors">
        <div class="invalid-feedback">
          {{ passwordErrors | formatPasswordError | translate }}
        </div>
      </div>
      <div
        *ngIf="confirmNewPasswordControl?.hasError('mustMatch')"
        class="invalid-feedback"
      >
        {{ 'PASSWORD.PASSWORDS_ARE_NOT_THE_SAME' | translate }}
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button
      type="button"
      class="btn btn-outline-dark"
      data-qa="cancel-btn"
      (click)="activeModal.close('Cancel click')"
      [disabled]="loading"
    >
      {{ 'PASSWORD.CANCEL' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-primary"
      data-qa="submit-btn"
      [disabled]="changePasswordForm.invalid || loading"
    >
      <span *ngIf="!loading">{{ 'PASSWORD.SUBMIT' | translate }}</span>
      <span *ngIf="loading">{{ 'PASSWORD.WORKING' | translate }}</span>
    </button>
  </div>
</form>
