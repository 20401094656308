import { Injectable } from '@angular/core';
import { isValidMsisdn } from '@fe-platform/core/phone';
import {
  CellTowerInfo,
  determineTargetColor,
  extractImsiFromGeoQueries,
  extractMsisdnFromGeoQueries,
  extractTargetFromMission,
  GeolocationQuery,
  GeolocationQueryDataService,
  GeolocationQueryRequestPayload,
  GeolocationQueryType,
  LatestGeolocationQueryResponse,
  MapStyle,
  MissionId,
  QueryId,
  Target,
  TargetId,
  TimebarRange,
} from '@fe-platform/geolocation/data';
import { Store } from '@ngrx/store';
import { map, Observable, take, tap, withLatestFrom } from 'rxjs';
import { numberIsInActiveMission } from '../helpers';
import { MissionActions } from './actions';
import {
  selectActiveMission,
  selectActiveMissionColorLocatedGeoqueries,
  selectActiveMissionWithoutFilteringQueries,
  selectActiveQuery,
  selectActiveTarget,
  selectAllMissions,
  selectAllTargets,
  selectMapMarkers,
  selectMapStyle,
  selectTargetIsExpanded,
  selectTowers,
} from './selectors';

@Injectable({ providedIn: 'root' })
export class MissionsFacade {
  missions$ = this.store.select(selectAllMissions);
  targets$ = this.store.select(selectAllTargets);
  towers$ = this.store.select(selectTowers);
  locatedGeoqueriesInActiveMission$ = this.store.select(
    selectActiveMissionColorLocatedGeoqueries
  );
  activeMission$ = this.store.select(selectActiveMission);
  activeMissionWithAllQueries$ = this.store.select(
    selectActiveMissionWithoutFilteringQueries
  );
  activeTarget$ = this.store.select(selectActiveTarget);
  activeQuery$ = this.store.select(selectActiveQuery);
  markers$ = this.store.select(selectMapMarkers);
  mapStyle$ = this.store.select(selectMapStyle);
  isExpanded$ = (targetId: TargetId): Observable<boolean> =>
    this.store.select(selectTargetIsExpanded(targetId));
  constructor(
    private store: Store,
    private geolocationQueryDataService: GeolocationQueryDataService
  ) {}
  public sendSingleGeolocationQuery(
    target: Target
  ): Observable<GeolocationQuery> {
    const payload: GeolocationQueryRequestPayload = {
      query_type: GeolocationQueryType.SIMPLE.toLowerCase(),
      queries: [{ telno: target.msisdn, imsi: target.imsi }],
    };
    return this.geolocationQueryDataService
      .sendSimpleGeolocationQuery(payload)
      .pipe(map(({ result }) => result[0]));
  }
  public createEmptyMission(): void {
    this.store.dispatch(MissionActions.createEmptyMission());
  }
  public setActiveMission(missionId: MissionId): void {
    this.store.dispatch(MissionActions.setActiveMission({ missionId }));
  }

  public setTimebarRange(range: TimebarRange | null): void {
    this.store.dispatch(MissionActions.setTimebarRange({ range }));
  }

  public deleteMission(missionId: MissionId): void {
    this.store
      .select(selectAllMissions)
      .pipe(
        take(1),
        tap((missions) => {
          if (missions.length === 1) {
            this.store.dispatch(MissionActions.clearMission({ missionId }));
          } else {
            this.store.dispatch(MissionActions.deleteMission({ missionId }));
          }
        })
      )
      .subscribe();
  }
  public setActiveTarget(targetId: TargetId): void {
    this.store.dispatch(MissionActions.setActiveTarget({ targetId }));
  }
  public clearActiveTarget(): void {
    this.store.dispatch(MissionActions.clearActiveTarget());
  }
  public addTargetToActiveMission(target: Target): void {
    this.store.dispatch(MissionActions.addTargetToActiveMission({ target }));
  }
  public deleteTarget(target: Target): void {
    this.store.dispatch(
      MissionActions.deleteTargetFromActiveMission({ target })
    );
  }
  public setActiveQuery(queryId: QueryId): void {
    this.store.dispatch(MissionActions.setActiveQuery({ queryId }));
  }
  public clearActiveQuery(): void {
    this.store.dispatch(MissionActions.clearActiveQuery());
  }
  public addQueriesToTargets(queries: GeolocationQuery[]): void {
    this.store.dispatch(MissionActions.addQueriesToTargets({ queries }));
  }
  public toggleTargetVisibility(
    missionId: string,
    targetId: string,
    visible: boolean
  ): void {
    const payload = { missionId, targetId, visible };

    this.store.dispatch(MissionActions.toggleTargetVisibility(payload));
  }
  public changeMapStyle(mapStyle: MapStyle): void {
    this.store.dispatch(MissionActions.changeMapStyle({ mapStyle }));
  }
  public reset(): void {
    this.store.dispatch(MissionActions.reset());
  }
  public fetchMsisdnFromImsi(imsi: string): Observable<string> {
    return this.geolocationQueryDataService.fetchMsisdnFromImsi(imsi);
  }

  public fetchLatestQueries(
    username: string
  ): Observable<LatestGeolocationQueryResponse> {
    return this.geolocationQueryDataService.fetchLatestQueries(username);
  }

  public fetchGeolocationQueriesByNumber(
    msisdnOrImsi: string
  ): Observable<{ target: Target; queries: GeolocationQuery[] }> {
    const isImsiOperation = !isValidMsisdn(msisdnOrImsi);
    const fetchOperation$ = isImsiOperation
      ? this.geolocationQueryDataService.fetchGeolocationQueriesByImsi(
          msisdnOrImsi
        )
      : this.geolocationQueryDataService.fetchGeolocationQueriesByMsisdn(
          msisdnOrImsi
        );
    return fetchOperation$.pipe(
      withLatestFrom(this.store.select(selectActiveMission)),
      map(([response, activeMission]) => {
        const queries = response.result;
        let msisdn = isValidMsisdn(msisdnOrImsi) ? msisdnOrImsi : '';
        let imsi = isImsiOperation ? msisdnOrImsi : '';
        if (msisdn) {
          imsi = extractImsiFromGeoQueries(response.result);
        } else {
          msisdn = extractMsisdnFromGeoQueries(response.result);
        }
        let target: Target;
        if (numberIsInActiveMission(activeMission, msisdnOrImsi)) {
          target = extractTargetFromMission(
            msisdnOrImsi,
            activeMission
          ) as Target;
          this.addQueriesToTargets(queries);
        } else {
          const color = determineTargetColor(activeMission);
          target = Target.create({
            missionId: activeMission.id,
            msisdn,
            imsi,
            color,
            queries,
          });
          this.addTargetToActiveMission(target);
        }

        return { target, queries };
      })
    );
  }
  public toggleTargetHistory(targetId: TargetId): void {
    this.store.dispatch(MissionActions.toggleTargetHistory({ targetId }));
  }

  public retrieveCallInfo(queryId: string): void {
    this.store.dispatch(MissionActions.retrieveCallInfo({ queryId }));
  }

  public retrieveCellTowers(query: GeolocationQuery): void {
    this.store.dispatch(MissionActions.retrieveCellTowers({ query }));
  }

  public checkForCellTowers(query: GeolocationQuery): void {
    this.store.dispatch(MissionActions.checkForCellTowers({ query }));
  }

  updateTowers(towers: CellTowerInfo[] | null): void {
    this.store.dispatch(MissionActions.updateCellTowers({ towers }));
  }
}
