import { Component } from '@angular/core';
import { Target } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';

import { debounceTime } from 'rxjs';
import { CssSelectorTargetIdPrefix } from './contants';

@Component({
  selector: 'keystone-active-mission',
  templateUrl: './active-mission.component.html',
  styleUrls: ['./active-mission.component.css'],
})
export class ActiveMissionComponent {
  CssSelectorTargetIdPrefix = CssSelectorTargetIdPrefix;

  constructor(private geolocationService: GeolocationFacade) {}

  activeMission$ = this.geolocationService.missions.activeMission$.pipe(
    debounceTime(10)
  );

  targetTrackBy(index: number, item: Target): string {
    return item.id;
  }
}
