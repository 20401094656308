<div class="left-side-menu pb-1">
  <div
    id="sidebar-menu"
    class="d-flex flex-column h-100 justify-content-between"
    keystoneFeatherIcon
  >
    <!-- Pages Section -->
    <ul class="mb-0">
      <li
        data-qa="sidebar-missions"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['missions']"
      >
        <a (click)="navigateToMission($event)">
          <i data-feather="map"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.MISSIONS' | translate
          }}</span>
        </a>
      </li>
      <li
        data-qa="sidebar-history"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['report-analysis']"
      >
        <a>
          <i data-feather="clock"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.HISTORY' | translate
          }}</span>
        </a>
      </li>
      <li
        data-qa="sidebar-robots"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['robots']"
      >
        <a>
          <i class="uil uil-robot uil-menu-icon"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.ROBOTS' | translate
          }}</span>
        </a>
      </li>
    </ul>
    <ul *ngrxLet="useRole$ as role">
      <hr />
      <li
        data-qa="sidebar-pricing"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['pricing']"
      >
        <a>
          <i class="uil uil-usd-circle uil-menu-icon"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.PRICING' | translate
          }}</span>
        </a>
      </li>
      <li
        *ngIf="role === userRoles.ADMIN || role === userRoles.POWERUSER"
        data-qa="sidebar-user-management"
        class="cursor-pointer"
        routerLinkActive="menuitem-active"
        [routerLink]="['admin', 'users']"
      >
        <a>
          <i data-feather="users"></i>
          <span class="cursor-pointer">{{
            'LEFT_SIDEBAR.USER_MANAGEMENT' | translate
          }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
