import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { PasswordValidationResult } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { delay, filter, map, Observable, of, switchMap } from 'rxjs';
import { createErrorsBasedOnValidatorPolicies } from './createErrorsBasedOnValidatorPolicies';

export function passwordPolicyValidator(auth: AuthFacade): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return of(control.value).pipe(
      delay(500),
      filter((controlValue: string) => {
        return !!controlValue;
      }),
      switchMap((controlValue: string) =>
        auth.checkPasswordPolicy(controlValue).pipe(
          map((passwordResult: PasswordValidationResult) => {
            return createErrorsBasedOnValidatorPolicies(passwordResult);
          })
        )
      )
    ) as Observable<ValidationErrors | null>;
  };
}
