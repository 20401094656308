<ng-container *ngrxLet="isMobile$ as isMobile">
  <div
    [ngClass]="{
      'mission-desktop-container': !isMobile,
      'mission-mobile-container': isMobile
    }"
  >
    <router-outlet></router-outlet>
  </div>
  <keystone-missions-nav-bar></keystone-missions-nav-bar>
  <keystone-mission-timebar
    *ngIf="timelineIsVisible$ | ngrxPush"
  ></keystone-mission-timebar>
  <keystone-mission-map></keystone-mission-map>
  <keystone-bottom-sheets></keystone-bottom-sheets>
</ng-container>
