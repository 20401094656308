import { Component } from '@angular/core';
import { GeolocationFacade } from '@fe-platform/geolocation/state';

@Component({
  selector: 'keystone-mission-target-history-web',
  templateUrl: './mission-target-history-web.component.html',
  styleUrls: ['./mission-target-history-web.component.css'],
})
export class MissionTargetHistoryWebComponent {
  activeTarget$ = this.geospatialService.missions.activeTarget$;

  constructor(private geospatialService: GeolocationFacade) {}
}
