import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { Map } from 'mapbox-gl';
import { Observable, tap } from 'rxjs';
import { calculateMapBoundsFromCoordinates } from './calculateMapBoundsFromCoordinates';
import { getCircleCoordinates } from './getCircleCoordinates';

export const flyToQuery =
  (map: Map) =>
  (
    query$: Observable<GeolocationQuery | null>
  ): Observable<GeolocationQuery | null> =>
    query$.pipe(
      tap((query) => {
        if (!query?.location || !map) return;
        const coordinates = getCircleCoordinates(
          [query.location.coordinates[0], query.location.coordinates[1]],
          query.accuracy_meters
        );
        map?.fitBounds(calculateMapBoundsFromCoordinates(coordinates));
      })
    );
