import { Component } from '@angular/core';
import { MissionId } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { map } from 'rxjs';
import { environment } from 'environments/environment';
import { MissionsNavBarService } from '../missions-nav-bar.service';
import { BottomSheetsService } from '@fe-platform/shared-ui/keystone';

@Component({
  selector: 'keystone-missions-bottom-sheet',
  templateUrl: './missions-bottom-sheet.component.html',
  styleUrls: ['./missions-bottom-sheet.component.css'],
})
export class MissionsBottomSheetComponent {
  missions$ = this.geolocationService.missions.missions$;
  activeMission$ = this.geolocationService.missions.activeMission$;
  addButtonEnabled$ = this.missions$.pipe(
    map((missions) => missions.length),
    map((missionLength) => missionLength < environment.missions.maxMissions)
  );

  constructor(
    private geolocationService: GeolocationFacade,
    private missionsNavBarService: MissionsNavBarService,
    private bottomSheetService: BottomSheetsService
  ) {}

  public onSelectMission(missionId: MissionId): void {
    this.bottomSheetService.closeAll();
    this.missionsNavBarService.onSelectMission(missionId);
  }

  public onAddMission(): void {
    this.missionsNavBarService.onAddMission();
  }

  public onDeleteMission(missionId: MissionId): void {
    this.missionsNavBarService.onDeleteMission(missionId);
  }
}
