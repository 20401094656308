import { Injectable } from '@angular/core';
import {
  GeolocationQuery,
  GeolocationQueryDataService,
  GeolocationQueryRequestPayload,
  Robot,
  Target,
} from '@fe-platform/geolocation/data';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { RobotActions, selectActiveRobots } from './reducer';

@Injectable({ providedIn: 'root' })
export class RobotsFacade {
  constructor(
    private store: Store,
    private geolocationQueryDataService: GeolocationQueryDataService
  ) {}
  activeRobots$ = this.store.select(selectActiveRobots);
  public getActiveRobot$(target: Target): Observable<Robot | undefined> {
    return this.activeRobots$.pipe(
      map((robots) =>
        robots.find(
          (r) =>
            r.payload.query_args.telno === target.msisdn ||
            r.payload.query_args.imsi === target.imsi
        )
      )
    );
  }
  public hasActiveRobot$(target: Target): Observable<boolean> {
    return this.getActiveRobot$(target).pipe(map((r) => !!r));
  }
  public createRobot(
    payload: GeolocationQueryRequestPayload
  ): Observable<GeolocationQuery> {
    return this.geolocationQueryDataService
      .sendSimpleGeolocationQuery(payload)
      .pipe(map(({ result }) => result[0]));
  }
  public deleteRobot(id: string): Observable<GeolocationQuery> {
    return this.geolocationQueryDataService.deleteScheduledQuery(id);
  }
  public fetchRobots(): Observable<Robot[]> {
    return this.geolocationQueryDataService.fetchActiveScheduledQueries();
  }

  public setActiveRobots(robots: Robot[]): void {
    this.store.dispatch(RobotActions.setActiveRobots({ robots }));
  }
}
