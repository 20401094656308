import { Inject, Injectable } from '@angular/core';
import { PROXY_API_URL } from '@fe-platform/core/config';
import { ApiService } from '@fe-platform/core/http';
import { Observable } from 'rxjs';
import { FeatureFlags } from './models';

@Injectable({ providedIn: 'root' })
export class ServerConfigService {
  constructor(
    @Inject(PROXY_API_URL) private proxyApiUrl: string,
    private apiService: ApiService
  ) {}

  public fetchEnvironmentConfig(): Observable<FeatureFlags> {
    return this.apiService.get<FeatureFlags>(`${this.proxyApiUrl}/app/config`);
  }
}
