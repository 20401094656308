<div class="modal-header pb-0" data-qa="modal-add-new-target">
  <h4 class="modal-title text-white" data-qa="modal-add-new-target-title">
    {{ 'QUERY_BOX.ADD_NEW_TARGET' | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    data-qa="modal-add-new-target-close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>
<div class="modal-body" data-qa="modal-body">
  <keystone-search-new-query-form
    (searchFormSubmitted$)="onSubmit($event)"
  ></keystone-search-new-query-form>
  <ng-container *ngrxLet="vm$ as vm">
    <keystone-latest-queries-list
      *ngIf="vm.latestQueries.length && !vm.loading"
      [latestQueries]="vm.latestQueries"
    ></keystone-latest-queries-list>
    <keystone-loader
      *ngIf="vm.loading"
      [fullscreen]="false"
      class="mt-2"
    ></keystone-loader>
  </ng-container>
</div>
