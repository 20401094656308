<form
  [formGroup]="searchForm"
  (ngSubmit)="onSubmitGeolocationQuerySearch()"
  autocomplete="off"
>
  <div class="d-flex">
    <div class="input-group">
      <span class="input-group-text">
        <i class="bi bi-search"></i>
      </span>
      <input
        #searchInput
        keystoneMsisdnOrImsi
        id="searchInput"
        class="form-control"
        data-qa="search-input"
        type="tel"
        formControlName="target"
        ngbAutofocus
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        placeholder="{{ 'QUERY_BOX.ENTER_MSISDN_OR_IMSI' | translate }}"
      />
      <ng-container *ngIf="isMobile$ | ngrxPush; else showDesktopHint">
        <button
          id="btn-clear"
          class="btn btn-close text-white"
          [class.d-none]="!searchInput.value"
          type="button"
          (click)="onClearMobileSearchField()"
        ></button>
      </ng-container>
      <ng-template #showDesktopHint>
        <span *ngIf="searchInput.value && inputFocus" id="hint">{{
          'QUERY_BOX.PRESS_ENTER' | translate
        }}</span>
      </ng-template>
    </div>
    <button
      class="btn btn-primary ms-1"
      data-qa="add-target-btn"
      type="submit"
      title="{{ 'QUERY_BOX.ADD_NEW_TARGET' | translate }}"
      [disabled]="!searchInput.value"
    >
      {{ 'QUERY_BOX.ADD' | translate }}
    </button>
  </div>
</form>
