<div class="form-switch mb-2">
  <input
    [disabled]="disabled"
    type="checkbox"
    id="switch-2fa"
    class="form-check-input"
    [value]="initialValue"
    (change)="otpConfigChecked($event)"
  />
  <label for="switch-2fa" class="form-check-label ms-1">{{
    'OTP.TOGGLE_TWO_FACTOR' | translate
  }}</label>
</div>
