import { Pipe, PipeTransform } from '@angular/core';
import { stringToDate } from '@fe-platform/core/date';
import { Robot } from '@fe-platform/geolocation/data';
import { differenceInMinutes, isBefore } from 'date-fns';

@Pipe({ name: 'formatRobotExecutedSearches' })
export class RobotExecutedSearches implements PipeTransform {
  transform(robot: Robot): number {
    const MIN_SEARCHES = 1;
    const now = new Date();
    const cancelledAt = robot.canceled_at
      ? stringToDate(robot.canceled_at)
      : null;
    const start = stringToDate(robot.starts_at);
    const end = cancelledAt && isBefore(cancelledAt, now) ? cancelledAt : now;

    return (
      Math.ceil(
        Math.abs(differenceInMinutes(start, end) / robot.interval_minutes)
      ) || MIN_SEARCHES
    );
  }
}
