import { toMediumDateString } from '@fe-platform/core/date';
import { GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatLocationReceivedAtDate: ColumnDataFormatter<
  GeolocationQuery,
  string
> = (query) => {
  if (query.location_received_at) {
    return toMediumDateString(query.location_received_at);
  } else if (query.created_at) {
    return toMediumDateString(query.created_at);
  }

  return '-';
};
