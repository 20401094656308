import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { AuthActions } from '../actions';

export function handleAuthResponseError$(store: Store) {
  return (error: unknown): Observable<never> => {
    if (typeof error === 'string') {
      store.dispatch(AuthActions.setErrorMessage({ errorMessage: error }));
    }
    if (error instanceof Error) {
      store.dispatch(
        AuthActions.setErrorMessage({ errorMessage: error.message })
      );
    }
    if (error instanceof HttpErrorResponse) {
      const errorMessage =
        error.error['error']?.message ??
        'Unexpected network error. Please try again later';
      store.dispatch(AuthActions.setErrorMessage({ errorMessage }));
    }
    return EMPTY;
  };
}
