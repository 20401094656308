import { QueryId } from './geolocation-query';
import { TargetId } from './target';
export enum MarkerStatus {
  DEFAULT,
  FOCUSED,
  DEFOCUSED,
}
export interface Marker {
  targetId: TargetId;
  type: string;
  queryId: QueryId;
  dt: {
    dt1: number;
  }[];
  longLat: [number, number];
  color: string;
  status: MarkerStatus;
  hasExtraInfo: boolean;
}
