import { Component, Input } from '@angular/core';
import { Target } from '@fe-platform/geolocation/data';

@Component({
  selector: 'keystone-latest-located-label',
  templateUrl: './latest-located-label.component.html',
})
export class LatestLocatedLabelComponent {
  @Input() target!: Target;
  @Input() hasPendingQuery!: boolean;
  @Input() isMobile!: boolean;
}
