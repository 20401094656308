import {
  LngLat,
  LngLatBounds,
  Mission,
  Target,
  targetHasBeenLocated,
} from '@fe-platform/geolocation/data';
import { Map } from 'mapbox-gl';
import { Observable, tap } from 'rxjs';
import { calculateMapBoundsFromCoordinates } from './calculateMapBoundsFromCoordinates';
export const flyToMission =
  (map: Map | undefined) =>
  (
    source$: Observable<[Mission, Target | null]>
  ): Observable<[Mission, Target | null]> =>
    source$.pipe(
      tap(([mission, target]) => {
        if (mission.visibleTargets?.some(targetHasBeenLocated) && !target) {
          map?.fitBounds(calculateMapBoundsFromMission(mission));
        }
      })
    );

function calculateMapBoundsFromMission(mission: Mission): LngLatBounds {
  const coordinates = mission.visibleTargets
    .flatMap((t) => t.queries)
    .map((q) => q.location?.coordinates)
    .filter(Boolean) as LngLat[];
  return calculateMapBoundsFromCoordinates(coordinates);
}
