import { Component } from '@angular/core';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { LatestQueriesService } from '../latest-queries.service';
import { GeoquerySearchBoxService } from '../geoquery-search-box.service';
@Component({
  selector: 'keystone-search-box-mobile',
  templateUrl: './search-box-mobile.component.html',
  styleUrls: ['./search-box-mobile.component.css'],
})
export class SearchBoxMobileComponent {
  public showSearchMobile = false;
  activeMission$ = this.geolocationService.missions.activeMission$;
  vm$ = this.geoquerySearchBoxLatestQueriesService.vm$;
  constructor(
    private geolocationService: GeolocationFacade,
    private geoquerySearchBoxService: GeoquerySearchBoxService,
    private geoquerySearchBoxLatestQueriesService: LatestQueriesService
  ) {}

  public onToggleSearch(): void {
    this.showSearchMobile = !this.showSearchMobile;
  }

  public onCloseSearch(): void {
    this.showSearchMobile = false;
  }

  onSubmit(val: string): void {
    this.geoquerySearchBoxService.submit$.next(val);
  }
}
