import { Injectable } from '@angular/core';
import { AuthStatus, TOKEN } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { filter, map, Observable, tap } from 'rxjs';
import { ServerWsTsWebsocketService, validateMessage$ } from './helpers';

import { EventChannel } from '@trg-commons/gio-data-models-ts';

@Injectable({ providedIn: 'root' })
export class StreamManager {
  constructor(
    private websocketService: ServerWsTsWebsocketService,
    private auth: AuthFacade
  ) {
    this.auth.status$
      .pipe(
        tap((status) => {
          if (status === AuthStatus.AUTHENTICATED) {
            this.initializeConnection();
          } else {
            this.websocketService.disconnectFromExistingConnection();
          }
        })
      )
      .subscribe();
  }
  public connected$ = this.websocketService.connected$;
  public initializeConnection(): void {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      this.websocketService.initialize(token);
    } else {
      throw new Error(
        'token is not present while tried to connect to websocket server'
      );
    }
  }
  public onEvent<T>(channel: EventChannel, ...events: string[]): Observable<T> {
    return this.websocketService.stream$.pipe(
      filter((m) => m.channel === channel),
      validateMessage$,
      filter((eventBody) => events.includes(eventBody[0])),
      map((eventBody) => eventBody[1])
    ) as Observable<T>;
  }
}
