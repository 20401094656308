import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValidImsi, isValidMsisdn } from '@fe-platform/core/phone';

export function searchInputValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;
    const isValid = [isValidMsisdn(value), isValidImsi(value)] as const;

    if (isValid.includes(true)) {
      return null;
    } else {
      return { inputIsNeitherMsisdnOrImsi: true };
    }
  };
}
