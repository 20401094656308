import { Component } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'keystone-missions-nav-bar',
  templateUrl: './missions-nav-bar.component.html',
  styleUrls: ['./missions-nav-bar.component.css'],
})
export class MissionsNavBarComponent {
  isMobile$: Observable<boolean> = this.layoutFacade.isMobile$;

  constructor(private layoutFacade: LayoutFacade) {}
}
