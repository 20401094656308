<button
  id="btn-search-desktop"
  class="btn btn-light"
  type="button"
  (click)="onOpenModal()"
  title="{{ 'QUERY_BOX.ADD_NEW_TARGET' | translate }}"
>
  <i class="bi bi-plus-circle-fill me-1"></i>
  {{ 'QUERY_BOX.ADD_NEW_TARGET' | translate }}
</button>
