import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdleTimerService } from '@fe-platform/auth/feature-idle-timer';
import { NetworkLostStateManager } from '@fe-platform/core/state';
import { Msisdn } from '@fe-platform/geolocation/data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IdleTimerModalComponent } from '@fe-platform/auth/feature-idle-timer';
import { GeoJSONSourceOptions } from 'mapbox-gl';
import { LayoutService } from './layout.service';
import { environment } from '@fe-platform/environment';

@UntilDestroy()
@Component({
  selector: 'keystone-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  providers: [NetworkLostStateManager, LayoutService],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public heatMapData?: GeoJSONSourceOptions['data'];
  public searchResults: {
    created_at: string;
    created_by: string;
    id: string;
    status: string;
    provider: { telno: Msisdn };
  }[] = [];

  constructor(
    public router: Router,
    private networkLostManager: NetworkLostStateManager,
    private idleTimerService: IdleTimerService,
    private modalService: NgbModal,
    private injector: Injector,
    private layoutService: LayoutService
  ) {
    this.networkLostManager
      .restoreAppStateOnNetworkRestore$()
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  ngOnInit(): void {
    this.startIdleTimer();
  }

  ngOnDestroy(): void {
    this.idleTimerService.destroy();
  }

  private startIdleTimer(): void {
    this.idleTimerService.start(environment.idleTimeoutInSec, () => {
      this.modalService
        .open(IdleTimerModalComponent, {
          backdrop: 'static',
          keyboard: false,
          injector: this.injector,
        })
        .closed.subscribe((result: string) => {
          if (result === 'exit') this.layoutService.signOut();
          else this.startIdleTimer();
        });
    });
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu(): void {
    document.body.classList.toggle('sidebar-enable');
  }
}
