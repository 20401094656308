import { Mission, MissionId } from '@fe-platform/geolocation/data';

export function findActiveMission(
  missions: Mission[],
  activeMissionId: MissionId
): Mission {
  const mission = missions.find((m) => m.id === activeMissionId);
  if (!mission) throw new Error('active mission id not found in missions');
  return mission;
}
