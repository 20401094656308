import { Component, Input } from '@angular/core';
import { CellTowerInfo } from '@fe-platform/geolocation/data';
import {
  copyLatLngToClipboard,
  viewInGoogleMaps,
} from '../../mission-query-card/helpers';

@Component({
  selector: 'keystone-cell-tower-popup',
  templateUrl: './cell-tower-popup.component.html',
  styles: [],
})
export class CellTowerPopupComponent {
  @Input() cellTower!: CellTowerInfo;

  copyLatLngToClipboard(coordinates: { lat: number; lon: number }): void {
    copyLatLngToClipboard([coordinates.lon, coordinates.lat]);
  }

  viewInGoogleMaps(coordinates: { lat: number; lon: number }): void {
    viewInGoogleMaps([coordinates.lon, coordinates.lat]);
  }
}
