import {
  GeolocationQuery,
  QueryId,
  Target,
} from '@fe-platform/geolocation/data';

export function findActiveQuery(
  activeTarget: Target | null,
  queryId: QueryId | null
): GeolocationQuery | null {
  const query = activeTarget?.queries.find((q) => q.id === queryId);
  return query ?? null;
}
