import { GeolocationQuery, LngLat, NMR } from '@fe-platform/geolocation/data';
import { map, Observable } from 'rxjs';

export function queryToSectorGeoJSON(
  query$: Observable<GeolocationQuery | null>
): Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined> {
  return query$.pipe(
    map((query) => {
      if (!query?.nmr || !query?.nmr.billing_id || !query.location) return;
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                getGeoJSONSector(
                  query.location.coordinates,
                  query.nmr,
                  query.accuracy_meters
                ),
              ],
            },
            properties: [],
          },
        ],
      };
    })
  );
}

// Based on calculateCircularSector() of Intellectus
function getGeoJSONSector(
  center: LngLat,
  sectorNMR: NMR,
  accuracyMeters: number
): LngLat[] {
  const R = 6378.1; // radius of the earth in Km
  const pi = Math.PI;
  const accuracyKm = accuracyMeters / 1000;

  const centerLatRad = center[1] * (pi / 180);
  const centerLngRad = center[0] * (pi / 180);

  const bearingStart = sectorNMR.combas_bearing - sectorNMR.sector_size / 2;
  const bearingStartRad = bearingStart * (pi / 180);

  const bearingEnd = sectorNMR.combas_bearing + sectorNMR.sector_size / 2;
  const bearingEndRad = bearingEnd * (pi / 180);

  const startLat = Math.asin(
    Math.sin(centerLatRad) * Math.cos(accuracyKm / R) +
      Math.cos(centerLatRad) *
        Math.sin(accuracyKm / R) *
        Math.cos(bearingStartRad)
  );
  const startLng =
    centerLngRad +
    Math.atan2(
      Math.sin(bearingStartRad) *
        Math.sin(accuracyKm / R) *
        Math.cos(centerLatRad),
      Math.cos(accuracyKm / R) - Math.sin(centerLatRad) * Math.sin(startLat)
    );
  const sectorStartLat = startLat * (180 / pi);
  const sectorStartLng = startLng * (180 / pi);

  const endLat = Math.asin(
    Math.sin(centerLatRad) * Math.cos(accuracyKm / R) +
      Math.cos(centerLatRad) *
        Math.sin(accuracyKm / R) *
        Math.cos(bearingEndRad)
  );
  const endLng =
    centerLngRad +
    Math.atan2(
      Math.sin(bearingEndRad) *
        Math.sin(accuracyKm / R) *
        Math.cos(centerLatRad),
      Math.cos(accuracyKm / R) - Math.sin(centerLatRad) * Math.sin(endLat)
    );
  const sectorEndLat = endLat * (180 / pi);
  const sectorEndLng = endLng * (180 / pi);

  const arcPts = calcArcPoints(
    center,
    calcBearing(center, [sectorStartLng, sectorStartLat]),
    calcBearing(center, [sectorEndLng, sectorEndLat]),
    accuracyMeters
  );

  return [center, ...arcPts, center];
}

function calcBearing(center: LngLat, point: LngLat): number {
  const pi = Math.PI;
  const centerLatRad = center[1] * (pi / 180);
  const centerLngRad = center[0] * (pi / 180);
  const pointLatRad = point[1] * (pi / 180);
  const pointLngRad = point[0] * (pi / 180);
  let angle = -Math.atan2(
    Math.sin(centerLngRad - pointLngRad) * Math.cos(pointLatRad),
    Math.cos(centerLatRad) * Math.sin(pointLatRad) -
      Math.sin(centerLatRad) *
        Math.cos(pointLatRad) *
        Math.cos(centerLngRad - pointLngRad)
  );
  if (angle < 0.0) {
    angle += pi * 2.0;
  }
  if (angle > pi) {
    angle -= pi * 2.0;
  }
  return angle * (180 / pi);
}

function calcArcPoints(
  center: LngLat,
  bearingStart: number,
  bearingEnd: number,
  radius: number
): LngLat[] {
  const points = 32;
  const extp = [];
  if (bearingStart > bearingEnd) {
    bearingEnd += 360;
  }
  const bearingDelta = (bearingEnd - bearingStart) / points;
  for (let i = 0; i < points + 1; i++) {
    extp.push(destPoint(center, bearingStart + i * bearingDelta, radius));
  }
  return extp;
}

function destPoint(center: LngLat, bearing: number, dist: number): LngLat {
  const R = 6378.1 * 1000; // earth's mean radius in meters
  const centerLatRad = center[1] * (Math.PI / 180);
  const centerLngRad = center[0] * (Math.PI / 180);
  const bearingRad = bearing * (Math.PI / 180);
  const distLat = Math.asin(
    Math.sin(centerLatRad) * Math.cos(dist / R) +
      Math.cos(centerLatRad) * Math.sin(dist / R) * Math.cos(bearingRad)
  );
  const distLng =
    centerLngRad +
    Math.atan2(
      Math.sin(bearingRad) * Math.sin(dist / R) * Math.cos(centerLatRad),
      Math.cos(dist / R) - Math.sin(centerLatRad) * Math.sin(distLat)
    );
  const distLngRad = distLng * (180 / Math.PI);
  const distLatRad = distLat * (180 / Math.PI);
  return [distLngRad, distLatRad];
}
