import { Notification } from '@fe-platform/notifications/data';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NotificationsActions = createActionGroup({
  source: 'Notifications',
  events: {
    'set Notifications': props<{ notifications: Notification[] }>(),
    'push Notification': props<{ notification: Notification }>(),
    'mark As Read': props<{ ids: string[] }>(),
    'set As Read': props<{ ids: string[] }>(),
    'mark All As Read': emptyProps(),
    'set All As Read': emptyProps(),
    'clear Notifications': emptyProps(),
  },
});
