import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GeolocationQueryEffects } from './effects';
import { GeolocationQueriesFeature } from './reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(GeolocationQueriesFeature),
    EffectsModule.forFeature([GeolocationQueryEffects]),
  ],
})
export class GeolocationQueriesStoreModule {}
