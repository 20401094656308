import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { ToasterService } from './toaster.service';

export const handleErrorAndShowToaster$ =
  (toasterService: ToasterService, translationService: TranslateService) =>
  <T>(source$: Observable<T>): Observable<T | never> => {
    return source$.pipe(
      catchError((e: Error) => {
        console.error(e);
        toasterService.showError({
          content: translationService.instant(e.message),
        });
        return EMPTY;
      })
    );
  };
