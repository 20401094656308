import { NgModule } from '@angular/core';
import { GeolocationQueriesStoreModule } from './geolocation-queries';
import { MissionStoreModule } from './missions/missions-store.module';
import { PendingQueriesStoreModule } from './pending-queries/pending-queries-store.module';
import { RobotsStoreModule } from './robots/robots-store.module';

@NgModule({
  imports: [
    GeolocationQueriesStoreModule,
    MissionStoreModule,
    PendingQueriesStoreModule,
    RobotsStoreModule,
  ],
})
export class GeolocationStoreModule {}
