import { GeolocationQuery } from '../models';

export function extractMsisdnFromGeoQueries(
  geoqueries: GeolocationQuery[]
): string {
  if (!geoqueries.length) return '';
  return extractMsisdnFromGeoQuery(geoqueries[0]);
}
export function extractMsisdnFromGeoQuery(geoquery: GeolocationQuery): string {
  if (!geoquery.provider?.telno) return '';
  return geoquery.provider.telno;
}
