import {
  GeolocationQuery,
  queryIsCompleted,
} from '@fe-platform/geolocation/data';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  createActionGroup,
  createReducer,
  props,
  on,
  emptyProps,
  createFeature,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

type PendingQueriesState = EntityState<GeolocationQuery>;

export const adapter: EntityAdapter<GeolocationQuery> =
  createEntityAdapter<GeolocationQuery>();
export const initialState: PendingQueriesState = adapter.getInitialState();
export const PendingQueriesStateName = 'PendingQueries';
export const PendingQueriesActions = createActionGroup({
  source: 'PendingQueries',
  events: {
    'add Pending Queries': props<{ queries: GeolocationQuery[] }>(),
    'update Pending Query': props<{ query: GeolocationQuery }>(),
    'clear Pending Queries': emptyProps(),
  },
});
const PendingQueriesReducer = createReducer(
  initialState,
  on(PendingQueriesActions.addPendingQueries, (state, action) =>
    adapter.setAll(action.queries, state)
  ),
  on(PendingQueriesActions.updatePendingQuery, (state, action) =>
    queryIsCompleted(action.query)
      ? adapter.removeOne(action.query.id, state)
      : adapter.setOne(action.query, state)
  ),
  on(PendingQueriesActions.clearPendingQueries, (state) =>
    adapter.removeAll(state)
  )
);
const { selectAll } = adapter.getSelectors();
export const selectPendingQueriesFeature =
  createFeatureSelector<PendingQueriesState>(PendingQueriesStateName);
export const selectPendingQueries = createSelector(
  selectPendingQueriesFeature,
  selectAll
);
export const PendingQueriesFeature = createFeature({
  name: PendingQueriesStateName,
  reducer: PendingQueriesReducer,
});
