import { Directive, Host, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Spaces and symbols in-between integers and at the end should be trimmed.
 * Spaces and symbols other than “+” at the front of the first integer should be trimmed.
 * if “00” are the first 2 integers, replace with “+”
 */
@Directive({
  selector: '[keystoneMsisdnOrImsi]',
})
export class MsisdnImsiInputDirective {
  constructor(@Host() private control: NgControl) {}

  @HostListener('keyup', ['$event.target.value'])
  change(): void {
    this.control.control?.setValue(this.transform(this.control.value));
  }

  transform(value: string): string {
    if (!value) return '';
    value = value.trim();

    const prefix = this.startsWithZeroOrPlus(value) ? '+' : '';
    const sanitizedValue = value.replace(/[^0-9a-zA-Z]+/g, '');

    if (value.startsWith('00')) {
      return `${prefix}${sanitizedValue.slice(2)}`;
    }

    return `${prefix}${sanitizedValue}`;
  }

  startsWithZeroOrPlus(value: string): boolean {
    return /^(00)|^\+/.test(value);
  }
}
