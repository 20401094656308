import { Injectable } from '@angular/core';
import {
  FeatureFlags,
  ServerConfigService,
} from '@fe-platform/server-config/data';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  ServerConfigActions,
  ServerConfigEnvironmentState,
  selectFeatureFlags,
} from './reducer';
@Injectable({ providedIn: 'root' })
export class ServerConfigFacade {
  featureFlags$ = this.store.select(selectFeatureFlags);

  constructor(
    private store: Store<ServerConfigEnvironmentState>,
    private serverConfigService: ServerConfigService
  ) {}

  public setEnvironmentConfig(featureFlags: FeatureFlags): void {
    this.store.dispatch(ServerConfigActions.setFeatureFlags({ featureFlags }));
  }

  public fetchEnvironmentConfig(): Observable<FeatureFlags> {
    return this.serverConfigService.fetchEnvironmentConfig();
  }
}
