import { Component } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'keystone-mission-target-history',
  templateUrl: './mission-target-history.component.html',
  styleUrls: ['./mission-target-history.component.css'],
})
export class MissionTargetHistoryComponent {
  isMobile$: Observable<boolean> = this.layoutFacade.isMobile$;

  constructor(private layoutFacade: LayoutFacade) {}
}
