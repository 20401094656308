import { isBefore } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const tokenIsValid = (token: string): boolean =>
  Boolean(token && !tokenIsExpired(token));

const tokenIsExpired = (token: string): boolean => {
  const decodedToken = jwtDecode<JwtPayload>(token);
  if (decodedToken.exp) {
    const expiresAt = new Date(decodedToken.exp * 1000);
    return isBefore(expiresAt, Date.now());
  } else {
    return true;
  }
};
