<div class="card col-10 col-md-4 p-4">
  <h6 class="h5 mb-1 mt-3">{{ 'LOGIN.CHANGE_YOUR_PASSWORD' | translate }}</h6>

  <keystone-password-form
    (password$)="handlePasswordFormChanged($event)"
    (formIsValid$)="handlePasswordFormValidityChanged($event)"
  ></keystone-password-form>
  <keystone-otp-toggle
    *ngIf="otpToggleVisible$ | ngrxPush"
    (otpConfigToggled$)="onOtpConfigToggled($event)"
  >
  </keystone-otp-toggle>
  <keystone-otp-config-form
    *ngIf="otpConfigFormVisible$ | ngrxPush"
    (formIsValid$)="handleOtpFormValidity($event)"
    (otpConfigChanged$)="handleOtpSelection($event)"
  >
  </keystone-otp-config-form>
  <keystone-otp-code-form
    *ngIf="otpCodeFormVisible$ | ngrxPush"
    (otpCodeChanged)="handleOtpCode($event)"
  >
  </keystone-otp-code-form>
  <div class="mb-1 text-center d-grid">
    <button
      class="btn btn-primary"
      [disabled]="!(submitEnabled$ | ngrxPush)"
      (click)="submit()"
    >
      {{ 'CONTINUE' | translate }}
    </button>
  </div>
  <ng-container *ngIf="errorMessage$ | ngrxPush; let errorMessage">
    <div class="invalid-feedback">
      {{ errorMessage | translate }}
    </div>
  </ng-container>
</div>
