import {
  BehaviorSubject,
  debounceTime,
  filter,
  map,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import {
  englishLocale,
  spanishLocale,
  timebarOptions,
} from './timebar-options';
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Mission,
  PropertyChangedTimebarEvents,
  TimebarEvent,
  TimebarEventActions,
} from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TimeBar, TimeBarOptions } from '@trg-ui/link-analysis';
import { tap } from 'rxjs';
import { convertGeoqueryToTimelineItems } from './convertGeoqueryToTimelineItem';
import { createTooltipPositions } from './createTimebarTooltips';
@UntilDestroy()
@Component({
  selector: 'keystone-mission-timebar',
  templateUrl: './mission-timebar.component.html',
  styleUrls: ['./mission-timebar.component.css'],
})
export class MissionTimeBarComponent implements OnInit, OnDestroy {
  constructor(
    private geolocationFacade: GeolocationFacade,
    private translateService: TranslateService
  ) {}

  mission$ = this.geolocationFacade.missions.activeMission$;
  timebar$ = new BehaviorSubject<TimeBar | null>(null);
  private _timebarDestroyed$ = new Subject<void>();
  timebarPropertyChanged$ = new Subject<TimebarEventActions>();

  timebarOptions: TimeBarOptions = { ...timebarOptions };
  @ViewChild('tooltip') tooltip!: ElementRef;
  timebarHoveredOver$ = new Subject<TimebarEvent>();

  tooltipContent$ = this.timebarHoveredOver$.pipe(
    untilDestroyed(this),
    filter((event) => event.name === TimebarEventActions.HOVER),
    map((event: TimebarEvent) => {
      return createTooltipPositions(event, this.tooltip, this.translateService);
    })
  );

  ngOnInit(): void {
    this.translateService.onLangChange
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((lang) => {
        if (lang.lang === 'es') {
          this.timebarOptions = {
            ...timebarOptions,
            locale: spanishLocale,
          };
        } else {
          this.timebarOptions = { ...timebarOptions, locale: englishLocale };
        }
      });
    this.timebarPropertyChanged$
      .pipe(
        untilDestroyed(this),
        filter((eventName) => PropertyChangedTimebarEvents.includes(eventName)),
        debounceTime(300),
        switchMap(() => this.timebar$.pipe(take(1))),
        filter(Boolean),
        tap((timebar) => {
          this.tooltip.nativeElement.classList.add('fadeout');
          this.geolocationFacade.missions.setTimebarRange({
            dateFrom: timebar.range().dt1,
            dateTo: timebar.range().dt2,
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._timebarDestroyed$.next();
  }

  klReady([timebar]: [TimeBar]): void {
    this.timebar$.next(timebar);
    this.subscribeToActiveMissionGeoqueries();
  }

  subscribeToActiveMissionGeoqueries(): void {
    this.geolocationFacade.missions.locatedGeoqueriesInActiveMission$
      .pipe(
        untilDestroyed(this),
        takeUntil(this._timebarDestroyed$),
        tap((queries) => {
          const timebar = this.timebar$.getValue();
          if (!timebar)
            throw new Error(
              'cannot set timebar list items. Timebar does not exist'
            );
          timebar.load({
            items: convertGeoqueryToTimelineItems(queries),
          });
        })
      )
      .subscribe();
    this.geolocationFacade.missions.activeMission$
      .pipe(
        untilDestroyed(this),
        debounceTime(10),
        takeUntil(this._timebarDestroyed$),
        tap((mission: Mission) => {
          if (!mission.targets.length) {
            this.timebar$.next(null);
            this._timebarDestroyed$.next();
          }
          const timebar = this.timebar$.getValue();
          if (!timebar) return;
          if (!mission.timebarRange) {
            timebar?.zoom('fit', { animate: true });
          } else {
            timebar?.range(
              mission.timebarRange.dateFrom,
              mission.timebarRange.dateTo
            );
          }
        })
      )
      .subscribe();
  }

  klTimebarEvents(event: TimebarEvent): void {
    this.timebarPropertyChanged$.next(event.name);
    this.timebarHoveredOver$.next(event);
  }
}
