import { ValueOf } from '@fe-platform/core/types';
import { filterOutMeaninglessNotifications } from '@fe-platform/notifications/data';
import { createReducer, on } from '@ngrx/store';
import { NotificationsActions } from './actions';
import { adapter, initialState } from './state';
export const NotificationsStateName = 'Notifications';

export type NotificationAction = ReturnType<
  ValueOf<typeof NotificationsActions>
>;

export const notificationsReducer = createReducer(
  initialState,
  on(NotificationsActions.setNotifications, (state, action) =>
    adapter.setAll(
      filterOutMeaninglessNotifications(action.notifications),
      state
    )
  ),
  on(NotificationsActions.pushNotification, (state, action) =>
    adapter.upsertOne(action.notification, state)
  ),
  on(NotificationsActions.clearNotifications, (state) =>
    adapter.removeAll(state)
  ),
  on(NotificationsActions.setAsRead, (state, action) =>
    adapter.updateMany(
      action.ids.map((id) => ({
        id,
        changes: {
          is_read: true,
        },
      })),
      state
    )
  ),
  on(NotificationsActions.setAllAsRead, (state) =>
    adapter.updateMany(
      state.ids.map((id) => ({
        id: String(id),
        changes: {
          is_read: true,
        },
      })),
      state
    )
  )
);
