<div class="card p-0" *ngrxLet="activeTarget$ as activeTarget">
  <div class="card-header">
    <h5
      class="card-title d-flex align-items-center justify-content-between text-white mb-0"
    >
      <span>
        <span class="card-title-icon me-1">
          <i class="uil uil-history"></i>
        </span>
        {{ 'TARGET_CARD.HISTORY_FOR' | translate }}
        {{ activeTarget?.msisdn || activeTarget?.imsi }}
      </span>
      <button type="button" class="btn btn-close" routerLink=".."></button>
    </h5>
  </div>
  <div class="card-body py-0 pe-1">
    <keystone-mission-target-history-list
      *ngIf="activeTarget"
      [target]="activeTarget"
    ></keystone-mission-target-history-list>
  </div>
</div>
