import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  OTPChannels,
  OtpConfigurationWithHashedRecipient,
} from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as feather from 'feather-icons';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  switchMap,
  take,
  tap,
} from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'keystone-otp-code-form',
  templateUrl: './otp-code-form.component.html',
  styleUrls: ['./otp-code-form.component.css'],
})
export class OtpCodeFormComponent implements OnInit, AfterViewInit {
  constructor(public auth: AuthFacade, private sanitizer: DomSanitizer) {}

  otpConfiguration$: Observable<OtpConfigurationWithHashedRecipient> =
    this.auth.otpConfiguration$;
  @Output() otpCodeChanged = new EventEmitter<string>();
  channels = OTPChannels;
  otpForm = new UntypedFormGroup({
    otpCode: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(6),
    ]),
  });

  QRImage: BehaviorSubject<SafeUrl | undefined> = new BehaviorSubject<
    SafeUrl | undefined
  >(undefined);
  errorMessage$ = this.auth.errorMessage$;

  ngOnInit(): void {
    this.otpForm.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.otpForm.valid),
        map(({ otpCode }) => otpCode),
        tap((code) => this.otpCodeChanged.emit(code))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    feather.replace();
    this.otpConfiguration$
      .pipe(
        take(1),
        filter((configuration) => configuration.channel === OTPChannels.App),
        switchMap(() => this.auth.generateOTPAuthenticatorQRCode()),
        tap((imageUrl: string) => {
          this.QRImage.next(this.sanitizer.bypassSecurityTrustUrl(imageUrl));
        })
      )
      .subscribe();
  }
}
