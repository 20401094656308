import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutFacade } from '@fe-platform/core/state';
import { MapStyle, Marker } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Map } from 'mapbox-gl';
import { Observable, combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MarkerStyles, desktopMapPadding, mobileMapPadding } from './constants';
import { styleMarkers } from './helpers/styleMarkers';
import { styleRadius } from './helpers/styleRadius';
import { MapMovementService } from './map-movement.service';
import { queryToGeoJSON } from './queryToGeoJSON';
import { PinRadiusStyle } from './radiusLayerStyle';
import { queryToSectorGeoJSON } from './helpers/queryToSectorGeoJSON';

@UntilDestroy()
@Component({
  selector: 'keystone-mission-map',
  templateUrl: './mission-map.component.html',
  styleUrls: ['./mission-map.component.css'],
  providers: [MapMovementService],
})
export class MissionMapComponent {
  mapStyles = MapStyle;
  markerStyles = MarkerStyles;
  constructor(
    private geospatialService: GeolocationFacade,
    private router: Router,
    private mapMovementService: MapMovementService,
    private layoutFacade: LayoutFacade
  ) {}
  // GeoJSON is an open standard file format for representing map data.
  // https://docs.mapbox.com/help/glossary/geojson/
  activeQueryGeojson$:
    | Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined>
    | undefined;
  activeQuerySectorGeojson$:
    | Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined>
    | undefined;
  markers$ = combineLatest([
    this.geospatialService.missions.markers$,
    this.geospatialService.missions.activeTarget$,
    this.geospatialService.missions.activeQuery$,
  ]).pipe(styleMarkers);

  cellTowers$ = this.geospatialService.missions.towers$;
  mapStyle$ = this.geospatialService.missions.mapStyle$;
  public map?: Map;
  pinRadiusStyle?: PinRadiusStyle;

  mapLoaded(map: Map): void {
    this.map = map;
    this.layoutFacade.isMobile$
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => {
        this.map?.setPadding(isMobile ? mobileMapPadding : desktopMapPadding);
      });
    this.activeQueryGeojson$ =
      this.geospatialService.missions.activeQuery$.pipe(
        tap((activeQuery) => {
          this.pinRadiusStyle = styleRadius(activeQuery);
        }),
        queryToGeoJSON
      );
    this.activeQuerySectorGeojson$ =
      this.geospatialService.missions.activeQuery$.pipe(queryToSectorGeoJSON);
    this.mapMovementService.initialize(map);
  }
  onClickMarker(marker: Marker): void {
    combineLatest([
      this.geospatialService.missions.activeQuery$,
      this.geospatialService.missions.activeMission$,
    ])
      .pipe(
        take(1),
        tap(([query, mission]) => {
          if (marker.queryId === query?.id) {
            this.router.navigate(['missions', mission.id, marker.targetId]);
          } else {
            this.router.navigate([
              'missions',
              mission.id,
              marker.targetId,
              marker.queryId,
            ]);
          }
        })
      )
      .subscribe();
  }
  public trackByMarker(index: number, marker: Marker): string {
    return marker.queryId + marker.status;
  }
  onSetMapStyle(style: MapStyle): void {
    this.geospatialService.missions.changeMapStyle(style);
  }
}
