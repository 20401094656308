import { OTPChannels, User } from './user.model';

export enum AuthStatus {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
  CONFIGURING_OTP = 'CONFIGURING_OTP',
  VALIDATING_OTP_CONFIGURATION = 'VALIDATING_OTP_CONFIGURATION',
  WAITING_FOR_OTP_INPUT = 'WAITING_FOR_OTP_INPUT',
  CHANGING_PASSWORD = 'CHANGING_PASSWORD',
  CHANGING_PASSWORD_WITH_MANDATORY_OTP = 'CHANGING_PASSWORD_WITH_MANDATORY_OTP',
  UNKNOWN = 'UNKNOWN',
}
export interface AuthPayload {
  username: string;
  password: string;
  otp_code?: string;
  support_email?: string;
}

export interface AuthResponseSuccess {
  current_session_id: string;
  total_sessions: number;
  session_expires_at: string;
  session_email: string;
  token: string;
}

export interface AuthResponseWarning {
  force_2fa_enablement: boolean;
  request_2fa_enablement: boolean;
  warning_password_will_expire_in_days: number;
  request_password_change: boolean;
}

export interface AuthResponseError {
  otp_code_required: boolean;
  otp_channel: OTPChannels;
  otp_recipient: string;
  message: string;
  support_email_required: boolean;
}
export interface AuthResponse<
  T extends
    | AuthResponseSuccess
    | Partial<AuthResponseWarning>
    | Partial<AuthResponseError>
    | unknown = unknown
> {
  result: T;
  error?: AuthResponseError;
}

export interface UserResponse {
  result: User;
  error?: { message: string };
}
