import {
  formatCellECI,
  formatCellId,
  formatCellNetwork,
  formatCountry,
  formatEndDate,
  formatLocation,
  formatLocationCoordinates,
  formatLocationReceivedAtDate,
  formatLocationType,
  formatPhoneModel,
  formatQueryType,
  formatRobotDuration,
  formatRobotExecutedSearch,
  formatRobotScheduledSearch,
  formatRobotType,
} from './formaters';
import { formatStartDate } from './formaters/formatStartDate';

export const QueryFormatters = {
  locationReceivedAtDate: formatLocationReceivedAtDate,
  location: formatLocation,
  locationCoordinates: formatLocationCoordinates,
  locationType: formatLocationType,
  cellNetwork: formatCellNetwork,
  country: formatCountry,
  phoneModel: formatPhoneModel,
  queryType: formatQueryType,
  cellId: formatCellId,
  cellECI: formatCellECI,
} as const;

export const RobotFormatters = {
  startDate: formatStartDate,
  endDate: formatEndDate,
  robotType: formatRobotType,
  duration: formatRobotDuration,
  executedSearch: formatRobotExecutedSearch,
  scheduledSearch: formatRobotScheduledSearch,
} as const;
