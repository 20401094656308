<div class="bottom-sheet-header">
  <div class="title">{{ 'MISSIONS.MISSIONS' | translate }}</div>
</div>

<div *ngrxLet="activeMission$ as activeMission" class="bottom-sheet-body">
  <ng-container *ngrxLet="missions$ as missions">
    <div
      *ngFor="let mission of missions; let i = index"
      class="bottom-sheet-action-item"
      [ngClass]="{ active: activeMission.id === mission.id }"
    >
      <button
        class="btn flex-fill text-left"
        (click)="onSelectMission(mission.id)"
      >
        {{ 'MISSIONS.MISSION' | translate: { value: i + 1 } }} ({{
          mission.targets.length
        }}
        {{
          mission.targets.length | i18nPlural: ('MISSIONS.TARGET' | translate)
        }})
      </button>
      <button class="btn" (click)="onDeleteMission(mission.id)">
        <i class="bi-x-circle text-danger"></i>
      </button>
    </div>
  </ng-container>
</div>

<div class="bottom-sheet-action-item">
  <button
    class="btn d-flex flex-fill text-left"
    (click)="onAddMission()"
    [disabled]="(addButtonEnabled$ | ngrxPush) === false"
  >
    <span class="flex-fill">{{ 'MISSIONS.ADD_NEW_MISSION' | translate }}</span>
    <i class="bi-plus"></i>
  </button>
</div>
