import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DEFAULT_TOAST, Toast } from './toast';
import { ToastType } from './toastType';

@Injectable({ providedIn: 'root' })
export class ToasterService {
  private _toasts = new BehaviorSubject<ReadonlyArray<Toast>>([]);
  public toasts$ = this._toasts.asObservable();

  private addToastCountdown(toast: Toast): void {
    toast.countdown = new BehaviorSubject<number>(toast.delay);
    toast.countdownInterval = setInterval(() => {
      if (toast.delay <= 0) {
        clearInterval(toast.countdownInterval);
      } else {
        toast.countdown?.next((toast.delay -= 1000));
      }
    }, 1000);
  }

  showDefault(toastParams: Partial<Toast>): Toast {
    const toast = {
      ...DEFAULT_TOAST,
      ...toastParams,
    };
    this.addToastCountdown(toast);
    this._toasts.next([...this._toasts.getValue(), toast]);
    return toast;
  }

  showError(toastParams: Partial<Toast>): Toast {
    const toast = {
      ...DEFAULT_TOAST,
      title: ToastType.ERROR,
      ...toastParams,
    };
    this.addToastCountdown(toast);
    this._toasts.next([...this._toasts.getValue(), toast]);
    return toast;
  }

  removeToast(toast: Toast): void {
    clearInterval(toast.countdownInterval);
    this._toasts.next(this._toasts.getValue().filter((t) => t !== toast));
  }

  removeAllToasts(): void {
    this._toasts.next([]);
  }
}
