import { GeolocationQuery, Mission, Target } from '../models';
import { queryInTarget } from './queryInTarget';

export function addQueryToMission(
  query: GeolocationQuery,
  mission: Mission
): Mission {
  const targets = mission.targets.map((t) => {
    if (t.queries.map((q) => q.id).includes(query.id)) {
      return t.replaceQuery(query);
    } else if (queryInTarget(query, t)) {
      return t.appendQuery(query);
    } else {
      return t;
    }
  });

  return mission.setTargets(filterDuplicateTargets(targets));
}
function filterDuplicateTargets(targets: Target[]): Target[] {
  const uniqueIds = new Set();
  return targets.filter((target) => {
    const isDuplicate = uniqueIds.has(target.msisdn + target.imsi);
    uniqueIds.add(target.msisdn + target.imsi);
    return !isDuplicate;
  });
}
