import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomSheet,
  BottomSheetsService,
} from '@fe-platform/shared-ui/keystone';
import { MissionTargetHistoryBottomSheetComponent } from '../mission-target-history-bottom-sheet/mission-target-history-bottom-sheet.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'keystone-mission-target-history-mobile',
  templateUrl: './mission-target-history-mobile.component.html',
  styleUrls: ['./mission-target-history-mobile.component.css'],
})
export class MissionTargetHistoryMobileComponent implements OnInit, OnDestroy {
  bottomSheetRef!: BottomSheet;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bottomSheetsService: BottomSheetsService
  ) {}

  ngOnInit(): void {
    this.bottomSheetRef = this.bottomSheetsService.open(
      MissionTargetHistoryBottomSheetComponent,
      {
        resizable: true,
        backdrop: false,
        bottom: '44px',
        z_index: 11,
        max_height: 'calc(100% - 114px)',
      },
      () => {
        this.router.navigate(['..'], { relativeTo: this.route });
      }
    );
  }

  ngOnDestroy(): void {
    if (this.bottomSheetRef)
      this.bottomSheetsService.destroy(this.bottomSheetRef);
  }
}
