<ng-container *ngrxLet="toasts$ as toasts">
  <ngb-toast
    *ngFor="let toast of toasts"
    [autohide]="!!toast.autohide"
    [delay]="toast.delay"
    (hidden)="removeToast(toast)"
  >
    <ng-container *ngrxLet="isMobile$ as isMobile">
      <keystone-web-toast
        *ngIf="!isMobile"
        [toast]="toast"
      ></keystone-web-toast>
      <keystone-mobile-toast
        *ngIf="isMobile"
        [toast]="toast"
      ></keystone-mobile-toast>
    </ng-container>
  </ngb-toast>
</ng-container>
