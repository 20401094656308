import format from 'date-fns/format';
import { GeolocationQuery, Marker, MarkerStatus, Target } from '../models';
export const queryToMarker =
  (target: Target) =>
  (query: GeolocationQuery): Marker => ({
    status: MarkerStatus.DEFAULT,
    color: target.color,
    queryId: query.id,
    type: 'node',
    targetId: target.id,
    dt: [{ dt1: +format(new Date(query.created_at), 'T') }],
    longLat: [
      query.location?.coordinates[0] ?? NaN,
      query.location?.coordinates[1] ?? NaN,
    ],
    hasExtraInfo: !!query.call_info?.oncall_number,
  });
