import { GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatLocationCoordinates: ColumnDataFormatter<
  GeolocationQuery,
  string
> = (query) => {
  const coordinates = query.location?.coordinates;
  if (coordinates) {
    return `${coordinates[1].toFixed(4)}, ${coordinates[0].toFixed(4)}`;
  }

  return '-';
};
