import { Component } from '@angular/core';
import { MissionId } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import {
  BottomSheet,
  BottomSheetsService,
} from '@fe-platform/shared-ui/keystone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';
import { map } from 'rxjs';
import { MissionsBottomSheetComponent } from '../missions-bottom-sheet/missions-bottom-sheet.component';
import { MissionsNavBarService } from '../missions-nav-bar.service';

@UntilDestroy()
@Component({
  selector: 'keystone-missions-nav-bar-mobile',
  templateUrl: './missions-nav-bar-mobile.component.html',
  styleUrls: ['./missions-nav-bar-mobile.component.css'],
})
export class MissionsNavBarMobileComponent {
  private bottomSheetMissions: BottomSheet | undefined;
  missions$ = this.geolocationService.missions.missions$;
  addButtonEnabled$ = this.missions$.pipe(
    map((missions) => missions.length),
    map((missionLength) => missionLength < environment.missions.maxMissions)
  );
  activeMission$ = this.geolocationService.missions.activeMission$;

  constructor(
    private geolocationService: GeolocationFacade,
    private missionsNavBarService: MissionsNavBarService,
    private bottomSheetService: BottomSheetsService
  ) {}

  public onAddMission(): void {
    this.missionsNavBarService.onAddMission();
  }

  public onSelectMission(missionId: MissionId): void {
    this.missionsNavBarService.onSelectMission(missionId);
  }

  public onDeleteMission(missionId: MissionId): void {
    this.missionsNavBarService.onDeleteMission(missionId);
  }

  public onToggleMissionsList(): void {
    if (!this.bottomSheetMissions) {
      this.bottomSheetMissions = this.bottomSheetService.open(
        MissionsBottomSheetComponent,
        {
          resizable: true,
          backdrop: true,
          bottom: '44px',
          z_index: 11,
          max_height: 'calc(100% - 114px)',
        },
        () => {
          this.bottomSheetMissions = undefined;
        }
      );
    } else this.bottomSheetService.close(this.bottomSheetMissions);
  }
}
