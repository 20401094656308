import { Mission } from '@fe-platform/geolocation/data';
import { isWithinInterval } from 'date-fns';
import { map, Observable } from 'rxjs';

export function filterGeoqueriesByTimerange(mission: Mission): Mission {
  if (!mission.timebarRange) return mission;
  const timerange = mission.timebarRange;
  const targets = mission.targets.map((target) => {
    if (target.visible) {
      return target.setQueries(
        target.queries.filter((q) =>
          isWithinInterval(new Date(q.created_at), {
            start: timerange.dateFrom,
            end: timerange.dateTo,
          })
        )
      );
    }

    return target;
  });
  return mission.setTargets(targets);
}
export function filterGeoqueriesByTimerange$(
  mission$: Observable<Mission>
): Observable<Mission> {
  return mission$.pipe(map(filterGeoqueriesByTimerange));
}
