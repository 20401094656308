import { Pipe, PipeTransform } from '@angular/core';
import { stringToDate } from '@fe-platform/core/date';
import { Robot } from '@fe-platform/geolocation/data';
import { differenceInMinutes } from 'date-fns';

@Pipe({ name: 'formatRobotScheduledSearches' })
export class RobotScheduledSearches implements PipeTransform {
  transform(robot: Robot): number {
    const start = stringToDate(robot.starts_at);
    const end = stringToDate(robot.ends_at);

    return Math.ceil(
      Math.abs(differenceInMinutes(start, end) / robot.interval_minutes)
    );
  }
}
