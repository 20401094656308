<div class="left-timeline">
  <ul class="list-unstyled events mb-0">
    <li
      class="event-list cursor-pointer"
      [class.location-pending]="(query | queryStatusText) === status.LOCATING"
      [class.location-found]="
        (query | queryStatusText) === status.LOCATION_FOUND
      "
      [class.location-lac]="query.cell && query.cell.lac && !query.cell.cell_id"
      [class.location-none]="
        (query | queryStatusText) === status.NO_LOCATION_FOUND
      "
      *ngFor="let query of target?.queries"
      (click)="onNavigateToQuery(query)"
    >
      <div
        class="d-flex align-items-center justify-content-between"
        [ngSwitch]="(query | queryStatusText) === status.LOCATING"
      >
        <div *ngSwitchCase="true">
          <p class="d-flex align-items-center mb-0">
            <i class="uil uil-crosshair lh-1 me-1"></i>
            {{ 'TARGET_CARD.LOCATING' | translate }}
          </p>
        </div>
        <div *ngSwitchCase="false">
          <p class="d-flex align-items-center mb-1">
            <i class="uil uil-history lh-1 me-1"></i>
            <span data-qa="target-card-history-date">{{
              query.created_at | toDate
            }}</span>
            <i
              *ngIf="query.call_info && query.call_info.oncall_number"
              class="bi bi-info-circle ms-1"
            ></i>
          </p>
          <p class="d-flex align-items-center mb-0">
            <i class="bi bi-sim lh-1 me-1"></i>
            {{ query.provider.imsi || 'TARGET_CARD.NO_IMSI_FOUND' | translate }}
          </p>
        </div>
        <i class="bi bi-chevron-right"></i>
      </div>
    </li>
  </ul>
</div>
