import { concatMap, map, Observable } from 'rxjs';
import { Message } from '../models';

export const validateMessage$ = <T>(
  message$: Observable<Message>
): Observable<[string, T] | never> =>
  message$.pipe(
    map((message) => {
      if (validateArrayMessageBody(message.body)) {
        return groupMessages<T>(message.body);
      } else if (validateLegacyMessageBody(message.body)) {
        return [[message.body.subject, message.body.body as T]] as [
          string,
          T
        ][];
      } else if (validateObjectMessageBody(message)) {
        return [[message.type, (<unknown>message.body) as T]] as [string, T][];
      } else {
        throw new Error('received an invalid message from stream manager');
      }
    }),
    concatMap((v) => v)
  );
// It seems that a single websocket message can include multiple events from the same channel.
// We need a piece of code that splits these messages into groups and emmits them sequentially.
// This function does the grouping. The above function with the use of concatMap does the sequential emissions.
function groupMessages<T>(eventBody: unknown[]): [string, T][] {
  if (eventBody.length % 2 !== 0) {
    throw new Error('event body has an odd length.');
  }
  const messageTuples: [string, T][] = [];
  for (let i = 0; i < eventBody.length; i += 2) {
    messageTuples.push([eventBody[i] as string, eventBody[i + 1] as T]);
  }
  return messageTuples;
}

// We get messages from the same websocket with different schemas so we need to validate ALL of them. ¯\_(ツ)_/¯
function validateArrayMessageBody(
  messageBody: unknown
): messageBody is unknown[] {
  return Array.isArray(messageBody) && messageBody.length >= 2;
}
function validateLegacyMessageBody(
  body: unknown
): body is { body: unknown; subject: string } {
  return (
    typeof body === 'object' && !!body && 'body' in body && 'subject' in body
  );
}

function validateObjectMessageBody(message: Message): boolean {
  return (
    'body' in message &&
    !!message.body &&
    typeof message.body === 'object' &&
    'type' in message
  );
}
