<div
  data-qa="target-card-hide"
  class="target-visibility-button"
  [ngStyle]="{
    'background-color': target.visible ? target.color : '#6a7585'
  }"
  [ngbTooltip]="
    target.visible
      ? ('TOOLTIP_CONTENT.HIDE_TARGET' | translate)
      : ('TOOLTIP_CONTENT.SHOW_TARGET' | translate)
  "
  [ngSwitch]="target.visible"
  (click)="toggleTargetVisibility($event)"
>
  <i *ngSwitchCase="true" class="uil uil-eye"></i>
  <i *ngSwitchCase="false" class="uil uil-eye-slash"></i>
</div>
