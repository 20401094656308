import { map, Observable } from 'rxjs';
import { Mission, MSISDNorIMSI, Target } from '../models';
import {
  extractTargetFromMission,
  extractVisibleTargetFromMission,
} from './extractTargetFromMission';

export function extractVisibleTargetsFromMissions(
  msisdnOrImsi: MSISDNorIMSI,
  missions: Mission[]
): Target[] {
  return missions
    .map((m) => extractVisibleTargetFromMission(msisdnOrImsi, m))
    .filter(Boolean) as Target[];
}
export const extractVisibleTargetsFromMissions$ =
  (msisdn: MSISDNorIMSI) =>
  (missions$: Observable<Mission[]>): Observable<Target[]> =>
    missions$.pipe(
      map((missions) => extractVisibleTargetsFromMissions(msisdn, missions))
    );

export function extractTargetsFromMissions(
  msisdnOrImsi: MSISDNorIMSI,
  missions: Mission[]
): Target[] {
  return missions
    .map((m) => extractTargetFromMission(msisdnOrImsi, m))
    .filter(Boolean) as Target[];
}
export const extractTargetsFromMissions$ =
  (msisdn: MSISDNorIMSI) =>
  (missions$: Observable<Mission[]>): Observable<Target[]> =>
    missions$.pipe(
      map((missions) => extractTargetsFromMissions(msisdn, missions))
    );
