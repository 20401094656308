import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { map, Observable, take } from 'rxjs';

@Injectable()
export class ActiveMissionGuard implements CanActivate {
  constructor(
    private geospatialService: GeolocationFacade,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const missionId = route.paramMap.get('missionId') ?? '';
    return this.geospatialService.missions.missions$.pipe(
      take(1),
      map((missions) => {
        if (missions.map((m) => m.id).includes(missionId)) {
          this.geospatialService.missions.setActiveMission(missionId);
          return true;
        } else {
          const mission = missions[0];
          this.router.navigate(['missions', mission.id]);
          return false;
        }
      })
    );
  }
}
