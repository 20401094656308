import { Pipe, PipeTransform } from '@angular/core';
import {
  GeolocationQuery,
  queryIsPending,
  QueryStatusContents,
} from '@fe-platform/geolocation/data';
@Pipe({ name: 'queryStatusText' })
export class QueryStatusTextPipe implements PipeTransform {
  transform(query: GeolocationQuery): string {
    if (queryIsPending(query)) {
      return QueryStatusContents.LOCATING;
    } else {
      return query.location
        ? QueryStatusContents.LOCATION_FOUND
        : QueryStatusContents.NO_LOCATION_FOUND;
    }
  }
}
