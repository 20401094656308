import {
  CellTowerInfo,
  Mission,
  MissionId,
  QueryId,
  TargetId,
} from '@fe-platform/geolocation/data';

export interface MissionsState {
  activeQueryId: QueryId | null;
  activeTargetId: TargetId | null;
  activeMissionId: MissionId;
  missions: Mission[];
  expandedTargets: TargetId[];
  cellTowers: CellTowerInfo[] | null;
}
export const createInitialState = (): MissionsState => {
  const emptyMission = Mission.createEmpty();
  return {
    activeMissionId: emptyMission.id,
    activeTargetId: null,
    activeQueryId: null,
    missions: [emptyMission],
    expandedTargets: [],
    cellTowers: null,
  };
};
export const MissionsStateName = 'Missions';
