import { Mission } from '@fe-platform/geolocation/data';

export const numberIsInActiveMission = (
  activeMission: Mission,
  msisdnOrImsi: string
): boolean => {
  const targetIndex = activeMission.targets.findIndex(
    (t) => t.msisdn === msisdnOrImsi || t.imsi === msisdnOrImsi
  );
  return targetIndex >= 0;
};
