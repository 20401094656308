import { Inject, Injectable } from '@angular/core';
import { GEO_URL } from '@fe-platform/core/config';
import { ApiService, Result } from '@fe-platform/core/http';
import { Observable } from 'rxjs';
import {
  MarkAsReadResponse,
  NotificationResponse,
} from './models/notificationResponse.model';

@Injectable({ providedIn: 'root' })
export class NotificationsDataService {
  constructor(
    @Inject(GEO_URL) private geoUrl: string,
    private apiService: ApiService
  ) {}

  public getNotifications(): Observable<NotificationResponse> {
    return this.apiService.get<NotificationResponse>(
      `${this.geoUrl}/notifications`
    );
  }

  public deleteNotifications(): Observable<NotificationResponse> {
    return this.apiService.delete<NotificationResponse>(
      `${this.geoUrl}/notifications`
    );
  }

  public markNotificationsAsRead(
    ids?: string[]
  ): Observable<Result<MarkAsReadResponse>> {
    return this.apiService.put(`${this.geoUrl}/notifications`, ids);
  }
}
