import { Injectable } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BroadcastService {
  private broadcastChannel = new BroadcastChannel('session');
  private onMessage$ = new Subject<string>();

  constructor() {
    this.broadcastChannel.onmessage = (message) =>
      this.onMessage$.next(message.data);
  }

  publish(msg: string): void {
    this.broadcastChannel.postMessage(msg);
  }

  listen(msg: string): Observable<string> {
    return this.onMessage$.pipe(filter((message) => message === msg));
  }

  close(): void {
    this.broadcastChannel.close();
  }
}
