import { Component } from '@angular/core';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';
import { map } from 'rxjs';
import { MissionsNavBarService } from '../missions-nav-bar.service';

@UntilDestroy()
@Component({
  selector: 'keystone-missions-nav-bar-web',
  templateUrl: './missions-nav-bar-web.component.html',
  styleUrls: ['./missions-nav-bar-web.component.css'],
})
export class MissionsNavBarWebComponent {
  missions$ = this.geolocationService.missions.missions$;
  addButtonEnabled$ = this.missions$.pipe(
    map((missions) => missions.length),
    map((missionLength) => missionLength < environment.missions.maxMissions)
  );
  activeMission$ = this.geolocationService.missions.activeMission$;

  constructor(
    private geolocationService: GeolocationFacade,
    private missionsNavBarService: MissionsNavBarService
  ) {}

  public onAddMission(): void {
    this.missionsNavBarService.onAddMission();
  }
}
