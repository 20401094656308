import { GeolocationQuery } from './geolocation-query';
import { Pagination } from './pagination';
import { ResponsePaginationLinks } from './response-pagination-links';

export interface LatestGeolocationQueryResponse {
  links: ResponsePaginationLinks;
  meta: Pagination;
  result: GeolocationQuery[];
}

export enum SortBy {
  Desc = 'DESC',
  Asc = 'ASC',
}
