<div id="missions-nav-bar-desktop">
  <div class="card p-2" *ngrxLet="activeMission$ as activeMission">
    <div class="d-flex" *ngrxLet="missions$ as missions">
      <keystone-missions-nav-bar-selector
        [missions]="missions"
        [activeMission]="activeMission"
      ></keystone-missions-nav-bar-selector>
      <button
        data-qa="mission-add-new"
        class="btn btn-sm mx-2"
        (click)="onAddMission()"
        [disabled]="(addButtonEnabled$ | ngrxPush) === false"
      >
        <i class="bi-plus-lg me-1"></i>
        {{ 'MISSIONS.ADD_NEW' | translate }}
      </button>
    </div>
  </div>
</div>
