import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { TimeBarItem } from '@trg-ui/link-analysis';

interface TimeBarQueryElement {
  query: GeolocationQuery;
  color: string;
}

const convertGeoqueryToTimelineItem = (
  timebarElement: TimeBarQueryElement
): TimeBarItem => ({
  id: timebarElement.query.id,
  dt: new Date(timebarElement.query.created_at),
  d: timebarElement,
});

export const convertGeoqueryToTimelineItems = (
  queries: TimeBarQueryElement[]
): TimeBarItem[] => queries.map(convertGeoqueryToTimelineItem);
