import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Target } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { take, tap } from 'rxjs';

@Component({
  selector: 'keystone-mission-target-color-sidebar',
  templateUrl: './mission-target-color-sidebar.component.html',
  styleUrls: ['./mission-target-color-sidebar.component.css'],
})
export class MissionTargetColorSidebarComponent {
  @Input() public target!: Target;
  @Input() public missionId!: string;

  constructor(
    private geolocationService: GeolocationFacade,
    private router: Router
  ) {}
  toggleTargetVisibility(event: Event): void {
    event.stopPropagation();
    const shouldHide = this.target.visible;
    this.geolocationService.missions.activeTarget$
      .pipe(
        take(1),
        tap((target) => {
          if (target?.id === this.target.id && shouldHide) {
            this.navigateToMission();
          }
        }),
        tap(() => {
          this.geolocationService.missions.toggleTargetVisibility(
            this.missionId,
            this.target.id,
            !this.target.visible
          );
        })
      )
      .subscribe();
  }

  private navigateToMission(): void {
    this.geolocationService.missions.activeMission$
      .pipe(
        take(1),
        tap((mission) => this.router.navigate(['missions', mission.id]))
      )
      .subscribe();
  }
}
