import { Provider } from '@angular/core';
import {
  FAST_API_URL,
  FETCH_RETRIES,
  GEO_URL,
  PROXY_API_URL,
  RECAPTCHA_KEY,
  REPORTS_URL,
  REPORTS_V2_URL,
  WEBSOCKETS_URL,
} from '@fe-platform/core/config';
import { environment } from '@fe-platform/environment';

export const ConfigProviders: Provider[] = [
  {
    provide: GEO_URL,
    useValue: environment.geoUrl,
  },
  {
    provide: REPORTS_URL,
    useValue: environment.reportsUrl,
  },
  {
    provide: REPORTS_V2_URL,
    useValue: environment.reportsV2Url,
  },
  {
    provide: RECAPTCHA_KEY,
    useValue: environment.recaptchaKey,
  },
  {
    provide: WEBSOCKETS_URL,
    useValue: environment.wsUrl,
  },
  {
    provide: FAST_API_URL,
    useValue: environment.fastApiUrl,
  },
  {
    provide: FETCH_RETRIES,
    useValue: environment.fetchRetries,
  },
  {
    provide: PROXY_API_URL,
    useValue: environment.proxyAPIUri,
  },
];
