export interface RobotListFilters {
  page: number;
  limit: number;
  canceled: boolean;
}
export const initialRobotListFilters: RobotListFilters = {
  page: 1,
  limit: 10,
  canceled: false,
};
