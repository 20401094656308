import { Component, Input } from '@angular/core';
import { CellTowerInfo } from '@fe-platform/geolocation/data';

@Component({
  selector: 'keystone-cell-tower-pin',
  templateUrl: './cell-tower-pin.component.html',
  styles: [],
})
export class CellTowerPinComponent {
  @Input() cellTower!: CellTowerInfo;
}
