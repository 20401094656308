import {
  GeolocationQuery,
  GeolocationQueryParams,
  GeolocationReportsParams,
  InitialGeolocationQueryListParams,
  Pagination,
  ReportType,
} from '@fe-platform/geolocation/data';
import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { HunterGeoQuery as KeystoneGeoQuery } from '@report-service-ts/data-models';

export interface GeolocationQueriesState {
  queries: GeolocationQuery[];
  filters: GeolocationQueryParams;
  pagination: Pagination | null;
  loading: boolean;
}

export const initialState: GeolocationQueriesState = {
  queries: [],
  filters: InitialGeolocationQueryListParams,
  pagination: null,
  loading: false,
};

export const GeolocationQueryAction = createActionGroup({
  source: 'GeolocationQueries',
  events: {
    'set Loading': props<{ loading: boolean }>(),
    'set Pagination': props<{ pagination: Pagination }>(),
    'set Queries': props<{ queries: GeolocationQuery[] }>(),
    'set Filters': props<{ filters: Partial<GeolocationQueryParams> }>(),
    'download Report': props<{
      params: Partial<GeolocationReportsParams>;
      reportType: ReportType;
    }>(),
    'download PDF Report': props<KeystoneGeoQuery>(),
    reset: emptyProps(),
    refresh: emptyProps(),
  },
});
export const GeolocationQueriesStateName = 'GeolocationQueries';
export const GeolocationQueriesFeature = createFeature({
  name: GeolocationQueriesStateName,
  reducer: createReducer(
    initialState,
    on(GeolocationQueryAction.setQueries, (state, { queries }) => ({
      ...state,
      queries,
    })),
    on(GeolocationQueryAction.setFilters, (state, { filters }) => ({
      ...state,
      filters: { ...state.filters, ...filters },
    })),
    on(GeolocationQueryAction.reset, (state) => ({
      ...state,
      filters: InitialGeolocationQueryListParams,
      queries: [],
    })),
    on(GeolocationQueryAction.refresh, (state) => ({
      ...state,
    })),
    on(GeolocationQueryAction.setPagination, (state, action) => ({
      ...state,
      pagination: action.pagination,
    })),
    on(GeolocationQueryAction.setLoading, (state, action) => ({
      ...state,
      loading: action.loading,
    }))
  ),
});
export const {
  name,
  reducer,
  selectGeolocationQueriesState,
  selectFilters,
  selectQueries,
  selectPagination,
  selectLoading,
} = GeolocationQueriesFeature;
