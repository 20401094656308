import {
  NotificationEvent,
  UpdateGeolocationEventName,
  UpdateLedgerDetailsEvent,
  UpdateTaskEventName,
} from '@fe-platform/geolocation/state';
import { EventChannel } from '@trg-commons/gio-data-models-ts';

export interface InstantNotificationSources {
  channel: EventChannel;
  eventName: string;
}
export const sources: InstantNotificationSources[] = [
  {
    channel: EventChannel.GeolocationQueries,
    eventName: UpdateGeolocationEventName,
  },
  {
    channel: EventChannel.Ledger,
    eventName: UpdateLedgerDetailsEvent,
  },
  {
    channel: EventChannel.GeolocationQueries,
    eventName: UpdateTaskEventName,
  },
  {
    channel: EventChannel.GeofrontNotification,
    eventName: NotificationEvent,
  },
];
