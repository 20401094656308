import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, AuthStoreName } from './state';
export const selectAuthFeature =
  createFeatureSelector<AuthState>(AuthStoreName);
export const selectCurrentUser = createSelector(
  selectAuthFeature,
  (state) => state.currentUser
);
export const selectStatus = createSelector(
  selectAuthFeature,
  (state) => state.status
);
export const selectToken = createSelector(
  selectAuthFeature,
  (state: AuthState) => state.token
);
export const selectErrorMessage = createSelector(
  selectAuthFeature,
  (state) => state.errorMessage
);
export const selectOtpConfiguration = createSelector(
  selectAuthFeature,
  (state) => ({
    channel: state.otpChannel,
    recipient: state.otpChannelRecipient,
    hashedRecipient: state.otpHashedRecipient,
  })
);
export const selectUserRole = createSelector(
  selectAuthFeature,
  (state) => state.currentUser?.roles[0]
);
