import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private modalService: NgbModal) {}

  openConfirmDialog(
    title: string,
    content: string,
    buttonName: string,
    buttonCancel: string
  ): NgbModalRef {
    const confirmDialog = this.modalService.open(DialogComponent, {
      centered: true,
    });
    confirmDialog.componentInstance.title = title;
    confirmDialog.componentInstance.content = content;
    confirmDialog.componentInstance.buttonName = buttonName;
    confirmDialog.componentInstance.buttonCancel = buttonCancel;
    return confirmDialog;
  }
  openInfoDialog(
    title: string,
    content: string,
    buttonCancel: string
  ): NgbModalRef {
    const infoDialog = this.modalService.open(DialogComponent, {
      centered: true,
    });
    infoDialog.componentInstance.title = title;
    infoDialog.componentInstance.content = content;
    infoDialog.componentInstance.buttonCancel = buttonCancel;
    return infoDialog;
  }
}
