<div class="modal-header">
  <h4
    class="modal-title text-center text-white ellipsis w-100"
    *ngrxLet="countdown$ as countdown"
  >
    {{ 'IDLE_TIMER.SESSION_WILL_EXPIRE' | translate }} {{ countdown }}
    {{ countdown | i18nPlural: ('SECONDS' | translate) }}
  </h4>
  <button type="button" class="btn-close" (click)="onContinue()"></button>
</div>

<div class="modal-body text-center">
  <p class="mb-1">
    {{ 'IDLE_TIMER.SESSION_WILL_EXPIRE_SOON' | translate }}
  </p>
  <p>
    {{ 'IDLE_TIMER.CLICK' | translate }} <b>{{ 'CONTINUE' | translate }}</b>
    {{ 'IDLE_TIMER.KEEP_SEESION_ACTIVE' | translate }}
  </p>
  <button type="button" class="btn btn-primary w-100" (click)="onContinue()">
    {{ 'CONTINUE' | translate }}
  </button>
</div>
