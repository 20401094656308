import { trigger, animate, transition, style } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({
      opacity: '0%',
    }),
    animate(
      '200ms ease-in-out',
      style({
        opacity: '100%',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '200ms ease-in-out',
      style({
        opacity: '0%',
      })
    ),
  ]),
]);

export const slideInOutBottom = trigger('slideInOutBottom', [
  transition(':enter', [
    style({
      transform: 'translateY(100%)',
      opacity: '0%',
    }),
    animate(
      '200ms ease-in-out',
      style({
        transform: 'translateY(0%)',
        opacity: '100%',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '200ms ease-in-out',
      style({
        transform: 'translateY(100%)',
        opacity: '0%',
      })
    ),
  ]),
]);
