import { Injectable } from '@angular/core';
import { AuthFacade } from '@fe-platform/auth/state';
import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

@UntilDestroy()
@Injectable()
export class LatestQueriesService {
  private currentUser$ = this.authFacade.currentUser$;

  private _latestQueries$ = new BehaviorSubject<GeolocationQuery[]>([]);
  public latestQueries$ = this._latestQueries$.asObservable();
  private _loading$ = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading$.asObservable();
  private _targetForSearch$ = new BehaviorSubject<string>('');
  public targetForSearch$ = this._targetForSearch$.asObservable();

  public vm$ = combineLatest([
    this.latestQueries$,
    this.loading$,
    this.targetForSearch$,
  ]).pipe(
    map(([latestQueries, loading, targetForSearch]) => ({
      latestQueries,
      loading,
      targetForSearch,
    }))
  );
  constructor(
    private geolocationService: GeolocationFacade,
    private authFacade: AuthFacade
  ) {
    this.fetchListOfLatestQueries();
  }

  private fetchListOfLatestQueries(): void {
    this._loading$.next(true);
    this.currentUser$
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
        take(1),
        switchMap((currentUser) => {
          return this.geolocationService.missions
            .fetchLatestQueries(currentUser?.username)
            .pipe(
              tap((queriesResponse) => {
                this._latestQueries$.next(queriesResponse.result);
              })
            );
        }),
        catchError((err) => {
          console.error('Failed to fetch latest queries', err);
          this._latestQueries$.next([]);
          return of([]);
        })
      )
      .subscribe(() => this._loading$.next(false));
  }

  setLatestTargetForSearch(target?: string): void {
    if (target) this._targetForSearch$.next(target);
  }
}
