import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'keystone-show-password-button',
  templateUrl: './show-password-button.component.html',
  styles: [''],
})
export class ShowPasswordButtonComponent {
  showPassword = false;
  @Output() changeShowPassword: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  onTogglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    this.changeShowPassword.emit(this.showPassword);
  }
}
