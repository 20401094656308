import { LngLat, LngLatBounds } from '@fe-platform/geolocation/data';

export function calculateMapBoundsFromCoordinates(
  coordinates: LngLat[] | number[][]
): LngLatBounds {
  if (coordinates.some((c) => c.length !== 2))
    throw new Error('Invalid coordinates length');
  const longitudes = coordinates.map((l) => l[0]);
  const latitudes = coordinates.map((l) => l[1]);
  const SouthWesternCorner: LngLat = [
    Math.min(...longitudes),
    Math.min(...latitudes),
  ];
  const NorthEastCorner: LngLat = [
    Math.max(...longitudes),
    Math.max(...latitudes),
  ];
  return [SouthWesternCorner, NorthEastCorner];
}
