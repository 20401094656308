<nav class="container-fluid d-flex align-items-center justify-content-between">
  <ul
    class="list-unstyled topnav-menu topnav-menu-left m-0 d-flex align-items-center"
  >
    <li>
      <button
        id="btn-sidenav-toggle"
        (click)="onToggleMobileMenu($event)"
        class="btn d-lg-none me-2"
      >
        <i data-feather="menu" keystoneFeatherIcon></i>
      </button>
    </li>
    <li class="d-flex align-items-center">
      <a [routerLink]="'/'">
        <keystone-logo></keystone-logo>
      </a>
      <keystone-page-title
        *ngIf="!(isMobile$ | ngrxPush)"
      ></keystone-page-title>
    </li>
  </ul>

  <ul class="list-unstyled topnav-menu d-flex float-end mb-0">
    <li
      *ngIf="isOffline$ | ngrxPush"
      class="ms-2 d-flex align-items-center red"
      ngbTooltip="Connection issues detected. Trying to reconnect..."
      triggers="manual"
      #t="ngbTooltip"
      (mouseenter)="t.open()"
      (mouseleave)="t.close()"
      (click)="t.open()"
    >
      <i data-feather="info" keystoneFeatherIcon></i>
    </li>
    <li class="ms-2" data-qa="topbar-credit-meter">
      <keystone-view-available-credits-widget></keystone-view-available-credits-widget>
    </li>
    <li class="ms-2" data-qa="topbar-notification-bell">
      <keystone-notification-widget></keystone-notification-widget>
    </li>
    <ng-container *ngIf="!(isMobile$ | ngrxPush)">
      <li ngbDropdown class="topbar-dropdown" data-qa="topbar-profile">
        <ng-container *ngrxLet="currentUser$ as user">
          <div
            ngbDropdownToggle
            [ngbTooltip]="'TOOLTIP_CONTENT.USER_SETTINGS' | translate"
            class="ms-2 dropdown-toggle user-alias cursor-pointer d-flex align-items-center justify-content-center"
            data-qa="topbar-profile-btn"
          >
            {{ user.username.charAt(0) || '-' }}
          </div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem class="dropdown-header dropdown-title">
              <h6 class="text-overflow m-0" data-qa="topbar-profile-username">
                {{ user.username }}
              </h6>
            </div>
            <div class="dropdown-divider"></div>
            <a
              ngbDropdownItem
              class="cursor-pointer"
              data-qa="sidebar-change-password"
              (click)="onShowChangePassword()"
            >
              {{ 'PASSWORD.CHANGE_PASSWORD' | translate }}
            </a>
            <div class="dropdown-divider"></div>
            <div class="pe-2 ps-2">
              <keystone-language-selection></keystone-language-selection>
            </div>
            <div class="dropdown-divider"></div>
            <a
              ngbDropdownItem
              class="cursor-pointer"
              data-qa="sidebar-signout"
              (click)="onSignOut()"
            >
              {{ 'SIGN_OUT' | translate }}
            </a>
          </div>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</nav>
