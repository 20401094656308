import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthFacade } from '@fe-platform/auth/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, tap } from 'rxjs/operators';
import { createResetPasswordForm } from './helpers';
enum ShowPasswordLabels {
  current = 'current',
  confirmCurrent = 'confirmCurrent',
}

@UntilDestroy()
@Component({
  selector: 'keystone-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.css'],
})
export class PasswordFormComponent implements OnInit {
  showPassword: Record<string, boolean> = {
    current: false,
    confirmCurrent: false,
  };

  showPasswordLabels = ShowPasswordLabels;

  resetPasswordForm: UntypedFormGroup = createResetPasswordForm(this.auth);
  @Output() password$: EventEmitter<string> = new EventEmitter();
  @Output() formIsValid$ = this.resetPasswordForm.statusChanges.pipe(
    map((status) => status === 'VALID')
  );

  passwordErrors$ = this.resetPasswordForm.statusChanges.pipe(
    map(() => this.resetPasswordForm.get('password')?.errors || null)
  );

  constructor(private auth: AuthFacade) {}

  ngOnInit(): void {
    this.resetPasswordForm.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(({ password }) => this.password$.emit(password))
      )
      .subscribe();
  }

  showPasswordChanged(
    showPasswordValue: boolean,
    passwordLabel: ShowPasswordLabels
  ): void {
    passwordLabel === this.showPasswordLabels.current
      ? (this.showPassword['current'] = showPasswordValue)
      : (this.showPassword['confirmCurrent'] = showPasswordValue);
  }
}
