import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthDataService } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import { createChangePasswordForm } from '../password-form/helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToasterService } from '@fe-platform/shared-ui/keystone';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'keystone-password-change-form',
  templateUrl: './password-change-form.component.html',
})
export class PasswordChangeFormComponent {
  changePasswordForm: UntypedFormGroup = createChangePasswordForm(this.auth);
  currentPasswordControl = this.changePasswordForm.get('passwordCurrent');
  newPasswordControl = this.changePasswordForm.get('passwordNew');
  confirmNewPasswordControl = this.changePasswordForm.get('passwordConfirmNew');
  loading = false;
  hidePassword: Record<string, boolean> = {
    current: true,
    new: true,
    confirm_new: true,
  };
  passwordCurrentErrors$ = this.changePasswordForm.statusChanges.pipe(
    map(() =>
      this.currentPasswordControl?.pristine
        ? null
        : this.currentPasswordControl?.errors
    )
  );
  passwordNewErrors$ = this.changePasswordForm.statusChanges.pipe(
    map(() =>
      this.newPasswordControl?.pristine ? null : this.newPasswordControl?.errors
    )
  );
  passwordConfirmNewErrors$ = this.changePasswordForm.statusChanges.pipe(
    map(() =>
      this.confirmNewPasswordControl?.pristine
        ? null
        : this.confirmNewPasswordControl?.errors
    )
  );

  constructor(
    private auth: AuthFacade,
    private authDataService: AuthDataService,
    public activeModal: NgbActiveModal,
    private toaster: ToasterService,
    private translationService: TranslateService
  ) {}

  onTogglePasswordVisibility(input: string): void {
    this.hidePassword[input] = !this.hidePassword[input];
  }

  onSubmit(): void {
    if (this.changePasswordForm.invalid || this.loading) return;

    const passwordCurrent = this.changePasswordForm.value.passwordCurrent;
    const passwordNew = this.changePasswordForm.value.passwordNew;
    const passwordConfirmNew = this.changePasswordForm.value.passwordConfirmNew;

    if (passwordNew !== passwordConfirmNew) {
      this.toaster.showError({
        content: this.translationService.instant(
          'PASSWORD.PASSWORDS_ARE_NOT_THE_SAME'
        ),
      });
      return;
    }

    this.loading = true;
    this.authDataService
      .resetPassword(passwordCurrent, passwordNew)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.loading = false;
          this.toaster.showDefault({
            title: this.translationService.instant('PASSWORD.PASSWORD_CHANGED'),
            content: this.translationService.instant(
              'PASSWORD.PASSWORD_CHANGED_SUCCESSFULLY'
            ),
          });
          this.activeModal.close();
        },
        error: (e) => {
          this.loading = false;
          const msg = e.error.error.message
            ? this.translationService.instant(
                `PASSWORD.${e.error.error.message}`
              )
            : this.translationService.instant(
                'PASSWORD.UNKNOWN_ERROR_OCCURRED'
              );
          this.toaster.showError({ content: msg });
        },
      });
  }
}
