import { Injectable } from '@angular/core';
import { GeolocationQueryDataService } from '@fe-platform/geolocation/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom } from 'rxjs';
import { MissionActions } from './actions';
import { selectActiveQuery } from './selectors';

@Injectable()
export class MissionEffects {
  constructor(
    private geolocationQueriesDataService: GeolocationQueryDataService,
    private actions$: Actions,
    private store: Store
  ) {}
  checkForCellTowers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionActions.checkForCellTowers),
      switchMap(({ query }) =>
        this.geolocationQueriesDataService
          .checkForCellTowers(query)
          .pipe(
            map((hasCellTowers) =>
              hasCellTowers
                ? MissionActions.retrieveCellTowers({ query })
                : MissionActions.updateCellTowers({ towers: null })
            )
          )
      )
    )
  );
  retriveCellTowers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionActions.retrieveCellTowers),
      switchMap(({ query }) =>
        this.geolocationQueriesDataService.retrieveCellTowers(query).pipe(
          withLatestFrom(this.store.select(selectActiveQuery)),
          filter(([, activeQuery]) => activeQuery?.id === query.id),
          map(([cellTowers]) => {
            if (!cellTowers)
              return MissionActions.updateCellTowers({ towers: [] });

            const t = cellTowers.result
              .filter((twr) => twr.accuracy < query.accuracy_meters)
              .sort((a, b) => (a.distance > b.distance ? 1 : -1))
              .slice(0, 20);

            return MissionActions.updateCellTowers({ towers: t });
          })
        )
      )
    )
  );
}
