export interface PinRadiusStyle {
  'fill-color': string;
  'fill-outline-color'?: string;
  'fill-opacity': number;
}

export const layerPaint: PinRadiusStyle = {
  'fill-color': 'rgb(179, 225, 247)',
  'fill-outline-color': '#1a73e8',
  'fill-opacity': 0.2,
};

export const layerPaintLAC: PinRadiusStyle = {
  'fill-color': 'rgb(235, 126, 48)',
  'fill-opacity': 0.16,
};
