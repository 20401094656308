import { Component } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { LatestQueriesService } from './latest-queries.service';
import { GeoquerySearchBoxService } from './geoquery-search-box.service';
@UntilDestroy()
@Component({
  selector: 'keystone-geoquery-search-box',
  templateUrl: './geoquery-search-box.component.html',
  styleUrls: ['./geoquery-search-box.component.css'],
  providers: [GeoquerySearchBoxService, LatestQueriesService],
})
export class GeoquerySearchBoxComponent {
  constructor(private layoutFacade: LayoutFacade) {}

  isMobile$: Observable<boolean> = this.layoutFacade.isMobile$;
}
