import { Injectable } from '@angular/core';
import {
  GeolocationQuery,
  GeolocationQueryDataService,
  Target,
  targetInQueries,
} from '@fe-platform/geolocation/data';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { PendingQueriesActions, selectPendingQueries } from './reducer';

@Injectable({ providedIn: 'root' })
export class PendingQueriesFacade {
  constructor(
    private store: Store,
    private geolocationQueryDataService: GeolocationQueryDataService
  ) {}
  pendingQueries$ = this.store.select(selectPendingQueries);
  public targetHasPendingQuery$(target: Target): Observable<boolean> {
    return this.pendingQueries$.pipe(map(targetInQueries(target)));
  }
  public fetchPendingGeolocationQueries(): Observable<GeolocationQuery[]> {
    return this.geolocationQueryDataService
      .fetchPendingGeolocationQueries()
      .pipe(map(({ result }) => result));
  }
  public setPendingQueries(queries: GeolocationQuery[]): void {
    this.store.dispatch(PendingQueriesActions.addPendingQueries({ queries }));
  }
  public clearPendingQueries(): void {
    this.store.dispatch(PendingQueriesActions.clearPendingQueries());
  }
}
