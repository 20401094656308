import { Mission } from '../models';

export function extractLastEmptyMission(
  missions: Mission[]
): Mission | undefined {
  return missions
    .slice()
    .reverse()
    .find((m) => !m.targets.length);
}
