<ng-container *ngrxLet="activeTarget$ as activeTarget">
  <div class="bottom-sheet-header">
    <div class="title">
      <span
        class="icon d-inline-flex align-items-center justify-content-center me-1"
      >
        <i class="uil uil-history lh-1"></i>
      </span>
      <span
        >{{ 'TARGET_CARD.HISTORY_FOR' | translate }}
        {{ activeTarget?.msisdn || activeTarget?.imsi }}</span
      >
    </div>
    <button
      data-qa="back-btn"
      type="button"
      class="btn-close"
      (click)="navigateBack()"
    ></button>
  </div>
  <div class="bottom-sheet-body ps-2">
    <keystone-mission-target-history-list
      *ngIf="activeTarget"
      [target]="activeTarget"
    ></keystone-mission-target-history-list>
  </div>
</ng-container>
