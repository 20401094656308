import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { GeolocationFacade } from '@fe-platform/geolocation/state';

@Injectable()
export class ActiveTargetGuard implements CanActivate {
  constructor(private geospatialService: GeolocationFacade) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const targetId = route.paramMap.get('targetId');
    if (targetId) {
      this.geospatialService.missions.setActiveTarget(targetId);
      return true;
    } else {
      this.geospatialService.missions.clearActiveTarget();
      return false;
    }
  }
}
