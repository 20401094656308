import { UntypedFormGroup } from '@angular/forms';
import { OTPChannels, OtpConfiguration } from '@fe-platform/auth/data';
interface OtpFormValue {
  phone: string;
  email: string;
  otpChannel: OTPChannels;
}
export function extractValueFromOtpForm(
  otpForm: UntypedFormGroup
): OtpConfiguration {
  const otpValue: OtpFormValue = otpForm.value;
  const channel = otpValue.otpChannel;
  switch (channel) {
    case OTPChannels.App:
      return {
        channel,
        recipient: '',
      };
    case OTPChannels.Email:
      return {
        channel,
        recipient: otpValue.email,
      };
    case OTPChannels.OtpPhone:
      return {
        channel,
        recipient: otpValue.phone,
      };
    default:
      throw new Error(
        'Unable to extract OTP value from from. Otp channel not valid'
      );
  }
}
