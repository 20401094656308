import { Component, Input } from '@angular/core';
import { Mission, MissionId } from '@fe-platform/geolocation/data';
import { MissionsNavBarService } from '../../missions-nav-bar.service';

@Component({
  selector: 'keystone-missions-nav-bar-selector',
  templateUrl: './missions-nav-bar-selector.component.html',
  styleUrls: ['./missions-nav-bar-selector.component.scss'],
})
export class MissionsNavBarSelectorComponent {
  @Input() missions!: Mission[];
  @Input() activeMission!: Mission;

  constructor(private missionsNavBarService: MissionsNavBarService) {}

  public onSelectMission(missionId: MissionId): void {
    this.missionsNavBarService.onSelectMission(missionId);
  }

  public onDeleteMission(missionId: MissionId): void {
    this.missionsNavBarService.onDeleteMission(missionId);
  }
}
