import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatus } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as feather from 'feather-icons';
import { Observable, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'keystone-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  showPassword = false;
  loginForm: UntypedFormGroup = new UntypedFormGroup({});
  errorMessage$: Observable<string> = this.auth.errorMessage$;
  formStatus$ = this.loginForm.statusChanges.pipe(
    startWith(this.loginForm.status)
  );
  constructor(
    private auth: AuthFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
    });
    this.auth.status$.pipe(untilDestroyed(this)).subscribe((status) => {
      switch (status) {
        case AuthStatus.AUTHENTICATED:
          this.router.navigate(['']);
          break;
        case AuthStatus.CONFIGURING_OTP:
          this.saveTempPasswordAndNavigateToRoute('otp-config');
          break;
        case AuthStatus.WAITING_FOR_OTP_INPUT:
          this.saveTempPasswordAndNavigateToRoute('otp');
          break;
        case AuthStatus.CHANGING_PASSWORD:
        case AuthStatus.CHANGING_PASSWORD_WITH_MANDATORY_OTP:
          this.saveTempPasswordAndNavigateToRoute('reset-password');
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    feather.replace();
  }

  showPasswordChanged(showPasswordValue: boolean) {
    this.showPassword = showPasswordValue;
  }

  logIn(): void {
    this.auth.login(this.loginForm.value);
  }
  private tempPasswordSaveToSessionStorage(): void {
    sessionStorage.setItem('password', this.loginForm.value.password);
    sessionStorage.setItem('username', this.loginForm.value.username);
  }

  private saveTempPasswordAndNavigateToRoute(route: string): void {
    this.tempPasswordSaveToSessionStorage();
    this.router.navigate([route], { relativeTo: this.route });
  }
}
