import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutFacade } from './layout';
import { NetworkLostStateManager } from './network-lost-state-manager.service';
import { NetworkStatusFacade } from './network-status';

@NgModule({
  imports: [CommonModule],
  providers: [NetworkStatusFacade, LayoutFacade, NetworkLostStateManager],
  exports: [],
})
export class CoreStateModule {}
