import { GeolocationQuery, Target } from '../models';

export function queryInTarget(
  query: GeolocationQuery,
  target: Target
): boolean {
  return (
    target.msisdn === query.provider.telno ||
    target.imsi === query.provider.imsi
  );
}
export const targetInQueries =
  (target: Target) =>
  (queries: GeolocationQuery[]): boolean =>
    queries.some((q) => queryInTarget(q, target));
