import { Injectable } from '@angular/core';

import {
  extractLastEmptyMission,
  GeolocationQuery,
  MSISDNorIMSI,
  Target,
} from '@fe-platform/geolocation/data';

import { HunterGeoQuery as KeystoneGeoQuery } from '@report-service-ts/data-models';

import { StreamManager } from '@fe-platform/stream-manager';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, switchMap, take, tap } from 'rxjs';
import { GeolocationQueryAction } from './geolocation-queries';
import { QueriesTableFacade } from './geolocation-queries/queries-table.facade';
import {
  subscribeToIncomingGeolocationQueries,
  subscribeToIncomingScheduledQueries,
} from './helpers';
import { MissionsFacade } from './missions/facade';
import { PendingQueriesFacade } from './pending-queries/facade';
import { RobotsFacade } from './robots/facade';

@Injectable({ providedIn: 'root' })
export class GeolocationFacade {
  constructor(
    private store: Store,
    private streamManager: StreamManager,
    public pendingQueries: PendingQueriesFacade,
    public robots: RobotsFacade,
    public queriesTable: QueriesTableFacade,
    public missions: MissionsFacade
  ) {
    subscribeToIncomingGeolocationQueries(this.store, this.streamManager);
    subscribeToIncomingScheduledQueries(this.store, this.streamManager);
  }
  public addTelnosToMission(
    telnos: MSISDNorIMSI[],
    maxMissions: number
  ): Observable<{ queries: GeolocationQuery[]; target: Target }[]> {
    return this.missions.missions$.pipe(
      take(1),
      tap((missions) => {
        const lastEmptyMission = extractLastEmptyMission(missions);
        if (lastEmptyMission) {
          this.missions.setActiveMission(lastEmptyMission.id);
        } else {
          if (missions.length >= maxMissions) {
            throw new Error('MAX_NUMBER_OF_MISSION_SCREENS');
          }
          this.missions.createEmptyMission();
        }
      }),
      switchMap(() =>
        forkJoin(
          telnos.map((telno) =>
            this.missions.fetchGeolocationQueriesByNumber(telno)
          )
        )
      )
    );
  }

  public downloadPDFReport(payload: KeystoneGeoQuery): void {
    this.store.dispatch(GeolocationQueryAction.downloadPdfReport(payload));
  }
}
