<div class="mb-3">
  <ng-container *ngrxLet="currentUser$ as currentUser">
    <form [formGroup]="otpConfigForm">
      <h6 class="h5 mb-3 mt-0">
        {{ 'OTP.ADDITIONAL_CHECK' | translate }}
      </h6>
      <keystone-radio-group-with-editable-values
        [config]="radioGroupConfig!"
        (radioSelected)="handleRadioSelection($event)"
      >
      </keystone-radio-group-with-editable-values>
      <!-- hide qr for now - hun-586 -->
      <!-- <div class="d-flex">
        <input
          type="radio"
          [id]="applicationRadioValue"
          name="otpChannel"
          [formControl]="otpChannelControl"
          class="form-check-input"
          [value]="applicationRadioValue"
          (ngModelChange)="handleRadioSelection($event)"
        />
        <label [for]="applicationRadioValue" class="d-flex flex-column"
          ><span>
            {{ 'OTP.AUTHENTICATOR_LABEL' | translate }}
          </span>
          <span>(eg. Google Authenticator)</span>
        </label>
      </div> -->
    </form>
  </ng-container>
</div>
