import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MissionId } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { DialogService } from '@fe-platform/shared-ui/keystone';
import { TranslateService } from '@ngx-translate/core';
import { map, of, switchMap, take, tap } from 'rxjs';

@Injectable()
export class MissionsNavBarService {
  missions$ = this.geolocationService.missions.missions$;
  activeMission$ = this.geolocationService.missions.activeMission$;

  constructor(
    private router: Router,
    public geolocationService: GeolocationFacade,
    private translationService: TranslateService,
    private dialog: DialogService
  ) {}

  public onSelectMission(missionId: MissionId): void {
    this.geolocationService.missions.setActiveMission(missionId);
    this.router.navigate(['missions', missionId]);
  }

  public onAddMission(): void {
    this.geolocationService.missions.createEmptyMission();
    this.geolocationService.missions.activeMission$
      .pipe(take(1))
      .subscribe((mission) => {
        this.router.navigate(['missions', mission?.id]);
      });
  }

  public onDeleteMission(missionId: MissionId): void {
    let dialogMessage = this.translationService.instant(
      'MISSIONS.MISSION_HAS_TARGETS_IN_IT'
    );
    this.missions$
      .pipe(
        take(1),
        tap((missions) => {
          if (missions.length === 1) {
            dialogMessage = this.translationService.instant(
              'MISSIONS.YOU_CANNOT_DELETE_ONLY_MISSION'
            );
          }
        }),
        map((missions) => {
          return missions.find((m) => m.id === missionId);
        }),
        switchMap((mission) => {
          if (!mission?.targets.length) return of(true);
          return this.dialog.openConfirmDialog(
            this.translationService.instant('MISSIONS.DELETING_A_MISSION'),
            dialogMessage,
            this.translationService.instant('CONFIRM'),
            this.translationService.instant('CANCEL')
          ).closed;
        }),
        tap((shouldDelete: boolean) => {
          if (shouldDelete) {
            this.geolocationService.missions.deleteMission(missionId);
            this.activeMission$.pipe(take(1)).subscribe((mission) => {
              this.router.navigate(['missions', mission?.id]);
            });
          }
        })
      )
      .subscribe();
  }
}
