import { AbstractControl, ValidationErrors } from '@angular/forms';
import { OTPChannels } from '@fe-platform/auth/data';

export const otpFormValidator = (
  form: AbstractControl
): ValidationErrors | null => {
  const error = { 'invalid-otp-form': true };
  const otpChannel = form.get('otpChannel');
  if (!otpChannel) throw new Error('No otpChannel control in otpForm');
  const otpValue: OTPChannels = otpChannel.value;
  if (!otpValue) return error;
  if (otpValue === OTPChannels.App) return null;
  if (otpValue === OTPChannels.OtpPhone && form.get('phone')?.invalid) {
    return error;
  }
  if (otpValue === OTPChannels.Email && form.get('email')?.invalid) {
    return error;
  }
  return null;
};
