<div class="toast-body d-flex justify-content-between">
  <div class="w-100">
    <div>
      {{ toast.title }}
    </div>
    <div>
      <ng-container *ngIf="toast.icon">
        <i [class]="toast.icon"></i>
      </ng-container>
      {{ toast.content }}
    </div>
  </div>
  <button
    type="button"
    aria-label="Close"
    class="btn-close"
    (click)="removeToast(toast)"
  ></button>
</div>
<button
  data-qa="toast-view-btn"
  *ngIf="toast.action"
  class="btn btn-outline-primary mt-1 w-100"
  type="button"
  (click)="toast.action.actionClicked(); onActionClick(toast)"
>
  {{ toast.action.content }}
</button>
<button
  *ngIf="toast.action_btn"
  class="btn btn-primary btn-toaster-action mt-1 w-100"
  type="button"
  (click)="toast.action_btn.actionClicked(); removeToast(toast)"
>
  {{ toast.action_btn.content }}
  <span *ngIf="toast.showCountdown">
    <span class="mx-1">|</span>
    <span class="me-1 spinner-border text-secondary"></span>
    <span *ngrxLet="countdown$ as countdown">
      {{ countdown }} {{ countdown | i18nPlural: ('SECONDS' | translate) }}
    </span>
  </span>
</button>
