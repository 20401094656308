import { Mission, Msisdn } from '../models';

export function extractUniqueMsisdnsFromAllMissions(
  missions: Mission[]
): Msisdn[] {
  return Array.from(
    new Set(
      missions
        .flatMap((mission) => mission.targets)
        .map((target) => target.msisdn)
    )
  );
}
