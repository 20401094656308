import { stringToDate, toMediumDateString } from '@fe-platform/core/date';
import { Robot } from '@fe-platform/geolocation/data';
import { differenceInMinutes, formatDistance } from 'date-fns';
import { RobotModalDetails } from './model';

export const formatScheduleQueryData = (
  msisdn: string,
  scheduler: Robot,
  executedSearches: number,
  timesLocated: number
): RobotModalDetails => {
  const start = stringToDate(scheduler.starts_at);
  const now = new Date();
  const end = stringToDate(scheduler.ends_at);
  return {
    msisdn,
    startDateTime: start,
    duration: formatDistance(start, end),
    expirationDate: toMediumDateString(end),
    frequency: scheduler.interval_minutes.toString(),
    scheduledSearches: Math.abs(
      Math.round(differenceInMinutes(now, end) / scheduler.interval_minutes)
    ),
    isLogon: scheduler.schedule_type === 'logon',
    scheduleId: scheduler.id,
    executedSearches,
    timesLocated,
  };
};
