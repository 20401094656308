import { Component } from '@angular/core';
import { BillingFacade } from '@fe-platform/billing/state';
import { environment } from '@fe-platform/environment';

@Component({
  selector: 'keystone-view-available-credits-widget',
  templateUrl: './view-available-credits-widget.component.html',
  styleUrls: ['./view-available-credits-widget.component.css'],
})
export class ViewAvailableCreditsWidgetComponent {
  credits$ = this.billingFacade.credits$;

  creditLimitWarning = environment.billing.creditLimitWarning;
  constructor(private billingFacade: BillingFacade) {}
}
