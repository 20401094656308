import { Injectable } from '@angular/core';
import {
  GeolocationQueryParams,
  GeolocationReportsParams,
  ReportType,
} from '@fe-platform/geolocation/data';
import { Store } from '@ngrx/store';
import {
  GeolocationQueryAction,
  selectFilters,
  selectLoading,
  selectPagination,
  selectQueries,
} from './reducer';

@Injectable({ providedIn: 'root' })
export class QueriesTableFacade {
  filters$ = this.store.select(selectFilters);
  queries$ = this.store.select(selectQueries);
  pagination$ = this.store.select(selectPagination);
  loading$ = this.store.select(selectLoading);

  constructor(private store: Store) {}

  public initializeTable(filters: Partial<GeolocationQueryParams>): void {
    this.store.dispatch(GeolocationQueryAction.setFilters({ filters }));
  }

  public changeFilters(filters: Partial<GeolocationQueryParams>): void {
    this.store.dispatch(GeolocationQueryAction.setFilters({ filters }));
  }

  public downloadQueryReport(
    params: Partial<GeolocationReportsParams>,
    reportType: ReportType
  ): void {
    this.store.dispatch(
      GeolocationQueryAction.downloadReport({ params, reportType })
    );
  }

  public reset(): void {
    this.store.dispatch(GeolocationQueryAction.reset());
  }

  public refresh(): void {
    this.store.dispatch(GeolocationQueryAction.refresh());
  }
}
