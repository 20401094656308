import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { OtpConfigFormComponent } from './otp-config-form/otp-config-form.component';
import { TempModule } from '@fe-platform/shared-ui/keystone';
import { OtpCodeFormComponent } from './otp-code-form/otp-code-form.component';
import { LetModule, PushModule } from '@ngrx/component';
import { OtpToggleComponent } from './otp-toggle/otp-toggle.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TempModule,
    LetModule,
    PushModule,
  ],
  declarations: [
    OtpConfigFormComponent,
    OtpCodeFormComponent,
    OtpToggleComponent,
  ],

  exports: [OtpConfigFormComponent, OtpCodeFormComponent, OtpToggleComponent],
})
export class AuthFeatureOtpFormModule {}
