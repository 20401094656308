<div
  class="badge p-1"
  data-qa="location-badge-text"
  [ngClass]="{
    'badge-soft-danger':
      (query | queryStatusText) === StatusContents.NO_LOCATION_FOUND,
    'badge-soft-success':
      (query | queryStatusText) === StatusContents.LOCATION_FOUND,
    'badge-soft-primary': (query | queryStatusText) === StatusContents.LOCATING
  }"
>
  {{ query | queryStatusText | translate }}
</div>
