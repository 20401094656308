import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { getTargetFromQueryArgs } from '@fe-platform/notifications/data';
import { ToasterService } from '@fe-platform/shared-ui/keystone';
import { TranslateService } from '@ngx-translate/core';

export function eventIsManualQueryFailed(
  event: unknown
): event is GeolocationQuery {
  return (
    typeof event === 'object' &&
    !!event &&
    'id' in event &&
    'provider' in event &&
    'device' in event &&
    'query_args' in event &&
    'status' in event &&
    (event as { [key: string]: string })['status'] === 'FAILED'
  );
}

function showFailedManualQueryMessage(
  toaster: ToasterService,
  content: string,
  title: string
): void {
  toaster.showDefault({
    title,
    content: content,
  });
}

export function createContentForFailedManualQuery(
  translationService: TranslateService,
  toaster: ToasterService,
  query: GeolocationQuery
): void {
  const title = getTargetFromQueryArgs(query.query_args);

  const content = `${title} - ${translationService.instant(
    'EVENT_NOTIFICATIONS.LOCATION_FAILED'
  )}`;

  showFailedManualQueryMessage(toaster, content, title);
}
