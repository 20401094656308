import { Injectable } from '@angular/core';
import {
  AuthDataService,
  AuthStatus,
  createOtpPayload,
} from '@fe-platform/auth/data';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, map, repeat, switchMap } from 'rxjs';
import { AuthActions } from '../actions';
import { selectOtpConfiguration } from '../selectors';

@Injectable()
export class OtpEffects {
  constructor(
    private actions$: Actions,
    private authDataService: AuthDataService,
    private store: Store,
    private translationService: TranslateService
  ) {}
  submitOtpConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.submitOtpConfiguration),
      switchMap((otpConfiguration) => {
        return this.authDataService
          .postOtpConfiguration(createOtpPayload(otpConfiguration))
          .pipe(
            switchMap(({ result }) => [
              AuthActions.setOtpConfiguration({
                channel: result.otp_channel,
                recipient: otpConfiguration.recipient,
                hashedRecipient: result.otp_recipient,
              }),
              AuthActions.setStatus({
                status: AuthStatus.VALIDATING_OTP_CONFIGURATION,
              }),
            ]),
            catchError(() => EMPTY)
          );
      })
    )
  );
  validateOtpConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.validateOtpConfiguration),
      concatLatestFrom(() => this.store.select(selectOtpConfiguration)),
      switchMap(([{ otpCode }, otpConfiguration]) =>
        this.authDataService.postOtpConfiguration({
          ...createOtpPayload(otpConfiguration),
          otp_code: otpCode,
        })
      ),
      map((response) => {
        const result = response.result;
        if (result.status === 'ok') {
          return AuthActions.setStatus({ status: AuthStatus.AUTHENTICATED });
        } else {
          throw new Error(
            this.translationService.instant('OTP.INVALID_OTP_CODE_PROVIDED')
          );
        }
      }),
      catchError((e: Error) => [
        AuthActions.setErrorMessage({
          errorMessage: e.message,
        }),
      ]),
      repeat()
    )
  );
}
