import { isValidMsisdn } from '@fe-platform/core/phone';
import { AbstractControl, ValidatorFn } from '@angular/forms';

// adapter for isValidPhone that returns a Validator Object
export function isValidPhoneFormValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const isInvalidNumber = !isValidMsisdn(control.value);
    return isInvalidNumber ? { invalidPhone: true } : null;
  };
}
