import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LetModule, PushModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { ViewAvailableCreditsWidgetComponent } from './components/view-available-credits-widget/view-available-credits-widget.component';

@NgModule({
  imports: [
    CommonModule,
    LetModule,
    PushModule,
    NgbTooltipModule,
    TranslateModule,
  ],
  declarations: [ViewAvailableCreditsWidgetComponent],
  exports: [ViewAvailableCreditsWidgetComponent],
})
export class BillingFeatureCreditsModule {}
