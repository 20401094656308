import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponents } from './layout';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TokenInterceptor } from '@fe-platform/auth/data';
import {
  AuthEffects,
  AuthStoreName,
  authReducer,
} from '@fe-platform/auth/state';
import { BillingFeatureCreditsModule } from '@fe-platform/billing/feature-credits';
import { BillingStateName, billingReducer } from '@fe-platform/billing/state';
import { environment } from '@fe-platform/environment';
import { GeolocationStoreModule } from '@fe-platform/geolocation/state';
import {
  ErrorHandlerService,
  TempModule,
} from '@fe-platform/shared-ui/keystone';
import { LetModule, PushModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigProviders } from './config.providers';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { CoreStateModule } from '@fe-platform/core/state';
import { NotificationsFeatureInstantNotificationsModule } from '@fe-platform/notifications/feature-instant-notifications';
import { NotificationsListFeatureModule } from '@fe-platform/notifications/feature-notifications-list';
import { NotificationsFeatureStoreModule } from '@fe-platform/notifications/state';

import {
  ServerConfigStateName,
  serverConfigReducer,
} from '@fe-platform/server-config/state';
import { LeftSidebarMobileComponent } from './layout/left-sidebar/left-sidebar-mobile/left-sidebar-mobile.component';
import { LeftSidebarWebComponent } from './layout/left-sidebar/left-sidebar-web/left-sidebar-web.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    ...LayoutComponents,
    DashboardComponent,
    LeftSidebarWebComponent,
    LeftSidebarMobileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LetModule,
    PushModule,
    NgApexchartsModule,
    TempModule,
    NgbTooltipModule,
    NgbDropdownModule,
    BillingFeatureCreditsModule,
    NotificationsListFeatureModule,
    NotificationsFeatureInstantNotificationsModule,
    CoreStateModule,
    StoreModule.forRoot({
      [AuthStoreName]: authReducer,
      [BillingStateName]: billingReducer,
      [ServerConfigStateName]: serverConfigReducer,
    }),
    GeolocationStoreModule,
    NotificationsFeatureStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([...AuthEffects]),
    NgxMapboxGLModule.withConfig({ accessToken: environment.mapBoxToken }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    ...ConfigProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
