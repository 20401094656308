import { GeolocationQuery, Robot } from '@fe-platform/geolocation/data';

export function eventIsTriggeredByCurrentUser(
  event: unknown,
  username: string | undefined
): event is GeolocationQuery | Robot {
  return (
    typeof event === 'object' &&
    !!event &&
    'id' in event &&
    'created_by' in event &&
    (event as { [key: string]: string })['created_by'] === username
  );
}
