import { AuthStatus, OTPChannels, User } from '@fe-platform/auth/data';
export interface AuthState {
  status: AuthStatus;
  otpChannel: OTPChannels;
  otpChannelRecipient: string;
  otpHashedRecipient: string;
  currentUser: User | null;
  token: string;
  errorMessage: string;
}

const initialState: AuthState = {
  status: AuthStatus.UNKNOWN,
  currentUser: null,
  otpChannel: OTPChannels.None,
  otpChannelRecipient: '',
  otpHashedRecipient: '',
  token: '',
  errorMessage: '',
};

const getTokenFromLocalStorage = (): string =>
  localStorage.getItem('token') || '';
export const generateInitialState: () => AuthState = () => {
  const token = getTokenFromLocalStorage();
  return {
    ...initialState,
    token: token,
  };
};
export const AuthStoreName = 'Auth';
