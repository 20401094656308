import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingFacade } from '@fe-platform/billing/state';
import { LayoutFacade } from '@fe-platform/core/state';
import { Target } from '@fe-platform/geolocation/data';
import {
  ROBOT_MODAL_MODE,
  RobotsModalService,
} from '@fe-platform/geolocation/feature-robots-modal';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import {
  ToasterService,
  handleErrorAndShowToaster$,
} from '@fe-platform/shared-ui/keystone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  debounceTime,
  finalize,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CssSelectorTargetIdPrefix } from '../active-mission/contants';
import { scrollIntoView } from './scrollIntoView';

@UntilDestroy()
@Component({
  selector: 'keystone-mission-target-card',
  templateUrl: './mission-target-card.component.html',
  styleUrls: ['./mission-target-card.component.css'],
})
export class MissionTargetCardComponent implements OnInit {
  @Input() public target!: Target;
  @Input() public missionId!: string;

  constructor(
    private geolocationService: GeolocationFacade,
    private billingFacade: BillingFacade,
    private router: Router,
    private modal: RobotsModalService,
    private route: ActivatedRoute,
    private layoutFacade: LayoutFacade,
    private toasterService: ToasterService,
    private translationService: TranslateService
  ) {}
  isMobile$: Observable<boolean> = this.layoutFacade.isMobile$;
  targetWithAllQueries$ =
    this.geolocationService.missions.activeMissionWithAllQueries$.pipe(
      map((mission) => {
        return mission.targets.find(
          (target) => target.id === this.target.id
        ) as Target;
      })
    );
  hasPendingQuery$: Observable<boolean> | undefined;
  hasActiveRobot$: Observable<boolean> | undefined;
  selected$ = this.route.params.pipe(
    map((params) => this.target.id === params['targetId']),
    withLatestFrom(this.isMobile$),
    debounceTime(10),
    map(([isSelected, isMobile]) => {
      if (isSelected) {
        this.scrollTargetIntoView(isMobile);
      }

      return isSelected;
    })
  );

  lockLocate = false;

  ngOnInit(): void {
    this.hasPendingQuery$ =
      this.geolocationService.pendingQueries.targetHasPendingQuery$(
        this.target
      );
    this.hasActiveRobot$ = this.geolocationService.robots.hasActiveRobot$(
      this.target
    );
  }

  locate(event?: Event): void {
    if (!this.target.visible) return;
    event?.stopPropagation();
    this.lockLocate = true;
    this.billingFacade
      .hasSuffecientCredits$('query_location')
      .pipe(
        switchMap(() =>
          this.geolocationService.missions.sendSingleGeolocationQuery(
            this.target
          )
        ),
        handleErrorAndShowToaster$(
          this.toasterService,
          this.translationService
        ),
        finalize(() => (this.lockLocate = false))
      )
      .subscribe();
  }

  setActiveTarget(): void {
    if (this.target.visible) {
      this.geolocationService.missions.activeMission$
        .pipe(
          take(1),
          tap((activeMission) => {
            this.router.navigate([
              'missions',
              activeMission.id,
              this.target.id,
            ]);
          })
        )
        .subscribe();
    }
  }

  deleteTargetFromMission(event: Event): void {
    event.stopPropagation();
    this.geolocationService.missions.activeTarget$
      .pipe(
        take(1),
        tap((activeTarget) => {
          if (activeTarget?.id === this.target.id) {
            this.router.navigate(['missions', this.target.missionId]);
          }
        })
      )
      .subscribe(() => {
        this.geolocationService.missions.deleteTarget(this.target);
      });
  }

  openRobotsModal(event: Event): void {
    event.stopPropagation();

    this.hasActiveRobot$
      ?.pipe(
        take(1),
        switchMap((hasActiveRobot) => {
          const viewMode = hasActiveRobot
            ? ROBOT_MODAL_MODE.DETAILS
            : ROBOT_MODAL_MODE.CREATION;
          return this.modal.openRobotsModal$(viewMode, this.target);
        })
      )
      .subscribe();
  }

  onNavigateToTargetHistory(target: Target): void {
    this.geolocationService.missions.activeMission$
      .pipe(
        untilDestroyed(this),
        take(1),
        tap((mission) =>
          this.router.navigate(['missions', mission.id, target.id, 'history'])
        )
      )
      .subscribe();
  }

  private scrollTargetIntoView(isMobile: boolean): void {
    isMobile
      ? document
          .getElementById(CssSelectorTargetIdPrefix + this.target.id)
          ?.scrollIntoView({
            behavior: 'smooth',
          })
      : scrollIntoView(this.target);
  }
}
