<div class="card col-10 col-md-4 p-4">
  <keystone-otp-code-form
    (otpCodeChanged)="handleOtpCode($event)"
  ></keystone-otp-code-form>
  <div class="mb-3 text-center d-grid">
    <button class="btn btn-primary" type="submit" (click)="submitOtpCode()">
      Log In
    </button>
  </div>
</div>
