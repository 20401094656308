import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { fade } from '../animations';

@Component({
  selector: 'keystone-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
  animations: [fade],
})
export class LoaderComponent implements OnInit {
  @Input() fullscreen = true;
  @HostBinding('style.position')
  position = 'fixed';
  @HostBinding('style.width')
  width = '100vw';
  @HostBinding('style.height')
  height = '100vh';
  @HostBinding('style.background')
  background = '#36404a';

  ngOnInit(): void {
    if (!this.fullscreen) {
      this.height = 'auto';
      this.width = 'auto';
      this.position = 'relative';
      this.background = 'none';
    }
  }
}
