import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GeolocationFeatureQueryBoxModule } from '@fe-platform/geolocation-feature-geolocation-query-box';
import { GeolocationFeatureRobotsModalModule } from '@fe-platform/geolocation/feature-robots-modal';
import { GeolocationSharedUiModule } from '@fe-platform/geolocation/shared-ui';
import { TempModule } from '@fe-platform/shared-ui/keystone';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LetModule, PushModule } from '@ngrx/component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ActiveMissionComponent } from './active-mission/active-mission.component';
import { FeatureMissionsRoutingModule } from './feature-missions-routing.module';
import { Guards } from './guards';
import { MapMarkerComponent } from './map-marker/map-marker.component';
import { MissionMapComponent } from './mission-map/mission-map.component';
import { MissionTargetHistoryComponent } from './mission-target-history/mission-target-history.component';
import { MissionTargetHistoryListComponent } from './mission-target-history/mission-target-history-list/mission-target-history-list.component';
import { MissionTargetHistoryMobileComponent } from './mission-target-history/mission-target-history-mobile/mission-target-history-mobile.component';
import { MissionTargetHistoryBottomSheetComponent } from './mission-target-history/mission-target-history-bottom-sheet/mission-target-history-bottom-sheet.component';
import { MissionTargetHistoryWebComponent } from './mission-target-history/mission-target-history-web/mission-target-history-web.component';
import { LatestLocatedLabelComponent } from './mission-target-card/latest-located-label/latest-located-label.component';
import { MissionTargetCardComponent } from './mission-target-card/mission-target-card.component';
import {
  KlComponent,
  KlComponentsService,
  KlContainerComponent,
} from './mission-timebar/angular-keylines';
import { MissionTimeBarComponent } from './mission-timebar/mission-timebar.component';
import { MissionsContainerComponent } from './missions-container.component';
import { MissionsBottomSheetComponent } from './missions-nav-bar/missions-bottom-sheet/missions-bottom-sheet.component';
import { MissionsNavBarMobileComponent } from './missions-nav-bar/missions-nav-bar-mobile/missions-nav-bar-mobile.component';
import { MissionsNavBarWebComponent } from './missions-nav-bar/missions-nav-bar-web/missions-nav-bar-web.component';
import { MissionsNavBarComponent } from './missions-nav-bar/missions-nav-bar.component';
import { MissionsNavBarService } from './missions-nav-bar/missions-nav-bar.service';
import { CellTowerPinComponent } from './cell-tower-pin/cell-tower-pin.component';
import { CellTowerPopupComponent } from './cell-tower-pin/cell-tower-popup/cell-tower-popup.component';
import { MissionsNavBarSelectorComponent } from './missions-nav-bar/missions-nav-bar-web/missions-nav-bar-selector/missions-nav-bar-selector.component';
import { MissionTargetColorSidebarComponent } from './mission-target-card/mission-target-color-sidebar/mission-target-color-sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FeatureMissionsRoutingModule,
    GeolocationFeatureRobotsModalModule,
    GeolocationFeatureQueryBoxModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgxMapboxGLModule,
    LetModule,
    PushModule,
    TempModule,
    GeolocationSharedUiModule,
  ],
  declarations: [
    MissionsContainerComponent,
    MissionsNavBarComponent,
    MissionsNavBarWebComponent,
    MissionsNavBarMobileComponent,
    MissionTargetCardComponent,
    MissionMapComponent,
    MissionTimeBarComponent,
    KlComponent,
    KlContainerComponent,
    ActiveMissionComponent,
    MapMarkerComponent,
    MissionTargetHistoryComponent,
    MissionTargetHistoryListComponent,
    MissionTargetHistoryMobileComponent,
    MissionTargetHistoryBottomSheetComponent,
    MissionTargetHistoryWebComponent,
    LatestLocatedLabelComponent,
    MissionsBottomSheetComponent,
    CellTowerPinComponent,
    CellTowerPopupComponent,
    MissionsNavBarSelectorComponent,
    MissionTargetColorSidebarComponent,
  ],
  providers: [KlComponentsService, ...Guards, MissionsNavBarService],
})
export class FeatureMissionsModule {}
