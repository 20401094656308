import { Injectable } from '@angular/core';
import { Target } from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable, take } from 'rxjs';
import { formatScheduleQueryData } from './formatScheduleQueryData';
import { ROBOT_MODAL_MODE } from './model';
import { RobotsModalComponent } from './robots-modal/robots-modal.component';

@Injectable()
export class RobotsModalService {
  constructor(
    private modalService: NgbModal,
    private geolocationService: GeolocationFacade
  ) {}

  openRobotsModal$(
    mode: ROBOT_MODAL_MODE,
    target: Target
  ): Observable<NgbModalRef> {
    const modalDialog = this.modalService.open(RobotsModalComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalDialog.componentInstance.viewMode = mode;
    modalDialog.componentInstance.msisdn = target.msisdn;
    modalDialog.componentInstance.imsi = target.imsi;
    return this.geolocationService.robots.getActiveRobot$(target).pipe(
      take(1),
      map((scheduler) => {
        if (!scheduler) return modalDialog;
        const schedulerQueries = target
          .getScheduledQueries()
          .filter((q) => q.schedule.id === scheduler.id);
        const executedSearches = schedulerQueries.length;
        const timesLocated = schedulerQueries.filter(
          (q) => !!q.location
        ).length;
        modalDialog.componentInstance.details = formatScheduleQueryData(
          target.msisdn,
          scheduler,
          executedSearches,
          timesLocated
        );
        return modalDialog;
      })
    );
  }
}
