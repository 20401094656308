import { Component } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { combineLatest, map } from 'rxjs';

@Component({
  selector: 'keystone-missions-container',
  templateUrl: './missions-container.component.html',
  styleUrls: ['./missions-container.component.css'],
})
export class MissionsContainerComponent {
  constructor(
    private layout: LayoutFacade,
    private geolocationFacade: GeolocationFacade
  ) {}
  timelineIsVisible$ = combineLatest([
    this.layout.isMobile$,
    this.geolocationFacade.missions.activeQuery$,
  ]).pipe(map(([isMobile, activeQuery]) => !isMobile && !activeQuery));
  isMobile$ = this.layout.isMobile$;
}
