<div class="card col-10 col-md-4 p-4">
  <keystone-logo class="login-logo" [width]="207" [height]="40"></keystone-logo>
  <h6 class="h5 mb-0 mt-3">{{ 'LOGIN.WELCOME_TO_KEYSTONE' | translate }}</h6>
  <p class="text-muted mt-1 mb-4">
    {{ 'LOGIN.ENTER_USERNAME_AND_PASS' | translate }}
  </p>

  <form [formGroup]="loginForm" name="login-form" class="authentication-form">
    <div class="mb-3">
      <label class="form-label">{{ 'LOGIN.USERNAME' | translate }}</label>
      <div class="input-group">
        <span class="input-group-text">
          <i class="icon-dual" data-feather="user"></i>
        </span>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              loginForm.get('username')?.touched &&
              loginForm.get('username')?.invalid
          }"
          autocomplete="username"
          id="username"
          formControlName="username"
        />
        <div
          *ngIf="
            loginForm.get('username')?.touched &&
            loginForm.get('username')?.invalid
          "
          class="invalid-feedback"
        >
          {{ 'LOGIN.PLEASE_ENTER_USERNAME' | translate }}
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">{{ 'LOGIN.PASSWORD' | translate }}</label>
      <div class="input-group">
        <span class="input-group-text">
          <i class="icon-dual" data-feather="lock"></i>
        </span>
        <input
          type="{{ showPassword ? 'text' : 'password' }}"
          autocomplete="current-password"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              loginForm.get('password')?.touched &&
              loginForm.get('password')?.invalid
          }"
          id="password"
          formControlName="password"
        />
        <keystone-show-password-button
          (changeShowPassword)="showPasswordChanged($event)"
        ></keystone-show-password-button>
        <div
          *ngIf="
            loginForm.get('password')?.touched &&
            loginForm.get('password')?.invalid
          "
          class="invalid-feedback"
        >
          {{ 'LOGIN.PLEASE_ENTER_PASSWORD' | translate }}
        </div>
      </div>
      <div
        *ngIf="errorMessage$ | ngrxPush; let errorMessage"
        class="invalid-feedback"
      >
        {{ errorMessage }}
      </div>
    </div>

    <div class="mb-3 text-center d-grid">
      <ng-container *ngrxLet="formStatus$ as formStatus">
        <button
          data-qa="login-btn"
          class="btn btn-primary"
          type="submit"
          [disabled]="formStatus !== 'VALID'"
          (click)="logIn()"
        >
          {{ 'LOGIN.LOGIN' | translate }}
        </button>
      </ng-container>
    </div>
  </form>
</div>

<div class="mt-5">
  <keystone-language-selection></keystone-language-selection>
</div>
