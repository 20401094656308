import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TempModule } from '@fe-platform/shared-ui/keystone';
import { LetModule, PushModule } from '@ngrx/component';
import { PasswordChangeFormComponent } from './password-change-form/password-change-form.component';
import { PasswordErrorPipe } from './password-form/helpers';
import { PasswordFormComponent } from './password-form/password-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LetModule,
    PushModule,
    TempModule,
  ],
  declarations: [
    PasswordFormComponent,
    PasswordChangeFormComponent,
    PasswordErrorPipe,
  ],
  exports: [PasswordFormComponent, PasswordChangeFormComponent],
})
export class AuthFeaturePasswordFormModule {}
