import { Component, HostListener, OnDestroy } from '@angular/core';
import { BottomSheet } from './BottomSheet';
import { BottomSheetsService } from './bottom-sheets.service';
import { fadeInOut, slideInOutBottom } from './animations';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'keystone-bottom-sheets',
  templateUrl: './bottom-sheets.component.html',
  styleUrls: ['./bottom-sheets.component.css'],
  animations: [fadeInOut, slideInOutBottom],
})
export class BottomSheetsComponent implements OnDestroy {
  private activeTargetEl!: HTMLElement;
  private activeSheetEl!: HTMLElement;
  private activeSheet: BottomSheet | null = null;
  sheets$ = this.bottomSheetsService.sheets$;

  constructor(private bottomSheetsService: BottomSheetsService) {}

  ngOnDestroy(): void {
    this.bottomSheetsService.destroyAll();
  }

  close(sheet: BottomSheet): void {
    this.bottomSheetsService.close(sheet);
  }

  private setSheetHeight(height: number): void {
    const sheetHeight = Math.max(0, Math.min(100, height));
    this.activeSheetEl.style.height = `${sheetHeight}%`;
    if (sheetHeight === 100) {
      this.activeSheetEl.classList.add('fullscreen');
    } else {
      this.activeSheetEl.classList.remove('fullscreen');
    }
  }

  private getPageY(e: MouseEvent | TouchEvent): number {
    return e.type.startsWith('mouse')
      ? (e as MouseEvent).pageY
      : (e as TouchEvent).touches[0].pageY;
  }

  private getSheetHeightPercentage(): number {
    return (this.activeSheetEl.offsetHeight / window.innerHeight) * 100;
  }

  onDragStart(e: MouseEvent | TouchEvent, sheet: BottomSheet): void {
    this.activeSheet = sheet;
    this.activeTargetEl = e.currentTarget as HTMLElement;
    this.activeSheetEl = this.activeTargetEl.parentNode as HTMLElement;
    this.activeTargetEl.dataset['dragPosition'] = this.getPageY(e).toString();
    this.activeSheetEl.classList.add('grabbing');
  }

  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  onDragMove(e: MouseEvent | TouchEvent): void {
    if (!this.activeTargetEl) return;
    const dragPosition = Number(this.activeTargetEl.dataset['dragPosition']);
    if (dragPosition === undefined || Number.isNaN(dragPosition)) return;

    const y = this.getPageY(e);
    const deltaY = dragPosition - y;
    const deltaHeight = (deltaY / window.innerHeight) * 100;
    const sheetHeight = this.getSheetHeightPercentage();
    this.setSheetHeight(sheetHeight + deltaHeight);
    this.activeTargetEl.dataset['dragPosition'] = y.toString();
  }

  @HostListener('window:mouseup', ['$event'])
  @HostListener('window:touchend', ['$event'])
  onDragEnd(): void {
    if (!this.activeTargetEl) return;
    this.activeTargetEl.dataset['dragPosition'] = undefined;
    this.activeSheetEl.classList.remove('grabbing');
    const sheetHeight = this.getSheetHeightPercentage();
    if (sheetHeight < 25 && this.activeSheet) {
      this.close(this.activeSheet);
    } else if (sheetHeight > 60) {
      this.setSheetHeight(100);
    } else {
      this.setSheetHeight(40);
    }
    this.activeSheet = null;
  }
}
