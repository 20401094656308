import { GeolocationQuery } from '@fe-platform/geolocation/data';
import { map, Observable } from 'rxjs';
import { getCircleCoordinates } from './helpers';

export function queryToGeoJSON(
  query$: Observable<GeolocationQuery | null>
): Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined> {
  return query$.pipe(
    map((query) => {
      if (!query?.location) return;

      const longLat = query.location.coordinates as [number, number];
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                getCircleCoordinates(longLat, query.accuracy_meters),
              ],
            },
            properties: [],
          },
        ],
      };
    })
  );
}
