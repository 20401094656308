import { Robot } from '@fe-platform/geolocation/data';
import { getTargetFromQueryArgs } from '@fe-platform/notifications/data';
import { ToasterService } from '@fe-platform/shared-ui/keystone';
import { TranslateService } from '@ngx-translate/core';

export function eventIsRobotTerminated(event: unknown): event is Robot {
  return (
    typeof event === 'object' &&
    !!event &&
    'id' in event &&
    'canceled_at' in event &&
    'starts_at' in event &&
    'created_at' in event &&
    !!(event as { [key: string]: string })['canceled_at']
  );
}

function showRobotTerminatedMessage(
  toaster: ToasterService,
  content: string,
  title: string
): void {
  toaster.showDefault({
    title,
    content,
    icon: 'uil uil-robot uil-menu-icon',
  });
}

export function createContentForRobotTerminated(
  translationService: TranslateService,
  toaster: ToasterService,
  robot: Robot
): void {
  const title = getTargetFromQueryArgs(robot.payload.query_args);
  const content = `${translationService.instant(
    'EVENT_NOTIFICATIONS.ROBOT_TERMINATED'
  )}`;

  showRobotTerminatedMessage(toaster, content, title);
}
