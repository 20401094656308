import { stringToDate } from '@fe-platform/core/date';
import { Notification } from '@fe-platform/notifications/data';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { isAfter } from 'date-fns';

export type NotificationsState = EntityState<Notification>;
export function selectUserId(a: Notification): string {
  //In this case this would be optional since primary key is id
  return a.id;
}

export const adapter: EntityAdapter<Notification> =
  createEntityAdapter<Notification>({
    selectId: selectUserId,
    sortComparer: (a, b) =>
      isAfter(stringToDate(b.created_at), stringToDate(a.created_at)) ? 1 : -1,
  });
export const initialState: NotificationsState = adapter.getInitialState();
export const NotificationsStateName = 'Notifications';
