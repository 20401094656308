import {
  BalanceType,
  BillingPlan,
  CreditPools,
  TenantBillingDetails,
} from '@fe-platform/billing/data';
import {
  createActionGroup,
  createFeatureSelector,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';

export interface BillingState {
  tenantBillingDetails: TenantBillingDetails;
  credits: number | CreditPools;
  billingPlan: BillingPlan;
  ready: boolean;
}

export const initialState: BillingState = {
  tenantBillingDetails: {
    balance: 0,
    balance_type: BalanceType.DISTRIBUTED,
    total_credits_reserved: 0,
    unassign_user_credits: 0,
  },
  credits: { geolocation: 0, call_log: 0, webint: 0, somedus: 0, total: 0 },
  billingPlan: {
    query_location: { cost: 0 },
    query_lac_location: { cost: 0 },
    query_location_offline: { cost: 0 },
    advanced_geolocation: { cost: 0 },
    query_foreign_location: { cost: 0 },
    msisdn_extraction: { cost: 0 },
    call_info: { cost: 0 },
    neighbour_cell_towers: { cost: 0 },
  },
  ready: false,
};
export const BillingStateName = 'Billing';

export const BillingActions = createActionGroup({
  source: 'Billing',
  events: {
    'set Tenant Billing Details': props<{
      tenantBillingDetails: TenantBillingDetails;
    }>(),
    'set Credits': props<{ credits: number | CreditPools }>(),
    'set Billing Plan': props<{
      billingPlan: BillingPlan;
    }>(),
    ready: props<{ ready: boolean }>(),
    reset: emptyProps(),
  },
});

export const billingReducer = createReducer(
  initialState,
  on(BillingActions.setCredits, (state, action) => ({
    ...state,
    credits: action.credits,
  })),
  on(BillingActions.setBillingPlan, (state, action) => ({
    ...state,
    billingPlan: action.billingPlan,
  })),
  on(BillingActions.setTenantBillingDetails, (state, action) => ({
    ...state,
    tenantBillingDetails: action.tenantBillingDetails,
  })),
  on(BillingActions.ready, (state, action) => ({
    ...state,
    ready: action.ready,
  })),
  on(BillingActions.reset, () => initialState)
);
export const selectBillingState =
  createFeatureSelector<BillingState>(BillingStateName);

// Handling credits from two different variations for billing (single and multi pool)
// and properly return the number of remaining credits for geolocation
export const selectCredits = createSelector(selectBillingState, (state) =>
  typeof state.credits === 'number'
    ? state.credits
    : state.credits.geolocation || state.credits.total
);

export const selectBillingPlan = createSelector(
  selectBillingState,
  (state) => state.billingPlan
);

export const selectTenantBillingDetails = createSelector(
  selectBillingState,
  (state) => state.tenantBillingDetails
);
export const selectBillingIsReady = createSelector(
  selectBillingState,
  (state) => state.ready
);
