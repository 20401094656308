import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthFacade } from '@fe-platform/auth/state';
import { mustMatchValidator } from './must-match-password-validator';
import { passwordPolicyValidator } from './password-policy-validator';

export function createResetPasswordForm(auth: AuthFacade): UntypedFormGroup {
  return new UntypedFormGroup(
    {
      password: new UntypedFormControl(
        '',
        [Validators.required],
        [passwordPolicyValidator(auth)]
      ),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    },
    [mustMatchValidator('password', 'confirmPassword')]
  );
}
