import { Inject, Injectable } from '@angular/core';
import { WEBSOCKETS_URL } from '@fe-platform/core/config';
import { BehaviorSubject, filter, map, Observable, share, Subject } from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { Message } from '../models/message.model';
@Injectable({ providedIn: 'root' })
export class ServerWsTsWebsocketService {
  private _serverTsWs$: BehaviorSubject<Socket | null> =
    new BehaviorSubject<Socket | null>(null);
  public connected$ = this._serverTsWs$.pipe(map((socket) => !!socket));
  private _stream$: Subject<Message> = new Subject();
  public stream$ = this._stream$.asObservable();
  constructor(@Inject(WEBSOCKETS_URL) private wsUrl: string) {}

  public getServerTsConnection(): Observable<Socket | null> {
    return this._serverTsWs$.asObservable().pipe(filter(Boolean), share());
  }

  public initialize(token: string): void {
    if (!this._serverTsWs$.getValue()) {
      this.connect(token);
      this._serverTsWs$
        .pipe(
          filter(Boolean),
          map((socket) => {
            socket.removeAllListeners();
            socket.on('message', (message: Message) => {
              this._stream$.next(message);
            });
          })
        )
        .subscribe();
    } else {
      console.warn('tried to initialize ws when one was already present.');
    }
  }

  public disconnectFromExistingConnection(): void {
    if (this._serverTsWs$.value) {
      this._serverTsWs$.value.disconnect();
      this._serverTsWs$.next(null);
    }
  }

  private connect(token: string): void {
    const socket = io(this.wsUrl, {
      path: '/proxy/socket.io',
      query: { token },
      transports: ['websocket'],
      reconnectionAttempts: 5,
    });

    socket.on('connect', () => {
      this._serverTsWs$.next(socket);
    });
    socket.on('disconnect', () => {
      this._serverTsWs$.next(null);
    });
  }
}
