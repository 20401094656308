import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { missionReducer } from './reducer';
import { MissionsStateName } from './state';
import { EffectsModule } from '@ngrx/effects';
import { MissionEffects } from './effect';

@NgModule({
  imports: [
    StoreModule.forFeature(MissionsStateName, missionReducer),
    EffectsModule.forFeature([MissionEffects]),
  ],
})
export class MissionStoreModule {}
