import { isWithinInterval } from 'date-fns';
import { GeolocationQuery, TimebarRange } from '../models';

export function geoqueryInTimerange(
  geoquery: GeolocationQuery,
  timerange: TimebarRange | null
): boolean {
  if (!timerange) return true;
  if (!timerange.dateFrom || !timerange.dateTo) return false;
  return isWithinInterval(new Date(geoquery.created_at), {
    start: timerange.dateFrom,
    end: timerange.dateTo,
  });
}
