import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BillingFacade } from '@fe-platform/billing/state';
import { timeDiff } from '@fe-platform/core/date';
import { isValidImsi } from '@fe-platform/core/phone';
import { environment } from '@fe-platform/environment';
import {
  extractTargetFromMission,
  GeolocationQuery,
  Mission,
  Target,
  targetHasBeenLocated,
  validateMaxTargetsInMission$,
} from '@fe-platform/geolocation/data';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import {
  handleErrorAndShowToaster$,
  Toast,
  ToastContent,
  ToasterService,
  ToastType,
} from '@fe-platform/shared-ui/keystone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  repeat,
  Subject,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ensureNumberIsNotInMission$ } from './helpers/ensureNumberIsNotInMission';
@UntilDestroy()
@Injectable()
export class GeoquerySearchBoxService {
  activeMission$ = this.geolocationService.missions.activeMission$;
  activeMission!: Mission;
  submit$ = new Subject<string>();

  constructor(
    private geolocationService: GeolocationFacade,
    private billingFacade: BillingFacade,
    private toasterService: ToasterService,
    private router: Router,
    private translationService: TranslateService
  ) {
    this.submit$
      .pipe(
        untilDestroyed(this),
        switchMap(
          (msisdnOrImsi) =>
            this.fetchGeolocationQueriesByNumber(msisdnOrImsi).pipe(
              filter((r) => !(r instanceof Error))
            ) as Observable<{ target: Target; queries: GeolocationQuery[] }>
        ),
        withLatestFrom(this.geolocationService.missions.activeTarget$),
        take(1),
        tap(([{ target }, activeTarget]) => {
          if (!activeTarget) {
            this.navigateToTarget(target);
          }

          this.toasterService.showDefault({
            title: this.translationService.instant(
              `QUERY_BOX.ADDED_TO_MISSION`,
              { input: target.msisdn ? target.msisdn : target.imsi }
            ),
            content: this.getTargetLastLocatedStr(target),
            action_btn: {
              content: this.translationService.instant('QUERY_BOX.LOCATE_NOW'),
              actionClicked: () => this.locateTarget(target),
            },
          });
          this.focusLastToaster();
        }),
        catchError((e) => {
          if (e instanceof Error) {
            console.error(e);
          }
          return of();
        }),
        repeat()
      )
      .subscribe();
    this.activeMission$
      .pipe(
        untilDestroyed(this),
        tap((mission) => (this.activeMission = mission))
      )
      .subscribe();
  }

  private fetchGeolocationQueriesByNumber(
    msisdnOrImsi: string
  ): Observable<{ target: Target; queries: GeolocationQuery[] } | Error> {
    return this.activeMission$.pipe(
      validateMaxTargetsInMission$(environment.missions.maxTargets),
      ensureNumberIsNotInMission$(msisdnOrImsi),
      take(1),
      switchMap(() => {
        if (isValidImsi(msisdnOrImsi)) {
          return this.geolocationService.missions
            .fetchMsisdnFromImsi(msisdnOrImsi)
            .pipe(
              map((msisdn) => {
                return msisdn || msisdnOrImsi;
              })
            );
        } else {
          return of(msisdnOrImsi);
        }
      }),
      switchMap((msisdn) =>
        this.geolocationService.missions.fetchGeolocationQueriesByNumber(msisdn)
      ),
      catchError((e: Error) => {
        const errMsg = e.message;
        switch (errMsg) {
          case 'MAX_TARGETS_IN_MISSION':
            this.handleErrMaxTargetsInMission(errMsg);
            break;
          case ToastContent.IMSI_ALREADY_INCLUDED_IN_MISSION:
            this.handleErrIsIMSI(
              msisdnOrImsi,
              ToastContent.IMSI_ALREADY_INCLUDED_IN_MISSION
            );
            break;
          case ToastContent.GEOLOCATION_QUERY_TARGET_ALREADY_INCLUDED:
            this.handleTargetAlreadyInMission(
              msisdnOrImsi,
              ToastContent.GEOLOCATION_QUERY_TARGET_ALREADY_INCLUDED
            );
            break;
          default:
            this.toasterService.showError({
              content: this.translationService.instant(errMsg),
            });
        }
        return of(e);
      })
    );
  }

  private getTargetLastLocatedStr(target: Target): string {
    let contentStr = '';
    if (targetHasBeenLocated(target)) {
      let lastLocationReceivedAt = new Date();
      for (let i = 0; i < target.queries.length; i++) {
        if (target.queries[i].location_received_at) {
          lastLocationReceivedAt = target.queries[i].location_received_at;
          break;
        }
      }

      const diff = timeDiff(
        new Date().getTime(),
        new Date(lastLocationReceivedAt).getTime()
      );

      if (diff.days > 0) {
        contentStr = this.translationService.instant(
          `QUERY_BOX.TARGET_LAST_LOCATED_DAYS_AGO`,
          { input: diff.days }
        );
      } else if (diff.hours > 0) {
        contentStr = this.translationService.instant(
          `QUERY_BOX.TARGET_LAST_LOCATED_HOURS_AGO`,
          { input: diff.hours }
        );
      } else if (diff.minutes > 0) {
        contentStr = this.translationService.instant(
          `QUERY_BOX.TARGET_LAST_LOCATED_MINUTES_AGO`,
          { input: diff.minutes }
        );
      } else {
        contentStr = this.translationService.instant(
          `QUERY_BOX.TARGET_LAST_LOCATED_SECONDS_AGO`,
          { input: diff.seconds }
        );
      }
    } else {
      contentStr = this.translationService.instant(
        'QUERY_BOX.TARGET_HAS_NEVER_BEEN_LOCATED'
      );
    }
    return contentStr;
  }

  private navigateToTarget(target: Target): void {
    this.geolocationService.missions.activeMission$
      .pipe(take(1))
      .subscribe((mission) =>
        this.router.navigate(['missions', mission.id, target.id])
      );
  }
  private locateTarget(target: Target): void {
    this.billingFacade
      .hasSuffecientCredits$('query_location')
      .pipe(
        switchMap(() =>
          this.geolocationService.missions.sendSingleGeolocationQuery(target)
        ),
        handleErrorAndShowToaster$(this.toasterService, this.translationService)
      )
      .subscribe();
  }
  private focusLastToaster(): void {
    // Wait for the latest toaster to appear and focus on its action button
    setTimeout(() => {
      const toasterActionBtns = document.querySelectorAll(
        '.btn-toaster-action'
      );
      const latestToasterActionBtn = toasterActionBtns[
        toasterActionBtns.length - 1
      ] as HTMLElement;
      latestToasterActionBtn?.focus();
    }, 250);
  }
  private handleErrMaxTargetsInMission(errMsg: string): void {
    const max = environment.missions.maxTargets;
    this.toasterService.showError({
      content: this.translationService.instant(errMsg, { max }),
    });
  }

  private handleErrIsIMSI(
    msisdnOrImsi: string,
    toastContent: ToastContent
  ): void {
    const target = extractTargetFromMission(msisdnOrImsi, this.activeMission);
    if (target) {
      const str1 = this.translationService.instant(
        `TOAST_CONTENT.${toastContent}`,
        { input: msisdnOrImsi }
      );
      const str2 = target.msisdn
        ? `MSISDN ${target.msisdn}`
        : this.translationService.instant('TOAST_CONTENT.UNKNOWN_MSISDN');

      const content = `${str1} (${str2})`;
      const toastParams: Partial<Toast> = { content, title: ToastType.ERROR };

      this.showLocateNowToaster(toastParams, target);
    }
  }

  private handleTargetAlreadyInMission(
    msisdnOrImsi: string,
    toastContent: ToastContent
  ): void {
    const target = extractTargetFromMission(msisdnOrImsi, this.activeMission);
    if (target) {
      const content = this.translationService.instant(
        `TOAST_CONTENT.${toastContent}`,
        { number: msisdnOrImsi }
      );

      const title = this.translationService.instant(
        `TOAST_CONTENT.GEOLOCATION_QUERY_TARGET_ALREADY_INCLUDED_TITLE`
      );

      const toastParams: Partial<Toast> = {
        content,
        title,
      };

      this.showLocateNowToaster(toastParams, target);
    }
  }

  private showLocateNowToaster(
    toastParams: Partial<Toast>,
    target: Target
  ): void {
    this.toasterService.showDefault({
      ...toastParams,
      action_btn: {
        content: this.translationService.instant('QUERY_BOX.LOCATE_NOW'),
        actionClicked: () => this.locateTarget(target),
      },
    });
    this.focusLastToaster();
  }
}
