import {
  PasswordValidationResult,
  PasswordValidationType,
} from '@fe-platform/auth/data';

export function createErrorsBasedOnValidatorPolicies(
  passwordResult: PasswordValidationResult
): { [key: string]: boolean } | null {
  const validationType =
    passwordResult.validated_policies as PasswordValidationType;

  if (!validationType.length) {
    return { passwordAtLeast8Chars: true };
  }

  if (!validationType.uppercase) {
    return { passwortAtLeastUppercase: true };
  }

  if (!validationType.lowercase) {
    return { passwordAtLeastLowercase: true };
  }

  if (!validationType.digits) {
    return { passwordAtLeastDigits: true };
  }

  return null;
}
