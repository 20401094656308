import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TempModule } from '@fe-platform/shared-ui/keystone';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';

import { LetModule, PushModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationWidgetComponent } from './notification-widget/notification-widget.component';
import { NotificationTextPipe } from './notifications-item/notification-text.pipe';
import { NotificationsItemComponent } from './notifications-item/notifications-item.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LetModule,
    PushModule,
    FormsModule,
    NgbDropdownModule,
    TempModule,
    TempModule,
    NgbTooltipModule,
    TranslateModule,
  ],
  declarations: [
    NotificationWidgetComponent,
    NotificationsItemComponent,
    NotificationTextPipe,
  ],
  exports: [NotificationWidgetComponent],
})
export class NotificationsListFeatureModule {}
