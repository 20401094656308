import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, startWith } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'keystone-radio-button-with-editable-value',
  templateUrl: './radio-button-with-editable-value.component.html',
  styleUrls: ['./radio-button-with-editable-value.component.css'],
})
export class DynamicRadioButtonComponent implements AfterViewInit {
  @Input() name!: string;
  @Input() value!: string;
  @Input() radioGroupName!: string;
  @Input() radioControl!: UntypedFormControl;
  @Input() disabled = false;
  @Input() initialValue!: string;
  @Input() textControl!: UntypedFormControl;
  @Input() emptyStateLabel = 'Unavailable';
  @Input() placeholder = '';
  @Input() label = '';
  @Output() radioSelected = new EventEmitter<string>();
  @ViewChild(`radio`) radioInput!: ElementRef;
  ngAfterViewInit(): void {
    this.textControl.statusChanges
      .pipe(
        untilDestroyed(this),
        startWith(this.textControl.status),
        distinctUntilChanged()
      )
      .subscribe((status) => {
        if (status !== 'VALID') {
          this.validateRadioSelection();
          this.radioInput.nativeElement.disabled = true;
        } else {
          this.radioInput.nativeElement.disabled = false;
        }
      });
    // whenever the text changes and it is valid, the radio should emit a new selected value
    this.textControl.valueChanges
      .pipe(
        startWith(this.textControl.value),
        untilDestroyed(this),
        filter(() => this.radioControl.value === this.value),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (this.textControl.valid) {
          this.radioSelected.emit(this.value);
        }
      });
  }
  onRadioSelected(value: string): void {
    if (value === this.value) {
      this.radioSelected.emit(this.value);
    }
  }
  private validateRadioSelection(): void {
    if (this.radioControl.value === this.value) {
      this.clearRadioSelection();
    }
  }
  private clearRadioSelection(): void {
    this.radioControl.reset();
  }
}
