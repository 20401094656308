import {
  GeolocationQuery,
  LngLat,
  LngLatBounds,
  Target,
  targetHasBeenLocated,
} from '@fe-platform/geolocation/data';
import { Map } from 'mapbox-gl';
import { Observable, tap } from 'rxjs';
import { calculateMapBoundsFromCoordinates } from './calculateMapBoundsFromCoordinates';
export const flyToTarget =
  (map: Map | undefined) =>
  (
    target$: Observable<[Target | null, GeolocationQuery | null]>
  ): Observable<[Target | null, GeolocationQuery | null]> =>
    target$.pipe(
      tap(([target]) => {
        if (target && targetHasBeenLocated(target)) {
          const mapBounds = calculateMapBoundsFromTarget(target);
          // If SouthWesternCorner & NorthEastCorner are the same in mapBounds,
          // we use the flyTo() for a smoother animation
          if (
            mapBounds[0][0] === mapBounds[1][0] &&
            mapBounds[0][1] === mapBounds[1][1]
          ) {
            map?.flyTo({
              center: [mapBounds[0][0], mapBounds[0][1]],
              zoom: 14,
              essential: true,
            });
          } else {
            map?.fitBounds(mapBounds);
          }
        }
      })
    );
function calculateMapBoundsFromTarget(target: Target): LngLatBounds {
  const coordinates = target.queries
    .map((q) => q.location?.coordinates)
    .filter(Boolean) as LngLat[];
  return calculateMapBoundsFromCoordinates(coordinates);
}
