import { AfterViewInit, Directive } from '@angular/core';
import { replace } from 'feather-icons';

@Directive({
  selector: '[keystoneFeatherIcon]',
})
export class FeatherIconDirective implements AfterViewInit {
  ngAfterViewInit(): void {
    // feather icon
    replace();
  }
}
