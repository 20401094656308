import { PhoneNumberUtil } from 'google-libphonenumber';
export function isValidMsisdn(phone: string | undefined): boolean {
  if (!phone?.length) return false;
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.parse(phone);
    const fakeNumbersRegex = new RegExp(/99991111[0-3]{1}[0-9]{1}$/i);
    return (
      phoneNumberUtil.isValidNumber(phoneNumber) || fakeNumbersRegex.test(phone)
    );
  } catch (e) {
    return false;
  }
}
