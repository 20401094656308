import { Pipe, PipeTransform } from '@angular/core';
import {
  toLongDateString,
  toMediumDateString,
  toShortDateString,
} from '@fe-platform/core/date';
type DateStringType = 'short' | 'medium' | 'long';

@Pipe({ name: 'toDate', pure: false })
export class ToDatePipe implements PipeTransform {
  transform(
    value: string | Date,
    dateStringType: DateStringType = 'medium'
  ): string {
    switch (dateStringType) {
      case 'short':
        return toShortDateString(value);
      case 'long':
        return toLongDateString(value);
      default:
        return toMediumDateString(value);
    }
  }
}
