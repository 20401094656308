import { Injectable } from '@angular/core';
import {
  AuthDataService,
  AuthStatus,
  createOtpPayload,
  OtpResponse,
} from '@fe-platform/auth/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  map,
  Observable,
  repeat,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AuthActions } from '../actions';
import { handleAuthResponseError$ } from '../helpers';
import { selectOtpConfiguration } from '../selectors';

@Injectable()
export class PasswordEffects {
  constructor(
    private actions$: Actions,
    private authDataService: AuthDataService,
    private store: Store
  ) {}
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      switchMap(({ newPassword, oldPassword, otpCode }) => {
        if (!otpCode) {
          return this._resetPassword$(oldPassword, newPassword);
        } else {
          return this.validateOtp$(otpCode).pipe(
            switchMap(() => this._resetPassword$(oldPassword, newPassword))
          );
        }
      }),
      catchError((e: Error) => [
        AuthActions.setErrorMessage({ errorMessage: e.message }),
      ]),
      repeat()
    )
  );
  private validateOtp$(otpCode: string): Observable<OtpResponse> {
    return this.store.select(selectOtpConfiguration).pipe(
      take(1),
      switchMap((otpConfiguration) =>
        this.authDataService.validateOtpConfiguration({
          ...createOtpPayload(otpConfiguration),
          otp_code: otpCode,
        })
      )
    );
  }
  private _resetPassword$(
    oldPassword: string,
    newPassword: string
  ): Observable<Action> {
    return this.authDataService.resetPassword(oldPassword, newPassword).pipe(
      tap((response) => {
        if ('error' in response) {
          throw new Error(response.error?.message);
        } else {
          sessionStorage.setItem('password', newPassword);
        }
      }),
      map(() => AuthActions.setStatus({ status: AuthStatus.AUTHENTICATED })),
      catchError(handleAuthResponseError$(this.store))
    );
  }
}
