import {
  Marker,
  Mission,
  queryToMarker,
  TargetId,
} from '@fe-platform/geolocation/data';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { isWithinInterval } from 'date-fns';
import {
  filterGeoqueriesByTimerange,
  findActiveMission,
  findActiveQuery,
  findActiveTarget,
} from '../helpers';
import { MissionsState, MissionsStateName } from './state';

export const selectMissionsState =
  createFeatureSelector<MissionsState>(MissionsStateName);
const selectActiveMissionId = createSelector(
  selectMissionsState,
  (state) => state.activeMissionId
);
const selectActiveTargetId = createSelector(
  selectMissionsState,
  (state) => state.activeTargetId
);
const selectActiveQueryId = createSelector(
  selectMissionsState,
  (state) => state.activeQueryId
);
export const selectAllMissions = createSelector(
  selectMissionsState,
  (state: MissionsState) => state.missions
);
export const selectActiveMission = createSelector(
  selectAllMissions,
  selectActiveMissionId,
  (missions, activeMissionId) => {
    const activeMission = findActiveMission(missions, activeMissionId);
    return filterGeoqueriesByTimerange(activeMission);
  }
);

export const selectActiveMissionWithoutFilteringQueries = createSelector(
  selectAllMissions,
  selectActiveMissionId,
  findActiveMission
);

export const selectActiveMissionColorLocatedGeoqueries = createSelector(
  selectAllMissions,
  selectActiveMissionId,
  (missions, activeMissionId) => {
    const activeMission = findActiveMission(missions, activeMissionId);
    return activeMission.targets
      .filter((target) => target.visible)
      .flatMap((t) => {
        return t.queries
          .filter((query) => query.location)
          .map((query) => {
            return { query: query, color: t.color };
          });
      });
  }
);
export const selectActiveTarget = createSelector(
  selectActiveMission,
  selectActiveTargetId,
  findActiveTarget
);
export const selectActiveQuery = createSelector(
  selectActiveTarget,
  selectActiveQueryId,
  findActiveQuery
);

export const selectAllTargets = createSelector(selectAllMissions, (missions) =>
  missions.flatMap((m) => m.targets)
);
export const selectMapMarkers = createSelector(
  selectActiveMission,
  (mission: Mission): Marker[] =>
    mission.targets
      .filter((target) => target.visible)
      .map((target) =>
        target.queries
          .filter((q) => q.location)
          .filter((q) => {
            if (!mission.timebarRange) return true;
            return isWithinInterval(new Date(q.created_at), {
              start: mission.timebarRange.dateFrom,
              end: mission.timebarRange.dateTo,
            });
          })
          .map(queryToMarker(target))
      )
      .flat()
);
export const selectMapStyle = createSelector(
  selectActiveMission,
  (mission: Mission) => mission.mapStyle
);
export const selectExpandedTargets = createSelector(
  selectMissionsState,
  (state) => state.expandedTargets
);
export const selectTargetIsExpanded = (
  targetId: TargetId
): MemoizedSelector<object, boolean, (targetIds: TargetId[]) => boolean> =>
  createSelector(selectExpandedTargets, (targets) =>
    targets.includes(targetId)
  );

export const selectTowers = createSelector(
  selectMissionsState,
  (state) => state.cellTowers
);
