<ng-container *ngrxLet="otpConfiguration$ as otpConfiguration">
  <h6
    *ngIf="
      otpConfiguration.channel === channels.Email ||
      otpConfiguration.channel === channels.OtpPhone
    "
    class="h5 mb-3"
  >
    {{
      'OTP.ENTER_OTP_RECEIVED_ON'
        | translate
          : {
              value:
                otpConfiguration.channel === channels.Email
                  ? 'email'
                  : ('PHONE' | translate)
            }
    }}
    {{ otpConfiguration.hashedRecipient || otpConfiguration.recipient }}
  </h6>
  <h6 *ngIf="otpConfiguration.channel === channels.App" class="h5 mb-3">
    {{ 'SCAN_QR_TO_GENERATE_OTP_CODE' | translate }}
  </h6>
  <div
    *ngIf="otpConfiguration.channel === channels.App"
    class="qr-wrapper mb-3"
  >
    <img [src]="QRImage | ngrxPush" class="qr-img" alt="QR Code" />
  </div>
  <form [formGroup]="otpForm" class="mb-3">
    <div class="input-group">
      <input class="form-control" id="otp" formControlName="otpCode" />
    </div>
    <div class="invalid-feedback" *ngrxLet="errorMessage$ as errorMessage">
      {{ errorMessage }}
    </div>
  </form>
</ng-container>
