import { CellType, GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatCellNetwork: ColumnDataFormatter<
  GeolocationQuery,
  string
> = (query: GeolocationQuery) => {
  const cell_type = query.cell?.cell_type;
  return cell_type ? CellType[cell_type] : '-';
};
