import { Inject, Injectable } from '@angular/core';
import { FAST_API_URL } from '@fe-platform/core/config';
import { ApiService } from '@fe-platform/core/http';
import { Observable } from 'rxjs';
import { BillingPlan, LedgerPlan, TenantBillingDetails } from './models';
@Injectable({ providedIn: 'root' })
export class BillingDataService {
  constructor(
    @Inject(FAST_API_URL) private fastApiUrl: string,
    private apiService: ApiService
  ) {}

  public fetchBillingPlan(): Observable<BillingPlan> {
    return this.apiService.get<BillingPlan>(`${this.fastApiUrl}/billing-plan`);
  }

  public fetchLedgerData(username: string): Observable<{ result: LedgerPlan }> {
    return this.apiService.get<{ result: LedgerPlan }>(
      `${this.fastApiUrl}/ledger/ledger-item/${username}`
    );
  }

  public fetchTenantDetails(): Observable<{ result: TenantBillingDetails }> {
    return this.apiService.get<{ result: TenantBillingDetails }>(
      `${this.fastApiUrl}/billing/tenant-details`
    );
  }
}
