import { Notification } from '../models';
import { getTargetFromQueryArgs } from './getNotificationTargetFromQueryArgs';

export function getNotificationTarget(notification: Notification): string {
  const target = notification.content.target;
  if (target && target.alias) {
    return target.alias;
  }

  if (notification.content.queryArgs) {
    return getTargetFromQueryArgs(notification.content.queryArgs);
  }

  if (notification.content.query?.query_args) {
    return getTargetFromQueryArgs(notification.content.query.query_args);
  }

  if (notification.content.schedule?.payload) {
    return getTargetFromQueryArgs(
      notification.content.schedule.payload.query_args
    );
  }

  return '-';
}
