<div class="cell-tower-marker-background mt-1">
  <dl class="row mb-0">
    <dt class="col-6 fw-normal">
      <b> {{ 'CELL_ID' | translate }} </b>
    </dt>
    <dd class="col-6 fw-normal">{{ cellTower.cellId || '-' }}</dd>

    <dt class="col-6 fw-normal"><b>LAC</b></dt>
    <dd class="col-6 fw-normal">{{ cellTower.lac || '-' }}</dd>

    <dt class="col-6 fw-normal">
      <b> {{ 'RADIUS' | translate }}</b>
    </dt>
    <dd class="col-6 fw-normal">{{ cellTower.accuracy }} m.</dd>

    <dt class="col-6 fw-normal">
      <b> {{ 'LAT_LON' | translate }}</b>
    </dt>
    <dd class="col-6 fw-normal">
      {{ cellTower.location.lat | number: '1.0-2' }}/{{
        cellTower.location.lon | number: '1.0-2'
      }}
      <button
        class="btn btn-link p-0"
        (click)="copyLatLngToClipboard(cellTower.location)"
      >
        <i class="uil uil-copy link"></i>
      </button>
    </dd>

    <dt class="col-6 fw-normal">
      <b>{{ 'DISTANCE_M' | translate }}</b>
    </dt>
    <dd class="col-6 fw-normal">
      {{ cellTower.distance | number: '1.0-0' }} m.
    </dd>
  </dl>

  <span
    class="link text-decoration-underline cursor-pointer"
    (click)="viewInGoogleMaps(cellTower.location)"
    >{{ 'QUERY_DETAILS.VIEW_GOOGLE_MAPS' | translate }}
  </span>
</div>
