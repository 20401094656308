import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStatus, OtpConfiguration } from '@fe-platform/auth/data';
import { AuthFacade } from '@fe-platform/auth/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComponentStore } from '@ngrx/component-store';
import {
  combineLatest,
  filter,
  map,
  Observable,
  repeat,
  tap,
  withLatestFrom,
} from 'rxjs';

interface ResetPasswordState {
  otpToggleEnabled: boolean;
  otpFormValid: boolean;
  otpCodeFormVisible: boolean;
  otpConfiguration: OtpConfiguration | null;
  otpCode: string;
  passwordValid: boolean;
  password: string;
}

@UntilDestroy()
@Injectable()
export class ResetPasswordAfterLoginComponentStore extends ComponentStore<ResetPasswordState> {
  private readonly configuringOtpStatuses = [
    AuthStatus.CHANGING_PASSWORD,
    AuthStatus.CHANGING_PASSWORD_WITH_MANDATORY_OTP,
  ];
  constructor(private auth: AuthFacade, private router: Router) {
    super({
      otpCode: '',
      otpCodeFormVisible: false,
      otpFormValid: false,
      otpToggleEnabled: false,
      password: '',
      otpConfiguration: null,
      passwordValid: false,
    });
    this.auth.status$.pipe(untilDestroyed(this)).subscribe((status) => {
      if (status === AuthStatus.AUTHENTICATED) {
        this.router.navigate(['']);
      }
    });
  }
  // selectors

  private otpConfiguration$ = this.select((state) => state.otpConfiguration);
  private otpToggleEnabled$ = this.select((state) => state.otpToggleEnabled);
  private passwordValid$ = this.select((state) => state.passwordValid);
  private otpCode$ = this.select((state) => state.otpCode);
  errorMessage$ = this.auth.errorMessage$;
  otpToggleVisible$ = this.auth.status$.pipe(
    map((status) => this.configuringOtpStatuses.includes(status))
  );
  otpFormVisible$ = combineLatest([
    this.auth.status$,
    this.otpToggleEnabled$,
  ]).pipe(
    map(
      ([status, otpToggleEnabled]) =>
        this.configuringOtpStatuses.includes(status) && otpToggleEnabled
    )
  );
  otpFormValid$ = this.select((state) => state.otpFormValid);
  otpCodeFormVisible$ = this.auth.status$.pipe(
    map((status) => status === AuthStatus.VALIDATING_OTP_CONFIGURATION)
  );
  submitEnabled$ = this.select(
    this.auth.status$,
    this.otpToggleEnabled$,
    this.otpFormValid$,
    this.otpCode$,
    this.passwordValid$,
    (status, otpToggleEnabled, otpFormValid, otpCode, passwordValid) => {
      const isConfiguringOtp =
        otpToggleEnabled &&
        otpFormValid &&
        this.configuringOtpStatuses.includes(status);
      const CanSendPasswordChangeWithoutConfiguringOtp =
        passwordValid && !otpToggleEnabled;
      const hasConfiguredOtpAndCanSendNewPassowrd =
        passwordValid &&
        status === AuthStatus.VALIDATING_OTP_CONFIGURATION &&
        !!otpCode.length;
      return (
        isConfiguringOtp ||
        CanSendPasswordChangeWithoutConfiguringOtp ||
        hasConfiguredOtpAndCanSendNewPassowrd
      );
    }
  );
  // updates
  updatePassword = this.updater((state, password: string) => ({
    ...state,
    password,
  }));
  updateOtpConfiguration = this.updater(
    (state, otpConfiguration: OtpConfiguration) => ({
      ...state,
      otpConfiguration,
    })
  );
  updateOtpCode = this.updater((state, otpCode: string) => ({
    ...state,
    otpCode,
  }));
  updateOtpEnabled = this.updater((state, otpToggleEnabled: boolean) => ({
    ...state,
    otpToggleEnabled,
  }));
  updateOtpFormValidity = this.updater((state, otpFormValid: boolean) => ({
    ...state,
    otpFormValid,
  }));
  updatePasswordValidity = this.updater((state, passwordValid: boolean) => ({
    ...state,
    passwordValid,
  }));
  // actions
  private readonly submitResetPassword = this.effect(
    (source$: Observable<void>) =>
      source$.pipe(
        withLatestFrom(this.state$),
        tap(([, state]) => {
          const oldPassword = sessionStorage.getItem('password') as string;
          const newPassword = state.password;
          const otpCode = state.otpCode;
          this.auth.resetPassword(oldPassword, newPassword, otpCode);
        }),
        repeat()
      )
  );
  private readonly submitOtpConfig = this.effect((source$: Observable<void>) =>
    source$.pipe(
      withLatestFrom(this.otpConfiguration$),
      filter(([, otpConfiguration]) => Boolean(otpConfiguration)),
      tap(([, otpConfiguration]) => {
        this.auth.submitOtpConfiguration(otpConfiguration as OtpConfiguration);
      }),
      repeat()
    )
  );
  readonly submit = this.effect((source: Observable<void>) =>
    source.pipe(
      withLatestFrom(this.otpToggleEnabled$, this.auth.status$),
      tap(([, otpToggleEnabled, authStatus]) => {
        if (
          otpToggleEnabled &&
          (authStatus === AuthStatus.CHANGING_PASSWORD ||
            authStatus === AuthStatus.CHANGING_PASSWORD_WITH_MANDATORY_OTP)
        ) {
          this.submitOtpConfig();
        } else {
          this.submitResetPassword();
        }
      }),
      repeat()
    )
  );
}
