// It generates the points of a Polygon that will simulate a Circle.
export const getCircleCoordinates = (
  center: [number, number],
  radiusInMeters: number
): number[][] => {
  const NUMBER_OF_POINTS = 64;
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInMeters / 1000;

  const ret = [];
  // We create two base points for x,y based on the distance from center of cycle
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta, x, y;
  // We create points in the perimeter of the circle by using distance from center of circle
  for (let i = 0; i < NUMBER_OF_POINTS; i++) {
    theta = (i / NUMBER_OF_POINTS) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return ret;
};
