import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { OtpConfigComponent } from './components/otp-config/otp-config.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { ResetPasswordAfterLoginComponent } from './components/reset-password-after-login/reset-password-after-login.component';
import { LoginGuard, OtpConfigGuard, OtpGuard } from './guards';
import { ResetPasswordGuard } from './guards/reset-password.guard';
const authRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordAfterLoginComponent,
    canActivate: [ResetPasswordGuard],
  },
  {
    path: 'otp-config',
    component: OtpConfigComponent,
    canActivate: [OtpConfigGuard],
  },
  {
    path: 'otp',
    component: OtpInputComponent,
    canActivate: [OtpGuard],
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthFeatureLoginRoutingModule {}
