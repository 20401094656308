import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OTPChannels, User } from '@fe-platform/auth/data';
import { DynamicRadioGroupConfig } from '@fe-platform/shared-ui/keystone';
import { patchOtpConfigForm } from './patchOtpConfigForm';

export const createRadioConfig =
  (otpConfigForm: UntypedFormGroup) =>
  (user: User): DynamicRadioGroupConfig => {
    patchOtpConfigForm(otpConfigForm)(user);
    return {
      radioControl: otpConfigForm.get('otpChannel') as UntypedFormControl,
      radioGroupName: 'otpChannel',
      radios: [
        {
          name: OTPChannels.Email,
          initialValue: user.email ?? '',
          control: otpConfigForm.get('email') as UntypedFormControl,
          value: OTPChannels.Email,
          placeholder: 'OTP.EMAIL_INPUT_PLACEHOLDER',
          label: 'OTP.EMAIL_INPUT_LABEL',
        },
        {
          name: OTPChannels.OtpPhone,
          initialValue: user.phone ?? '',
          control: otpConfigForm.get('phone') as UntypedFormControl,
          value: OTPChannels.OtpPhone,
          placeholder: 'OTP.PHONE_INPUT_PLACEHOLDER',
          label: 'OTP.PHONE_INPUT_LABEL',
        },
      ],
    };
  };
