import { SortBy } from './latest-geolocation-queries';

export interface GeolocationQueryParams {
  page: number;
  limit: number;
  search_filter: string;
  sort: string;
  from_date: string;
  to_date: string;
  sort_by: SortBy;
  endIndex: number;
  totalRecords: number;
  status: string;
}
export const InitialGeolocationQueryListParams: GeolocationQueryParams = {
  page: 1,
  limit: 10,
  search_filter: '',
  from_date: '',
  to_date: '',
  sort: '',
  sort_by: SortBy.Desc,
  endIndex: 0,
  totalRecords: 0,
  status: '',
};
