import { Inject, Injectable } from '@angular/core';
import { GEO_URL, REPORTS_URL, REPORTS_V2_URL } from '@fe-platform/core/config';
import { ApiService, Result, ResultArray } from '@fe-platform/core/http';
import {
  BehaviorSubject,
  catchError,
  finalize,
  map,
  Observable,
  throwError,
} from 'rxjs';
import { generateGeolocationQueryParams } from './helpers/generate-geolocation-query-params';

import { HttpErrorResponse } from '@angular/common/http';
import { HunterGeoQuery as KeystoneGeoQuery } from '@report-service-ts/data-models';
import {
  GeolocationQuery,
  GeolocationQueryParams,
  GeolocationQueryRequestPayload,
  GeolocationReportsParams,
  LatestGeolocationQueryResponse,
  QueryPendingStatuses,
  Robot,
  RobotListFilters,
  RobotResponse,
} from './models';
import { NeighbourCellTowers } from './models/neighbour-cell-towers';
@Injectable({ providedIn: 'root' })
export class GeolocationQueryDataService {
  private pdfLoadingSrc = new BehaviorSubject<Record<string, boolean>>({});
  private callInfoLoadingSrc = new BehaviorSubject<Record<string, boolean>>({});
  private cellTowersLoadingSrc = new BehaviorSubject<Record<string, boolean>>(
    {}
  );

  pdfLoading$ = this.pdfLoadingSrc.asObservable();
  callInfoLoading$ = this.callInfoLoadingSrc.asObservable();
  cellTowersLoading$ = this.cellTowersLoadingSrc.asObservable();

  constructor(
    @Inject(GEO_URL) private geoUrl: string,
    @Inject(REPORTS_URL) private reportsUrl: string,
    @Inject(REPORTS_V2_URL) private reportsV2Url: string,
    private apiService: ApiService
  ) {}

  private setLoading(
    source: BehaviorSubject<Record<string, boolean>>,
    queryId: string,
    loading: boolean
  ): void {
    const value = { ...source.value };
    value[queryId] = loading;
    source.next(value);
  }

  public fetchGeolocationQueries(
    params: GeolocationQueryParams
  ): Observable<LatestGeolocationQueryResponse> {
    const url = `${this.geoUrl}/queries?`;
    const reqParams = generateGeolocationQueryParams(params);
    return this.apiService.get(url + new URLSearchParams(reqParams));
  }

  public fetchLatestQueries(
    username: string,
    limit = 5
  ): Observable<LatestGeolocationQueryResponse> {
    const url = `${this.geoUrl}/latest-queries?sort_by=DESC&limit=${limit}&created_by=${username}`;
    return this.apiService.get(url);
  }

  public fetchPaginatedRobots(
    params: RobotListFilters
  ): Observable<RobotResponse> {
    const url = `${this.geoUrl}/paginated-schedules?`;
    const reqParams = Object.keys(params).reduce(
      (paramsFiltered, paramKey) => ({
        ...paramsFiltered,
        [paramKey]: params[paramKey as keyof RobotListFilters].toString(),
      }),
      {}
    );
    return this.apiService.get(url + new URLSearchParams(reqParams));
  }

  public generateQueryReport(
    params: Partial<GeolocationReportsParams>
  ): Observable<Blob> {
    return this.apiService.post(`${this.reportsUrl}/reports`, params);
  }

  public generatePDFReport(params: KeystoneGeoQuery): Observable<Blob> {
    this.setLoading(this.pdfLoadingSrc, params.title, true);
    return this.apiService
      .postPDF(`${this.reportsV2Url}/hunter-geo-query`, params)
      .pipe(
        map((response) => response as Blob),
        finalize(() => this.setLoading(this.pdfLoadingSrc, params.title, false))
      );
  }

  public retrieveCallInfo(
    queryId: string
  ): Observable<Result<GeolocationQuery>> {
    this.setLoading(this.callInfoLoadingSrc, queryId, true);
    return this.apiService
      .get(`${this.geoUrl}/call-info/query/${queryId}`)
      .pipe(
        map((response) => response as Result<GeolocationQuery>),
        finalize(() => this.setLoading(this.callInfoLoadingSrc, queryId, false))
      );
  }

  public sendSimpleGeolocationQuery(
    payload: GeolocationQueryRequestPayload
  ): Observable<ResultArray<GeolocationQuery>> {
    return this.apiService.post(`${this.geoUrl}/queries`, payload);
  }

  public checkForCellTowers(query: GeolocationQuery): Observable<boolean> {
    return this.apiService.get<boolean>(
      `${this.geoUrl}/bts/neighbour-towers-check/${query.id}`
    );
  }

  public retrieveCellTowers(
    query: GeolocationQuery
  ): Observable<NeighbourCellTowers> {
    this.setLoading(this.cellTowersLoadingSrc, query.id, true);
    return this.apiService
      .get(`${this.geoUrl}/bts/neighbour-towers/${query.id}`)
      .pipe(
        map((response) => response as NeighbourCellTowers),
        finalize(() =>
          this.setLoading(this.cellTowersLoadingSrc, query.id, false)
        )
      );
  }

  public fetchGeolocationQueriesByMsisdn(
    msisdn: string,
    limit = 100
  ): Observable<ResultArray<GeolocationQuery>> {
    // Query requires msisdn to not include the + in front
    msisdn = msisdn.replace('+', '');
    const url = `${this.geoUrl}/queries?telno=${msisdn}&sort_by=DESC&limit=${limit}`;
    return this.apiService.get(url);
  }

  public fetchGeolocationQueriesByImsi(
    imsi: string,
    limit = 100
  ): Observable<ResultArray<GeolocationQuery>> {
    // Query requires msisdn to not include the + in front
    const url = `${this.geoUrl}/queries?imsi=${imsi}&sort_by=DESC&limit=${limit}`;
    return this.apiService.get(url);
  }

  public fetchMsisdnFromImsi(imsi: string): Observable<string> {
    const url = `${this.geoUrl}/queries?imsi=${imsi}&sort=provider.telno&sort_by=DESC&limit=1`;
    return this.apiService.get<ResultArray<GeolocationQuery>>(url).pipe(
      map((response) => {
        if (!response.result.length) return '';
        const query = response.result[0];
        if (!query.provider?.telno) return '';
        return query.provider.telno;
      }),
      catchError((e: HttpErrorResponse) => {
        console.error('Failed on Fetch Msisdn from Imsi');
        return throwError(() => e);
      })
    );
  }

  public deleteScheduledQuery(id: string): Observable<GeolocationQuery> {
    const url = `${this.geoUrl}/schedules/${id}`;
    return this.apiService.delete(url);
  }

  public fetchActiveScheduledQueries(): Observable<Robot[]> {
    const url = `${this.geoUrl}/schedules?canceled=false`;
    return this.apiService.get(url);
  }

  public fetchPendingGeolocationQueries(): Observable<
    ResultArray<GeolocationQuery>
  > {
    const queryParams = QueryPendingStatuses.map(
      (status) => `status=${status}`
    ).join('&');

    const url = `${this.geoUrl}/queries?${queryParams}`;
    return this.apiService.get(url);
  }

  public chargeNMRQuery(queryId: string): Observable<Result<GeolocationQuery>> {
    const url = `${this.geoUrl}/nmr/query/${queryId}`;
    return this.apiService.get<Result<GeolocationQuery>>(url);
  }
}
