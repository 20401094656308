import {
  AuthResponse,
  AuthResponseError,
  AuthResponseSuccess,
  AuthResponseWarning,
} from '@fe-platform/auth/data';

export function AuthResponseIsSuccess(
  response: AuthResponse
): response is AuthResponse<AuthResponseSuccess> {
  return (
    typeof response.result === 'object' &&
    !!response.result &&
    'token' in response.result &&
    'session_expires_at' in response.result
  );
}

export function AuthResponseHasError(
  response: AuthResponse
): response is AuthResponse<Partial<AuthResponseError>> {
  return (
    typeof response.result === 'object' &&
    !!response.result &&
    ('otp_code_required' in response.result ||
      'otp_channel' in response.result ||
      'otp_recipient' in response.result ||
      'message' in response.result)
  );
}
export function AuthResponseHasWarning(
  response: AuthResponse
): response is AuthResponse<
  AuthResponseSuccess & Partial<AuthResponseWarning>
> {
  return (
    AuthResponseIsSuccess(response) &&
    ('force_2fa_enablement' in response.result ||
      'request_2fa_enablement' in response.result ||
      'request_password_change' in response.result)
  );
}
export function AuthResponseIsSupport(
  response: AuthResponse
): response is AuthResponse<AuthResponse<Partial<AuthResponseError>>> {
  return (
    typeof response.result === 'object' &&
    !!response.result &&
    'support_email_required' in response.result
  );
}
