export interface PageTitle {
  name: string;
  translation: string;
}

export const pageTitles: PageTitle[] = [
  {
    name: '/robots',
    translation: 'LEFT_SIDEBAR.ROBOTS',
  },
  { name: '/report-analysis', translation: 'LEFT_SIDEBAR.HISTORY' },
  { name: '/missions', translation: 'LEFT_SIDEBAR.MISSION' },
  { name: '/pricing', translation: 'LEFT_SIDEBAR.PRICING' },
  { name: '/admin/users', translation: 'LEFT_SIDEBAR.ADMIN' },
];
