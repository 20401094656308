import { timebarColors } from '@fe-platform/geolocation/data';
import { TimeBarOptions } from '@trg-ui/link-analysis';

export const timebarOptions: TimeBarOptions = {
  showPlay: false,
  showExtend: false,
  showFit: true,
  backColour: 'rgba(20, 20, 20, 0.7)',
  sliderColour: 'rgba(17, 17, 17, 0.5)',
  histogram: {
    colour: '#3C4752',
    markHiColour: 'red',
  },
  groups: {
    groupBy: 'color',
    categories: timebarColors,
    colours: timebarColors,
  },
  controlBarTheme: 'dark',
};
export const spanishLocale = {
  longMonths: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  shortMonths: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ],
};

export const englishLocale = {
  longMonths: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};
