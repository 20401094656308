import { Robot } from '@fe-platform/geolocation/data';
import { StreamManager } from '@fe-platform/stream-manager';
import { Store } from '@ngrx/store';
import { EventChannel } from '@trg-commons/gio-data-models-ts';
import { Subscription, tap, withLatestFrom } from 'rxjs';
import { RobotActions, selectActiveRobots } from '../robots';
import { UpdateTaskEventName } from './constants';

export function subscribeToIncomingScheduledQueries(
  store: Store,
  streamManager: StreamManager
): Subscription {
  return streamManager
    .onEvent<Robot>(EventChannel.GeolocationQueries, UpdateTaskEventName)
    .pipe(
      withLatestFrom(store.select(selectActiveRobots)),
      tap(([scheduler, activeRobots]) => {
        const hasActiveRobot = activeRobots.find((q) => q.id === scheduler.id);

        if (scheduler.canceled_at && hasActiveRobot) {
          store.dispatch(
            RobotActions.removeActiveRobot({
              robotId: scheduler.id,
            })
          );
        }

        if (!scheduler.canceled_at && !hasActiveRobot) {
          store.dispatch(RobotActions.setActiveRobot({ robot: scheduler }));
        }
      })
    )
    .subscribe();
}
