import { CellType, GeolocationQuery } from '../../models';
import { ColumnDataFormatter } from './columnDataFormatter';

export const formatCellECI: ColumnDataFormatter<GeolocationQuery, string> = (
  query: GeolocationQuery
) => {
  const cell_type = query.cell?.cell_type;
  if (cell_type === CellType['4G']) {
    return String(query.cell?.cell_id);
  }
  return '-';
};
