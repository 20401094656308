import { Injectable } from '@angular/core';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Map } from 'mapbox-gl';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  withLatestFrom,
} from 'rxjs';
import { flyToMission, flyToQuery, flyToTarget } from './helpers';
@UntilDestroy()
@Injectable()
export class MapMovementService {
  constructor(private geospatialService: GeolocationFacade) {}
  public initialize(map: Map): void {
    this.flyToMission$(map).subscribe();
    this.flyToTarget$(map).subscribe();
    this.flyToQuery$(map).subscribe();
  }
  private flyToMission$(map: Map): Observable<unknown> {
    return combineLatest([
      this.geospatialService.missions.activeMission$.pipe(
        distinctUntilChanged()
      ),
      this.geospatialService.missions.activeTarget$,
    ]).pipe(untilDestroyed(this), flyToMission(map));
  }
  private flyToQuery$(map: Map): Observable<unknown> {
    return this.geospatialService.missions.activeQuery$.pipe(
      flyToQuery(map),
      untilDestroyed(this)
    );
  }
  private flyToTarget$(map: Map): Observable<unknown> {
    return this.geospatialService.missions.activeTarget$.pipe(
      // never fly to target if there is an active query
      withLatestFrom(this.geospatialService.missions.activeQuery$),
      filter(([, query]) => !query),
      //
      flyToTarget(map),
      untilDestroyed(this)
    );
  }
}
