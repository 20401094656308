import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FETCH_RETRIES } from '@fe-platform/core/config';
import { Observable, retry } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
    @Inject(FETCH_RETRIES) private retries: number = 3
  ) {}

  get<T>(url: string, params: HttpParams = new HttpParams()): Observable<T> {
    return this.http
      .get<T>(url, {
        headers: this.headers,
        params,
      })
      .pipe(retry({ count: this.retries, delay: 2000, resetOnSuccess: true }));
  }

  post<T, D>(url: string, data?: D): Observable<T> {
    return this.http.post<T>(url, JSON.stringify(data), {
      headers: this.headers,
    });
  }

  put<T, D>(url: string, data: D): Observable<T> {
    return this.http.put<T>(url, JSON.stringify(data), {
      headers: this.headers,
    });
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url, {
      headers: this.headers,
    });
  }

  postPDF<T, D>(url: string, data?: D): Observable<T> {
    return this.http.post<T>(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'blob' as 'json',
    });
  }

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return new HttpHeaders(headersConfig);
  }
}
