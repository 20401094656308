import { TranslateService } from '@ngx-translate/core';
import {
  HunterGeoQuery as KeystoneGeoQuery,
  LanguagesEnum,
} from '@report-service-ts/data-models';
import { GeolocationQuery } from '../models';
import { QueryFormatters } from './formatters';

export class geoQueryToPdfPayload {
  constructor(
    private query: GeolocationQuery,
    private translationService: TranslateService
  ) {}

  public mapToPayload(): KeystoneGeoQuery {
    const lang = this.translationService.currentLang as LanguagesEnum;
    let qrCodeUrl = '-';
    let location = undefined;

    if (this.query.location) {
      const long = this.query.location.coordinates[0];
      const lat = this.query.location.coordinates[1];
      qrCodeUrl = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`;

      location = {
        googleMapsUrl: qrCodeUrl,
        geoResolution: this.translationService.instant(
          QueryFormatters.locationType(this.query)
        ),
        radius: this.query.accuracy_meters,
        aol: `${this.query.age_of_location_in_minutes} mins`,
        coordinates: [`${lat}`, `${long}`],
        address: this.query.address,
      };
    }

    const payload = {
      lang,
      theme: 'WHITE',
      title: this.query.id,
      createdDate: QueryFormatters.locationReceivedAtDate(this.query),
      qrCodeUrl,
      fileManagerPath: '-',

      targetMsisdn: this.query.provider.telno,
      locationFound: !!this.query.location,
      networkStatus: this.query.device?.network_status,
      callInfo: {
        isAvailable: !!this.query.call_info?.oncall_number,
        chargedAlready: !!this.query.call_info?.billing_id,
        callDirection: this.query.call_info?.call_direction,
        onCallNumber: this.query.call_info?.oncall_number,
      },
      equipment: {
        imsi: this.query.provider.imsi,
        imei: this.query.device?.imei,
        phoneModel: QueryFormatters.phoneModel(this.query),
        country: QueryFormatters.country(this.query),
        mobileOperator: this.query.provider.name,
      },
      network: {
        provider: this.query.provider.name,
        roamingProvider: this.query.provider.roaming_name,
        lac: this.query.cell?.lac,
        cellENBID: this.query.cell?.enbid,
        cellLCID: this.query.cell?.lcid,
        cellECI: QueryFormatters.cellECI(this.query),
        cellId: QueryFormatters.cellId(this.query),
        radio: QueryFormatters.cellNetwork(this.query),
      },
      location,
    } as KeystoneGeoQuery;

    return payload;
  }
}
