<div class="mb-1">
  <keystone-radio-button-with-editable-value
    *ngFor="let radio of config.radios"
    [radioGroupName]="config.radioGroupName"
    [name]="radio.name"
    [radioControl]="config.radioControl"
    [initialValue]="radio.initialValue"
    [value]="radio.value"
    [textControl]="radio.control"
    [disabled]="!!radio.disabled"
    (radioSelected)="onRadioSelected($event)"
    [placeholder]="radio.placeholder"
    [label]="radio.label ?? ''"
  >
  </keystone-radio-button-with-editable-value>
</div>
