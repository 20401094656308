import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeolocationFacade } from '@fe-platform/geolocation/state';
import { map, take, tap } from 'rxjs';

@Injectable()
export class LeftSidebarService {
  constructor(
    private router: Router,
    private geospatialService: GeolocationFacade
  ) {}

  navigateToMission(event?: Event): void {
    event?.stopPropagation();
    this.geospatialService.missions.missions$
      .pipe(
        take(1),
        map((missions) => missions[0]),
        tap((mission) => {
          this.geospatialService.missions.setActiveMission(mission.id);
          this.router.navigate(['missions', mission.id]);
        })
      )
      .subscribe();
  }
}
